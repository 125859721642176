import {
  Stack,
  Typography,
} from '@mui/material';
import { DateTime } from 'luxon';

import {
  useGetV1CoursesByIdQuery,
} from '../../store/api/backendApi';
import { serializeRTKFetchError } from '../../utils/utils';
import CourseTypeInfo from '../courseTypes/CourseTypeInfo';
import { CoursesIcon, sxSubtleIcon } from '../layout/icons';
import InfoMessage from '../misc/InfoMessage';
import LoadingIndicator from '../misc/LoadingIndicator';

interface ParticipantSearchResultCourseDetailsProps {
  courseId: string;
}

export default function ParticipantSearchResultCourseDetails({
  courseId,
} : ParticipantSearchResultCourseDetailsProps): JSX.Element {
  const {
    data: courseData, isLoading, isError, error: fetchError,
  } = useGetV1CoursesByIdQuery({ id: courseId });

  if (isLoading) {
    return (
      <LoadingIndicator caption="Ladataan kurssin tietoja..." />
    );
  }

  if (isError) {
    return (
      <InfoMessage
        sx={{ m: 2, p: 1 }}
        title="Virhe"
        message="Kurssin lataaminen epäonnistui."
        severity="error"
        details={serializeRTKFetchError(fetchError)}
      />
    );
  }

  return (
    <>
      <Stack direction="row" spacing={1} alignItems="center" sx={{ mb: 1 }}>
        <CoursesIcon fontSize="small" sx={sxSubtleIcon} />
        <Typography variant="body1" gutterBottom>
          {courseData?.data?.name}
          ,
        </Typography>
        <Typography variant="body1" gutterBottom>
          {courseData?.data?.startDateTime ? DateTime.fromISO(courseData?.data?.startDateTime).toFormat('dd.MM.yyyy') : ''}
        </Typography>
      </Stack>
      <CourseTypeInfo id={courseData?.data?.courseTypeId as string} sx={{ mb: 1 }} />
    </>
  );
}
