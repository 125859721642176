import {
  Paper,
  Typography,
} from '@mui/material';
import React from 'react';

type MultilineCommentProps = {
  content: string;
};

export default function MultilineComment(
  { content } : MultilineCommentProps,
): JSX.Element {
  return (
    <Paper sx={{
      p: 2, mb: 3, background: '#fafafa', boxShadow: 0,
    }}
    >
      <Typography variant="body2" gutterBottom sx={{ whiteSpace: 'pre-wrap' }}>
        {content}
      </Typography>
    </Paper>
  );
}
