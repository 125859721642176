import {
  Container,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const ViewContainer = styled(Container)(({ theme }) => ({
  padding: '10px 25px 25px 25px',
  marginBottom: '15px',
  [theme.breakpoints.down('md')]: {
    padding: '10px',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '0px',
  },
}));

export default ViewContainer;
