import {
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Outlet } from 'react-router-dom';

import ScrollToTop from './ScrollToTop';
import TopBar from './TopBar';

function MainLayout() {
  const theme = useTheme();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <ScrollToTop>
      <>
        <TopBar />
        <Outlet />
      </>
    </ScrollToTop>
  );
}

export default MainLayout;
