import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import {
  CoursesIcon,
  CustomersIcon,
  DashboardIcon,
  MenuIcon,
  ParticipantSearchIcon,
  SettingsIcon,
  UserIcon,
} from './icons';
import ProfileMenu from './ProfileMenu';
import Restricted from './Restricted';

type NaviItemProps = {
  title: string,
  Icon: JSX.Element,
  to: string
}; /* use `interface` if exporting so that consumers can extend */

function NaviItem({ title, Icon, to }: NaviItemProps) {
  const { pathname } = useLocation();
  const selected = pathname === to;
  return (
    <ListItem>
      <ListItemButton component={RouterLink} selected={selected} to={to}>
        <ListItemIcon>
          {Icon}
        </ListItemIcon>
        <ListItemText primary={title} />
      </ListItemButton>
    </ListItem>
  );
}

function DashboardView(): JSX.Element {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [profileMenuAnchor, setProfileMenuAnchor] = useState<HTMLElement | null>(null);

  const handleProfileMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setProfileMenuAnchor(profileMenuAnchor ? null : event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setProfileMenuAnchor(null);
  };

  const toggleDrawer = (isOpen: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event instanceof KeyboardEvent) {
      if (event.key === 'Tab' || event.key === 'Shift') {
        return;
      }
    }
    setDrawerOpen(isOpen);
  };

  return (
    <>
      <AppBar sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(!drawerOpen)}>
            <MenuIcon />
          </IconButton>
          <Typography variant="body1">
            <Link component={RouterLink} to="/" color="inherit" underline="none">
              Camilla Safety Oy
            </Link>
          </Typography>
          <IconButton
            size="large"
            edge="end"
            color="inherit"
            aria-label="Open profile menu"
            onClick={handleProfileMenuClick}
          >
            <UserIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <ProfileMenu
        anchorEl={profileMenuAnchor}
        handleClose={handleProfileMenuClose}
      />

      <Toolbar />

      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}

      >
        <div
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <List sx={{ mt: '60px', minWidth: '300px' }}>
            <NaviItem title="Etusivu" Icon={<DashboardIcon />} to="/dashboard" />
            <NaviItem title="Kurssit" Icon={<CoursesIcon />} to="/courses" />
            <Restricted requires={['admin']} quiet>
              <>
                <NaviItem title="Asiakkaat" Icon={<CustomersIcon />} to="/customers" />
                <NaviItem title="Osallistujat" Icon={<ParticipantSearchIcon />} to="/participants" />
                <NaviItem title="Asetukset" Icon={<SettingsIcon />} to="/settings" />
              </>
            </Restricted>
          </List>
          {/* Footer  */}
          <Box
            sx={{
              justifyContent: 'center',
              display: 'flex',
              mb: 2,
              position: 'absolute',
              bottom: 0,
              width: '90%',
            }}
          >
            <Typography variant="caption" align="center" sx={{ color: '#999' }}>
              Versio
              {' '}
              {process.env.NODE_ENV === 'production' ? process.env.REACT_APP_BUILD : process.env.NODE_ENV }
              {' '}
              {process.env.REACT_APP_BUILD_TIME }
              {' '}
              UTC
            </Typography>
          </Box>
        </div>
      </Drawer>

    </>
  );
}

export default DashboardView;
