/* eslint-disable no-nested-ternary */
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  InputLabelProps,
  SxProps,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';

import { Customer, useGetV1CustomersQuery } from '../../store/api/backendApi';
import { serializeRTKFetchError } from '../../utils/utils';
import { CloseIcon } from '../layout/icons';
import InfoMessage from '../misc/InfoMessage';

 interface CustomerSearchProps {
  onSelect: (customer: Customer) => void,
  onCancel?: () => void | undefined,
  sx?: SxProps<Theme> | undefined
}

export default function CustomerSearch({
  onSelect,
  onCancel = undefined,
  sx = {},
  ...props
}: CustomerSearchProps) {
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>('Ladataan...');
  const [customers, setCustomers] = useState<readonly Customer[]>([]);

  const {
    data: customerData,
    error: fetchError,
    isError: isFetchError,
    isFetching,
  } = useGetV1CustomersQuery();

  useEffect(() => {
    if (customerData) {
      setSearchTerm('');
      setCustomers(customerData.data);
    }
  }, [customerData]);

  if (isFetchError) {
    return (
      <InfoMessage
        title="Virhe"
        message="Tietojen lataaminen epäonnistui."
        severity="error"
        details={serializeRTKFetchError(fetchError)}
      />
    );
  }

  return (
    <Autocomplete
      id="customer-search"
      sx={sx}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      inputValue={searchTerm}
      onInputChange={(event, newInputValue) => {
        if (event) {
          setSearchTerm(newInputValue);
        } else {
          setSearchTerm('');
        }
      }}
      onKeyDown={(event) => {
        if (event.key === 'Escape' && onCancel) {
          event.stopPropagation(); // Prevent the event from bubbling up
          onCancel();
        }
      }}
      popupIcon={null}
      clearIcon={null}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option;
        }
        return option.name as string;
      }}
      options={customers}
      loading={isFetching}
      onChange={(event, newValue, reason) => {
        if (reason === 'selectOption') {
          if (typeof newValue === 'object' && newValue !== null) {
            onSelect(newValue as Customer);
          }
        }
        setSearchTerm('');
      }}
      renderOption={(optionProps, option) => {
        if (typeof option === 'object' && option !== null) {
          return (
            <li {...optionProps}>
              <Box>
                <Typography variant="body1">
                  {option.name}
                </Typography>
                {'contactPerson' in option && (
                  <Box>
                    {option.contactPerson?.name && (
                    <Typography variant="body2" color="text.secondary" sx={{ mr: 1 }}>
                      {option.contactPerson?.name}
                    </Typography>
                    )}
                    {option.contactPerson?.emailAddress && (
                    <Typography variant="body2" color="text.secondary" sx={{ mr: 1 }}>
                      {option.contactPerson?.emailAddress}
                    </Typography>
                    )}
                    {option.contactPerson?.phoneNumber && (
                    <Typography variant="body2" color="text.secondary" sx={{ mr: 1 }}>
                      {option.contactPerson?.phoneNumber}
                    </Typography>
                    )}
                  </Box>
                )}
              </Box>
            </li>
          );
        }
        return (
          <li {...optionProps}>
            {option}
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...(params as Partial<AutocompleteRenderInputParams>
              & { InputLabelProps: React.PropsWithChildren<InputLabelProps> })
            }
          label="Asiakashaku"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
            // eslint-disable-next-line react/jsx-no-useless-fragment
              <>
                {isFetching ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  onCancel ? (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        aria-label="Clear"
                        onClick={onCancel}
                        edge="end"
                      >
                        <CloseIcon />
                      </IconButton>
                    </InputAdornment>
                  ) : (
                    null // note also: popupIcon={null}
                  )
                )}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      {...props}
    />
  );
}
