import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  Breadcrumbs as MuiBreadcrumbs,
  Link,
  Typography,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Link as RouterLink } from 'react-router-dom';

import { DashboardIcon } from './icons';

const MuiBreadcrumbsStyled = styled(MuiBreadcrumbs)(({ theme }) => ({
  marginBottom: '10px',
  [theme.breakpoints.down('sm')]: {
    marginLeft: '10px',
  },
}));

function Breadcrumbs({ items }: { items: { label: string, to?: string }[]}) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const dashboardLabel = isSmallScreen ? '' : 'Etusivu';
  return (
    <MuiBreadcrumbsStyled
      maxItems={isSmallScreen ? 3 : 5}
      itemsAfterCollapse={2}
      separator={<NavigateNextIcon fontSize="small" />}
    >
      <Link component={RouterLink} underline="hover" color="inherit" to="/dashboard" sx={{ display: 'flex', alignItems: 'center' }}>
        <DashboardIcon sx={{ mr: 0.5 }} fontSize="inherit" color="primary" />
        {dashboardLabel}
      </Link>
      {items.map((item, index) => (item?.to ? (
        // eslint-disable-next-line react/no-array-index-key
        <Link key={index} component={RouterLink} underline="hover" color="inherit" to={item.to}>
          {item.label}
        </Link>
      // eslint-disable-next-line react/no-array-index-key
      ) : (<Typography key={index} color="primary.light">{item.label}</Typography>)))}

    </MuiBreadcrumbsStyled>
  );
}

export default Breadcrumbs;
