import {
  Box,
  BoxProps,
} from '@mui/material';
import React from 'react';

interface FormActionsBoxProps extends BoxProps {
  children: React.ReactNode;
}

export default function FormActionsBox({ children, ...props }: FormActionsBoxProps): JSX.Element {
  const { sx, ...rest } = props;
  return (
    <Box display="flex" justifyContent="flex-end" sx={{ my: 1, ...sx }} {...rest}>
      {children}
    </Box>
  );
}
