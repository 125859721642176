import {
  ClickAwayListener, List, ListItemButton, ListItemIcon, ListItemText,
  Paper,
  Popper, useTheme,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import {
  LogoutIcon,
} from './icons';

type ProfileMenuProps = {
  handleClose: () => void,
  anchorEl: HTMLElement | null,
};

function ProfileMenu({ handleClose, anchorEl }: ProfileMenuProps) {
  const theme = useTheme();

  // Close menu when clicking a link
  // Event default is hdnled by the Link component
  const handleClick = () => {
    handleClose();
  };

  return (
    <Popper
      placement="bottom-end"
      open={anchorEl !== null}
      anchorEl={anchorEl}
      popperOptions={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -5],
            },
          },
        ],
      }}
      sx={{ zIndex: 2 }} // needed to be abobe badges
    >
      <ClickAwayListener onClickAway={handleClose}>
        <Paper sx={{ paddingTop: '15px' }}>
          <List
            component="nav"
            sx={{
              width: '100%',
              minWidth: 250,
              borderRadius: '4px',
              [theme.breakpoints.down('md')]: {
                minWidth: '100%',
              },
              '& .MuiListItemButton-root': {
                mt: 0.5,
              },
            }}
          >
            <ListItemButton component={RouterLink} to="/logout" onClick={handleClick}>
              <ListItemText primaryTypographyProps={{ fontWeight: 300 }} primary="Kirjaudu ulos" />
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
            </ListItemButton>
          </List>
        </Paper>
      </ClickAwayListener>
    </Popper>
  );
}

export default ProfileMenu;
