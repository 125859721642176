import {
  Box,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import React from 'react';

import { AddressProperties } from '../../store/api/backendApi';
import GoogleMapsLink from './GoogleMapsLink';

interface AddressBlockProps {
  address: AddressProperties;
  sx?: SxProps<Theme> | undefined
}

export default function AddressBlock({ address, sx = {} } : AddressBlockProps): JSX.Element {
  return (
    <Box sx={sx}>
      <Typography variant="body1">
        {address?.street || '-' }
      </Typography>
      <Typography variant="body2" gutterBottom>
        {address?.postalCode || '-'}
        {' '}
        {address?.city || '-'}
        {address?.street && (
        <>
          {' '}
          <GoogleMapsLink address={address} />
        </>
        )}
      </Typography>
    </Box>
  );
}
