import {
  Link,
  LinkProps,
} from '@mui/material';
import React from 'react';

import { AddressProperties } from '../../store/api/backendApi';
import { NavigateIcon } from '../layout/icons';

interface GoogleMapsLinkProps extends LinkProps {
  address: AddressProperties;
  label?: string;
}

export default function GoogleMapsLink(
  { address, label = 'Näytä reitti', ...props } : GoogleMapsLinkProps,
): JSX.Element {
  const {
    underline = 'none',
    ...rest
  } = props;
  const addressQueryString = encodeURIComponent(`${address.street || ''}${address.postalCode ? `, ${address.postalCode}` : ''} ${address.city || ''}`);

  return (
    <Link
      underline={underline}
      href={`https://www.google.com/maps/dir/?api=1&destination=${addressQueryString}`}
      target="_blank"
      rel="noopener noreferrer"
      {...rest}
    >
      {label}
      &nbsp;
      <NavigateIcon
        fontSize="inherit"
        sx={{
          position: 'relative',
          top: '2px',
        }}
      />
    </Link>
  );
}
