import {
  Box,
  Button,
  Collapse,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import React, { useState } from 'react';

import { stateFromYupSchema, useFormValidator } from '../../hooks/useFormValidator';
import {
  CertificateType,
  GetV1CoursesByIdApiResponse,
  Participant,
  PutV1ParticipantsByIdApiArg,
  useDeleteV1ParticipantsByIdMutation,
  usePutV1ParticipantsByIdMutation,
} from '../../store/api/backendApi';
import { participantCourseStatuses } from '../../utils/const';
import {
  isErrorWithFailureFrame,
  isErrorWithMessage,
  isFetchBaseQueryError,
  setNestedProperty,
} from '../../utils/utils';
import { ParticipantInputValues, participantSchema } from '../../validators/validators';
import CustomerChip from '../customers/CustomerChip';
import FormActionsBox from '../form/FormActionsBox';
import FormInputStack from '../form/FormInputStack';
import SubmitButton from '../form/SubmitButton';
import TextInput from '../form/TextInput';
import { DeleteIcon, MoveIcon } from '../layout/icons';
import CollapseButton from '../misc/CollapseButton';
import InfoMessage from '../misc/InfoMessage';
import CertificateTypeSelect from './CertificateTypeSelect';
import CourseParticipantTransferForm from './CourseParticipantTransferForm';

interface CourseParticipantFormProps {
  course: GetV1CoursesByIdApiResponse['data']
  participant: Participant;
  onClose: (needsRefresh: boolean) => void;
}

export function CourseParticipantForm(
  {
    course,
    participant,
    onClose,
  }: CourseParticipantFormProps,
) {
  const [showSecondary, setShowSecondary] = useState(false);
  const formValidator = useFormValidator(participantSchema);
  const [formState, setFormState] = useState<ParticipantInputValues>(
    stateFromYupSchema(participantSchema, participant),
  );
  const [formError, setFormError] = useState<
    {error: string, errorDescription: string} | false
  >(false);
  const [showTransferForm, setShowTransferForm] = useState(false);

  const [putParticipant, { isLoading: isUpdating }] = usePutV1ParticipantsByIdMutation();
  const [deleteParticipant] = useDeleteV1ParticipantsByIdMutation();

  const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = target.type === 'checkbox' ? { name: target.name, value: target.checked } : target;

    if (target.type === 'checkbox') { // checkbox does not trigger onBlur
      formValidator.setTouched(target.name);
    }

    if (formValidator.isTouched(name)) {
      formValidator.validate(name, value);
    }
    const newState = { ...formState };
    setNestedProperty(newState, name, value);
    setFormState(newState);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSelectChange = (event: SelectChangeEvent<any>) => {
    const { name, value } = event.target;
    const newState = { ...formState };
    setNestedProperty(newState, name, value);
    setFormState(newState);
  };

  const handleBlur = ({ target }: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = target;
    formValidator.setTouched(target.name);
    formValidator.validate(name, value);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      setFormError(false);
      const validated = await formValidator.validateAll(formState);
      if (!validated) {
        const e = new Error('Tarkista lomakkeen kentät');
        e.name = 'FormError';
        throw e;
      }

      const payload = {
        id: participant.id,
        body: {
          ...validated,
        },
      } as PutV1ParticipantsByIdApiArg;

      await putParticipant(payload).unwrap();
      setFormError(false);
      onClose(true);
    } catch (e) {
      const errorInfo = {
        error: 'Tallennus epäonnistui',
        errorDescription: 'Tuntematon virhe',
      };

      if (isErrorWithFailureFrame(e)) {
        errorInfo.errorDescription = `Palvelinvirhe (${e.data.error?.message})`;
      } else if (isFetchBaseQueryError(e)) {
        errorInfo.errorDescription = 'Palvelinvirhe (verkkovirhe)';
      } else if (isErrorWithMessage(e)) {
        errorInfo.errorDescription = e.message;
      }

      setFormError(errorInfo);
    }
  };

  const handleDelete = async () => {
    await deleteParticipant({ id: participant.id });
    onClose(true);
  };

  if (showTransferForm) {
    return (
      <CourseParticipantTransferForm
        currentCourseId={course.id}
        participant={participant}
        onClose={(needsRefresh) => {
          setShowTransferForm(false);
          if (needsRefresh) {
            onClose(true);
          }
        }}
      />
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      <FormInputStack>
        <TextInput
          label="Etunimi"
          name="firstName"
          value={formState.firstName}
          formValidator={formValidator}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={isUpdating}
          fullWidth
        />
        <TextInput
          label="Sukunimi"
          name="lastName"
          value={formState.lastName}
          formValidator={formValidator}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={isUpdating}
          fullWidth
        />
        <TextInput
          label="Puhelin"
          name="phoneNumber"
          value={formState.phoneNumber}
          formValidator={formValidator}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={isUpdating}
          fullWidth
        />
      </FormInputStack>

      <FormInputStack>
        <CertificateTypeSelect
          label="Kortti"
          name="certificateSelection"
          value={(formState.certificateSelection || 'pdf') as CertificateType}
          onChange={handleSelectChange}
          error={formValidator.hasError('certificateSelection')}
        />
        <FormControl fullWidth margin="normal" error={formValidator.hasError('courseStatus')}>
          <InputLabel id="fe-course-status">Suoritus</InputLabel>
          <Select
            labelId="fe-course-status"
            name="courseStatus"
            value={formState.courseStatus}
            label="Suoritus"
            onChange={handleSelectChange}
          >
            {Object.entries(participantCourseStatuses).map(([key, value]) => (
              <MenuItem key={key} value={key}>
                {value.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </FormInputStack>

      <FormInputStack>
        <TextInput
          label="Kommentti todistukselle"
          name="customerComment"
          value={formState.customerComment}
          formValidator={formValidator}
          helperText="HUOM: Tämä tulostuu todistukselle"
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={isUpdating}
          fullWidth
          multiline
          sx={{
            '& .MuiFormHelperText-root': {
              color: '#FF6699',
            },
          }}
        />
        <TextInput
          label="Sisäinen kommentti"
          name="privateComment"
          value={formState.privateComment}
          formValidator={formValidator}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={isUpdating}
          fullWidth
          multiline
        />
      </FormInputStack>

      <CollapseButton title="Vaihda asiakas" collapseOpen={showSecondary} onClick={() => setShowSecondary(!showSecondary)} />
      <Collapse in={showSecondary}>
        <FormInputStack>
          {course.links?.customers?.map((link) => (
            <CustomerChip
              key={link.id}
              id={link.id as string}
              active={link.id === formState.customerId}
              onClick={() => {
                const newState = { ...formState };
                newState.customerId = link.id as string;
                setFormState(newState);
              }}
            />
          ))}
        </FormInputStack>
      </Collapse>

      <FormActionsBox>

        <SubmitButton
          variant="text"
          size="small"
          color="error"
          requireConfirmation
          startIcon={<DeleteIcon />}
          disabled={isUpdating}
          loading={isUpdating}
          onClick={handleDelete}
          sx={{ mr: 1 }}
        >
          Poista
        </SubmitButton>
        <Button
          variant="text"
          size="small"
          color="inherit"
          startIcon={<MoveIcon />}
          onClick={() => setShowTransferForm(true)}
        >
          Siirrä
        </Button>
        <Box flexGrow={1} />
        <Button
          size="small"
          disabled={isUpdating}
          onClick={() => onClose(false)}
          sx={{ mr: 1 }}
        >
          Peruuta
        </Button>
        <SubmitButton
          size="small"
          disabled={isUpdating}
          loading={isUpdating}
          type="submit"
          variant="contained"
        >
          Tallenna
        </SubmitButton>
      </FormActionsBox>
      {formError && (
      <InfoMessage
        sx={{ mt: 2 }}
        title={formError.error}
        message={formError.errorDescription}
        severity="error"
      />
      )}
    </form>
  );
}

export default CourseParticipantForm;
