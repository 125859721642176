import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Box,
  Divider,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { ExpiredIcon, VerifiedIcon } from '../../components/layout/icons';
import ViewContainer from '../../components/layout/ViewContainer';
import ViewPaper from '../../components/layout/ViewPaper';
import InfoMessage from '../../components/misc/InfoMessage';
import LanguageSelection from '../../components/registration/LanguageSelection';
import { decodeJws, getPublicKeys, verifyJws } from '../../utils/jws';

export default function CertVerficationView(): JSX.Element {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const jwsPayload = searchParams.get('jws') || '';
  const isValidJws = verifyJws(jwsPayload, getPublicKeys());
  const payload = isValidJws ? JSON.parse(decodeJws(jwsPayload)) : null;

  const certificateDateString = DateTime.fromISO(payload?.certificateDate as string).toFormat('dd.MM.yyyy');
  const certificateExpiresAtDate = DateTime.fromISO(payload?.certificateExpiresAtDate as string);
  const certificateExpiresAtDateString = certificateExpiresAtDate.toFormat('dd.MM.yyyy');
  const isCertificateExpired = certificateExpiresAtDate < DateTime.now();

  const content = !isValidJws ? (
    <InfoMessage
      severity="error"
      title={t('Virheellinen varmenne')}
      message={t('Digitaalista varmennetta ei voitu todentaa!')}
    />
  ) : (
    <>
      <Box display="flex" justifyContent="center" sx={{ mt: 1, mb: 3 }}>
        <Stack spacing={1} direction="row" alignItems="center">
          <VerifiedIcon color="success" />
          <Typography variant="h2" gutterBottom sx={{ color: 'green' }}>
            {`${t('Varmenne on aito')}!`}
          </Typography>
        </Stack>
      </Box>

      <Paper sx={{
        px: 2, py: 3, background: '#F5FFF5',
      }}
      >
        <Typography variant="h4" gutterBottom>
          {payload.courseName}
        </Typography>

        <Typography variant="body1" gutterBottom>
          <strong>
            {t('Nimi')}
            :&nbsp;
          </strong>
          {payload.participantName}
        </Typography>

        <Typography variant="body1" gutterBottom>
          <strong>
            {t('Päivämäärä')}
            :&nbsp;
          </strong>
          {certificateDateString}
        </Typography>

        <Stack spacing={1} direction="row" alignItems="center">
          <Typography variant="body1" gutterBottom>
            <strong>
              {t('Voimassa')}
              :&nbsp;
            </strong>
            <Typography
              variant="body1"
              component="span"
              color={isCertificateExpired ? 'error' : 'inherit'}
            >
              {certificateExpiresAtDateString}
            </Typography>

          </Typography>
          {isCertificateExpired ? (
            <ExpiredIcon color="error" data-testid="expired-icon" />
          ) : (
            <VerifiedIcon color="success" data-testid="verified-icon" />
          )}
        </Stack>

        <Divider sx={{ my: 2 }} />

        <Typography variant="body2" gutterBottom>
          {t('Tarkasta, että varmenteen tiedot täsmäävät todistukseen.')}
        </Typography>
      </Paper>
    </>
  );

  return (
    <ViewContainer maxWidth="sm">

      <ViewPaper>

        <Box display="flex" alignItems="center">
          <Box sx={{ flexGrow: 2 }}>
            <img src="/img/camilla_safety-ea.svg" alt="Camilla Safety Oy" />
          </Box>
          <LanguageSelection sx={{ flexGrow: 1 }} />
        </Box>

        {content}

        <Stack spacing={1} direction="row" alignItems="center" sx={{ mt: 4, mb: 2 }}>
          <InfoOutlinedIcon color="info" />
          <Typography variant="h2" gutterBottom>
            {t('Digitaalinen varmenne')}
          </Typography>
        </Stack>

        <Typography variant="body2" paragraph>
          {t('Camilla Safety Oy:n kurssitodistukset sisältävät digitaalisen varmenteen, jolla voidaan todentaa todistuksen aitous.')}
        </Typography>

      </ViewPaper>
    </ViewContainer>
  );
}
