import {
  BaseTextFieldProps,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';

import type { UseFormValidatorReturn } from '../../hooks/useFormValidator';

const durationUnits = [
  { value: 'Y', label: 'vuotta' },
  { value: 'M', label: 'kuukautta' },
  { value: 'W', label: 'viikkoa' },
  { value: 'D', label: 'päivää' },
  { value: 'H', label: 'tuntia' },
  // { value: 'M', label: 'minuuttia' },
  // { value: 'S', label: 'sekuntia' },
];

function parseIso8601Duration(duration: string): { value: string, unit: string } {
  const match = duration.match(/^P(\d+)([YMDWH])$/);
  if (match) {
    return { value: match[1], unit: match[2] };
  }
  return { value: '', unit: 'H' };
}

function formatIso8601Duration(duration: { value: string, unit: string }): string {
  return duration.value ? `P${duration.value}${duration.unit}` : '';
}

interface DurationInputProps extends BaseTextFieldProps {
  label: string;
  name: string;
  value: string | undefined; // ISO 8601 duration format
  formValidator: UseFormValidatorReturn;
  validUnits: string[];
  onChange: (durationString: string) => void;
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  stackProps?: Record<string, unknown>;
}

export default function DurationInput({
  label, name, value, formValidator, onChange, onBlur, validUnits,
  stackProps = {}, ...other
}: DurationInputProps): JSX.Element {
  const [duration, setDuration] = useState(parseIso8601Duration(value || ''));

  const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { name: fieldName, value: fieldValue } = target;

    const truncatedValue = Math.trunc(Number(fieldValue));

    setDuration({ ...duration, [fieldName]: truncatedValue });
    onChange(formatIso8601Duration({ ...duration, [fieldName]: truncatedValue }));
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSelectChange = (event: SelectChangeEvent<any>) => {
    const { name: fieldName, value: fieldValue } = event.target;
    setDuration({ ...duration, [fieldName]: fieldValue });
    onChange(formatIso8601Duration({ ...duration, [fieldName]: fieldValue }));
  };

  useEffect(() => {
    setDuration(parseIso8601Duration(value || ''));
  }, [value]);

  return (
    <Stack direction="row" spacing={1} {...stackProps}>
      <TextField
        variant="outlined"
        label={label}
        name="value"
        type="number"
        value={duration.value}
        error={formValidator.hasError(name)}
        helperText={formValidator.getError(name)}
        onChange={handleChange}
        onBlur={onBlur}
        {...other}
      />
      <FormControl margin="normal">
        <Select
          name="unit"
          value={duration.unit}
          onChange={handleSelectChange}
          data-testid="duration-unit-select"
          inputProps={{ 'data-testid': 'duration-unit-input' }}
        >
          {durationUnits
            .filter((unit) => validUnits.includes(unit.value))
            .map((unit) => (
              <MenuItem key={unit.value} value={unit.value}>
                {unit.label}
              </MenuItem>

            ))}
        </Select>
      </FormControl>
    </Stack>
  );
}
