/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import {
  AnyObject,
  BooleanSchema,
  InferType,
  ISchema,
  ObjectSchema,
  reach,
  Reference,
  Schema,
  ValidationError,
} from 'yup';

interface FieldsState {
  [key: string]: {
    value?: any;
    error?: string;
  };
}

export type UseFormValidatorReturn = {
  isTouched: (field: string) => boolean;
  setTouched: (field: string, touched?: boolean) => void;
  hasError: (field: string) => boolean;
  getError: (field: string) => string | undefined;
  getErrors: () => Record<string, string>;
  validate: (field: string, value: any) => void;
  validateAll: (data: any) => Promise<any>;
};

export const useFormValidator = (schema: ObjectSchema<any>) : UseFormValidatorReturn => {
  const [fieldState, setState] = useState<FieldsState>({});
  const [touched, setTouchedState] = useState<string[]>([]);

  const setTouched = (field: string) => setTouchedState([
    ...touched,
    field,
  ]);

  const isTouched = (field: string) => touched.includes(field);

  const validate = (field: string, value: any) => {
    let error = '';
    try {
      (reach(schema, field) as Schema<any>).validateSync(value);
    } catch (e: ValidationError | any) {
      error = e.message;
    }
    setState({
      ...fieldState,
      [field]: { error },
    });
  };

  const validateAll = async (data: any) => {
    try {
      return await schema.validate(data, {
        abortEarly: false,
        stripUnknown: false,
      });
    } catch (e) {
      const newState: FieldsState = {};

      // Iterate the error items
      if (e instanceof ValidationError) {
        e.inner.forEach((error: ValidationError) => {
          if (error.path === undefined) {
            throw error; // for type safety, this should never happen
          }
          newState[error.path] = {
            value: error.value,
            error: error.message,
          };
        });

        setState({
          ...fieldState,
          ...newState,
        });
      } else {
        throw e;
      }
      return false;
    }
  };

  const hasError = (field: string) => Boolean(fieldState[field]?.error);

  const getError = (field: string) => fieldState[field]?.error;

  const getErrors = () => {
    const errors: Record<string, string> = {};
    Object.keys(fieldState).forEach((field) => {
      if (fieldState[field]?.error) {
        errors[field] = fieldState[field].error as string;
      }
    });
    return errors;
  };

  return {
    isTouched,
    setTouched,
    hasError,
    getError,
    getErrors,
    validate,
    validateAll,
  };
};

type FieldSchema = Reference<unknown>
  | ISchema<any, AnyObject, any, any>
  | {
      spec?: {
        default?: string;
      };
      type?: string;
    };

export function stateFromYupSchema(
  schema: ObjectSchema<any>,
  data: any = {},
  options: any = {},
): InferType<typeof schema> {
  const state: { [key: string]: any } = {};
  const exclude = options.exclude || {};
  Object.entries(schema.fields).forEach((field: [string, FieldSchema]) => {
    // field[0] is the field name
    // field[1] is the field schema

    if ('type' in field[1] && field[1].type === 'object') {
      state[field[0]] = stateFromYupSchema(
        field[1] as ObjectSchema<any>,
        data[field[0]],
        { exclude: exclude[field[0]] },
      );
    } else if (!exclude[field[0]]) {
      if (data[field[0]] !== undefined && data[field[0]] !== null) {
        state[field[0]] = data[field[0]];
      } else if ('spec' in field[1] && typeof field[1].spec?.default === 'string' && field[1].spec?.default !== undefined) {
        state[field[0]] = field[1].spec?.default;
      } else if (field[1] instanceof BooleanSchema) {
        state[field[0]] = false;
      } else {
        state[field[0]] = '';
      }
    }
  });
  return state;
}
