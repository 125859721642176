import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  CircularProgress,
  InputLabelProps,
  SxProps,
  TextField,
  Theme,
} from '@mui/material';
import { useEffect, useState } from 'react';

import { useGetV1UsersQuery, User } from '../../store/api/backendApi';
import { serializeRTKFetchError } from '../../utils/utils';
import InfoMessage from '../misc/InfoMessage';
import UserAvatar from './UserAvatar';

 interface UserSearchProps {
  label: string,
  onSelect: (user: Partial<User>) => void,
  sx?: SxProps<Theme> | undefined
}

export default function UserSearch({
  label, onSelect, sx = {}, ...props
}: UserSearchProps) {
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>('Ladataan...');
  const [courseTypes, setUsers] = useState<readonly Partial<User>[]>([]);

  const {
    data: userData,
    error: fetchError,
    isError: isFetchError,
    isFetching,
  } = useGetV1UsersQuery();

  useEffect(() => {
    if (userData) {
      setSearchTerm('');
      setUsers(userData.data);
    }
  }, [userData]);

  if (isFetchError) {
    return (
      <InfoMessage
        title="Virhe"
        message="Tietojen lataaminen epäonnistui."
        severity="error"
        details={serializeRTKFetchError(fetchError)}
      />
    );
  }

  return (
    <Autocomplete
      id="user-search"
      sx={sx}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      inputValue={searchTerm}
      onInputChange={(event, newInputValue) => {
        if (event) {
          setSearchTerm(newInputValue);
        } else {
          setSearchTerm('');
        }
      }}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      renderOption={(optionProps, option, state, ownerState) => (
        <Box
          {...optionProps}
          component="li"
          key={option.id}
        >
          <UserAvatar
            email={option.emailAddress as string}
            name={option.name as string}
            sx={{ mr: 1 }}
          />
          {ownerState.getOptionLabel(option)}
        </Box>
      )}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option;
        }
        return option.name as string;
      }}
      options={courseTypes}
      loading={isFetching}
      onChange={(event, newValue, reason) => {
        if (reason === 'selectOption') {
          if (typeof newValue === 'object' && newValue !== null) {
            onSelect(newValue as Partial<User>);
          } else if (typeof newValue === 'string') {
            onSelect({ name: newValue });
          }
        }
        setSearchTerm('');
      }}
      renderInput={(params) => (
        <TextField
          {...(params as Partial<AutocompleteRenderInputParams>
            & { InputLabelProps: React.PropsWithChildren<InputLabelProps> })
          }
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isFetching ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      {...props}
    />
  );
}
