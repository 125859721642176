/* eslint-disable no-bitwise */

// https://stackoverflow.com/questions/36721830/convert-hsl-to-rgb-and-hex
function hslToHex(h: number, s:number, l: number) {
  // eslint-disable-next-line no-param-reassign
  l /= 100;
  // eslint-disable-next-line no-mixed-operators
  const a = s * Math.min(l, 1 - l) / 100;
  const f = (n: number) => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color).toString(16).padStart(2, '0'); // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`;
}

export function contrastColor(hex: string) {
  /* https://codepen.io/davidhalford/pen/AbKBNr */

  /* Lower threshold equals more dark text on dark background  */
  const threshold = 175;
  function cutHex(h: string) { return (h.charAt(0) === '#') ? h.substring(1, 7) : h; }
  function hexToR(h: string) { return parseInt((cutHex(h)).substring(0, 2), 16); }
  function hexToG(h: string) { return parseInt((cutHex(h)).substring(2, 4), 16); }
  function hexToB(h: string) { return parseInt((cutHex(h)).substring(4, 6), 16); }

  const hRed = hexToR(hex);
  const hGreen = hexToG(hex);
  const hBlue = hexToB(hex);

  const cBrightness = ((hRed * 299) + (hGreen * 587) + (hBlue * 114)) / 1000;
  if (cBrightness > threshold) { return '#000000'; } return '#ffffff';
}

export function stringToColor(input: string) {
  // Generate a hash code for the name
  let hash = 0;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < input.length; i++) {
    hash = input.charCodeAt(i) + ((hash << 5) - hash);
  }

  // Convert the hash code to HSL color values
  const hue = Math.abs(hash % 360);
  const saturation = 25 + ((hash >> 8) % 75); // limit 25-100%
  const lightness = 70 + ((hash >> 16) % 15); // limit 70-85%

  const hexColor = hslToHex(hue, saturation, lightness); // Convert RGB to hex

  return hexColor;
}
