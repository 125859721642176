import {
  createApi,
} from '@reduxjs/toolkit/query/react';

import apiBaseQuery from './apiBaseQuery';

export type QueryReturnValue<T = unknown, E = unknown, M = unknown> =
| {
  error: E
  data?: undefined
  meta?: M
}
| {
  error?: undefined
  data: T
  meta?: M
}

export const baseApi = createApi({
  baseQuery: apiBaseQuery,
  reducerPath: 'baseApi',
  endpoints: () => ({}),
});
