import jws from 'jws';

const PUBLIC_KEYS = {
  dev: [
    // test (not actually used, mocked in tests)
    '-----BEGIN PUBLIC KEY-----\nMIGbMBAGByqGSM49AgEGBSuBBAAjA4GGAAQAIvmmHTor8Y7NmS1G6CqeWubHMtUg\nzu2tQ2bm3mfXYlmFsJrFU2Ht9C/gBRo8viSijk9J5beCexo+cYxesHb6T9UAXa1t\nxgkeko49/pqm0lubdcXLww9h2dQztIsqwkhy80ULQqmRaf8Q54SpixK71p8QpsNr\nYvC7cG2OIYThdCFuB2g=\n-----END PUBLIC KEY-----',
  ],
  staging: [
    '-----BEGIN PUBLIC KEY-----\nMIGbMBAGByqGSM49AgEGBSuBBAAjA4GGAAQArwE7163nMNJdf5Z+d+uz88jSSsUm\n0PMZxkcOBvKLc0j6ySvJpoiglSHrUrL8BkXSlf+QTGGmmV63Aqz4semzycQAgmHX\nsGZoaFkPGULdw3sd3oCjbjBRlG5POq+f385VUWiThd45OOrUckSqYeYVa+0ZhiEy\n/SocLb3JlOZY31/UCrg=\n-----END PUBLIC KEY-----',
  ],
  prod: [
    '-----BEGIN PUBLIC KEY-----\nMIGbMBAGByqGSM49AgEGBSuBBAAjA4GGAAQBDygfCCrmuaqR7/b7rGkS2Wtw4JkS\nmvKFT2SMN7LtU/240qHVmBMf22RUZdNN0h/VJUugbQyMfzNYjEES2SNX1PEBZe4b\n0g2meXvkpjfVRgNCWywPj+ZHeQwlLPJ9ZcsjEDTRyZarba1XsZS/+o3X8AsgQptp\n4MrDFCCqy/AjEkVhNZA=\n-----END PUBLIC KEY-----',
  ],
};

export const getPublicKeys = (): string[] => {
  const stage = process.env.REACT_APP_STAGE || 'dev';

  if (!PUBLIC_KEYS[stage as keyof typeof PUBLIC_KEYS]) {
    throw new Error(`No public keys found for stage ${stage}`);
  }

  return PUBLIC_KEYS[stage as keyof typeof PUBLIC_KEYS];
};

export const verifyJws = (payload: string, publicKeys: string[]): boolean => {
  if (!Array.isArray(publicKeys)) {
    throw new Error(`publicKeys must be an array, got ${typeof publicKeys}`);
  }

  return publicKeys.some((publicKey) => {
    try {
      return jws.verify(payload, 'ES512', publicKey);
    } catch {
      // Ignore the error and continue with the next key
      return false;
    }
  });
  return false;
};

export const decodeJws = (payload: string): string => {
  try {
    return jws.decode(payload).payload;
  } catch {
    return '';
  }
};
