import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { DateTime } from 'luxon';
import { useState } from 'react';

import {
  Course,
  usePostV1CoursesByIdCloseMutation,
} from '../../store/api/backendApi';
import {
  isErrorWithFailureFrame,
  isErrorWithMessage,
  isFetchBaseQueryError,
} from '../../utils/utils';
import SubmitButton from '../form/SubmitButton';
import { CheckIcon } from '../layout/icons';
import InfoMessage from '../misc/InfoMessage';

interface CourseClosingProps {
  course: Course;
  onClose: (needsRefresh: boolean) => void;
}

export default function CourseClosing(
  {
    course,
    onClose,
  }: CourseClosingProps,
) {
  const [formError, setFormError] = useState<
    {error: string, errorDescription: string} | false
  >(false);
  const [postCourseClose, { isLoading: isSaving }] = usePostV1CoursesByIdCloseMutation();

  const courseEndDateTimeText = course.endDateTime
    ? `Tarkista kurssin päättymispäivä: ${DateTime.fromISO(course.endDateTime).toFormat('dd.MM.yyyy')}`
    : 'Kurssin päättymispäivää ei ole asetettu. Kurssin päättymispäiväksi asetetaan tämä päivä. OK?';

  const handleClose = async (event: React.FormEvent) => {
    setFormError(false);
    event.preventDefault();
    try {
      const payload = {
        id: course.id,
      };

      await postCourseClose(payload).unwrap();

      onClose(true);
    } catch (e) {
      const errorInfo = {
        error: 'Kurssin sulkeminen epäonnistui',
        errorDescription: 'Tuntematon virhe',
      };

      if (isErrorWithFailureFrame(e)) {
        errorInfo.errorDescription = `Palvelinvirhe (${e.data.error?.message})`;
      } else if (isFetchBaseQueryError(e)) {
        errorInfo.errorDescription = 'Palvelinvirhe (verkkovirhe)';
      } else if (isErrorWithMessage(e)) {
        errorInfo.errorDescription = e.message;
      }

      setFormError(errorInfo);
    }
  };

  return (
    <>
      <List>
        <ListItem disablePadding>
          <ListItemIcon>
            <CheckIcon />
          </ListItemIcon>
          <ListItemText
            primary={courseEndDateTimeText}
          />
        </ListItem>
        <ListItem disablePadding>
          <ListItemIcon>
            <CheckIcon />
          </ListItemIcon>
          <ListItemText primary="Tarkasta kurssin tilaajan yhteystiedot" />
        </ListItem>
        <ListItem disablePadding>
          <ListItemIcon>
            <CheckIcon />
          </ListItemIcon>
          <ListItemText primary="Tarkasta osallistujien tiedot" />
        </ListItem>
        <ListItem disablePadding>
          <ListItemIcon>
            <CheckIcon />
          </ListItemIcon>
          <ListItemText primary="Tarkasta osallistujien korttitilanne" />
        </ListItem>
        <ListItem disablePadding>
          <ListItemIcon>
            <CheckIcon />
          </ListItemIcon>
          <ListItemText primary="Tarkasta osallistujien hyväksyntä" />
        </ListItem>
      </List>

      <SubmitButton
        variant="contained"
        color="primary"
        onClick={handleClose}
        loading={isSaving}
        disabled={isSaving}
        requireConfirmation
      >
        Merkitse kurssi valmiiksi
      </SubmitButton>

      {formError && (
        <InfoMessage
          sx={{ mt: 2 }}
          title={formError.error}
          message={formError.errorDescription}
          severity="error"
        />
      )}
    </>
  );
}
