import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './i18n/i18n';

import {
  CssBaseline,
} from '@mui/material';
import {
  ThemeProvider,
} from '@mui/material/styles';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import {
  BrowserRouter,
} from 'react-router-dom';

import App from './App';
import ErrorBoundary from './components/layout/ErrorBoundary';
import reportWebVitals from './reportWebVitals';
import { createStore } from './store';
import theme from './theme';

const store = createStore();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <ErrorBoundary>
            <CssBaseline />
            <App />
          </ErrorBoundary>
        </Provider>
      </ThemeProvider>
    </BrowserRouter>
    ,
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
