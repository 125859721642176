/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Stack,
  Typography,
} from '@mui/material';
import { getAuth } from 'firebase/auth';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import CourseSelectionList from '../components/courses/CourseSelectionList';
import {
  CoursesIcon,
} from '../components/layout/icons';
import ViewContainer from '../components/layout/ViewContainer';
import ViewPaper from '../components/layout/ViewPaper';
import InfoMessage from '../components/misc/InfoMessage';
import LoadingIndicator from '../components/misc/LoadingIndicator';
import { useAppSelector } from '../store';
import { Course, useGetV1CoursesQuery, useGetV1UsersByIdQuery } from '../store/api/backendApi';
import { courseFilter, courseSort, serializeRTKFetchError } from '../utils/utils';

function DashboardView(): JSX.Element {
  const firebaseAuth = getAuth();
  const navigate = useNavigate();
  const userRoles = useAppSelector((state) => state.userState.roles);
  const userName = useAppSelector((state) => state.userState.data?.name) as string;
  const userId = useAppSelector((state) => state.userState.data?.id) as string;

  if (!firebaseAuth.currentUser) {
    navigate('/logout');
  }

  const {
    data: courseData,
    isLoading: isLoadingCourses,
    isError: isCourseLoadingError,
    error: courseLoadingError,
  } = useGetV1CoursesQuery();

  const myCourses = useMemo(() => {
    if (!courseData || !userId) {
      return [];
    }

    const filterOptions = {
      search: '',
      ownOnly: true,
      nextFourWeeks: true,
      lastFourWeeks: true,
      unInvoiced: false,
      courseStatuses: ['pending' as const, 'started' as const],
    };

    const filtered = courseData.data.filter(
      (course: Course) => courseFilter(course, filterOptions, false, userId),
    ) as Course[];

    filtered.sort((a: Course, b: Course) => courseSort(a, b, 'startDateTime', 'asc'));

    return filtered;
  }, [courseData, userId]);

  if (isLoadingCourses) {
    return (
      <LoadingIndicator caption="Ladataan tietoja..." />
    );
  }

  if (isCourseLoadingError) {
    return (
      <InfoMessage
        title="Virhe"
        message="Tietojen lataaminen epäonnistui."
        severity="error"
        details={serializeRTKFetchError(courseLoadingError)}
      />
    );
  }

  if (!userId) { // this should never happen...
    throw new Error('user not found');
  }

  return (
    <ViewContainer>

      <ViewPaper>

        <Typography variant="h2" gutterBottom>
          Tervetuloa,
          {' '}
          {userName }
          !
        </Typography>

        <Stack direction="row" alignItems="center" gap={1} sx={{ px: 3, mt: 3 }}>
          <CoursesIcon />
          <Typography variant="h3">
            Omat kurssisi lähiviikoilla
          </Typography>
        </Stack>
        <CourseSelectionList courses={myCourses} />
        <Typography variant="body2" color="text.secondary" sx={{ px: 3 }}>
          Päättyneet kurssit piilotettu.
        </Typography>

      </ViewPaper>
    </ViewContainer>
  );
}

export default DashboardView;
