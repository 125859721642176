import { Home as HomeIcon } from '@mui/icons-material';
import {
  Box,
  Icon,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { Customer } from '../../store/api/backendApi';
import InfoMessage from '../misc/InfoMessage';

function CustomerListItem({ customer }: { customer: Customer }): JSX.Element {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        p: 1,
        mb: 1,
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: '#fff9f9',
        },
        borderTop: '1px solid #e0e0e0',
      }}
      onClick={() => navigate(`/customers/${customer.id}`)}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Typography variant="h4" gutterBottom>
            {customer.name}
          </Typography>
          <Typography variant="body2" gutterBottom>
            {customer.contactPerson?.name}
          </Typography>
        </Box>
        <Box>
          <Typography variant="body2" paragraph />

          <Typography variant="body2" gutterBottom display="flex" alignItems="center">
            <Icon sx={{ mr: 1 }}>
              <HomeIcon />
            </Icon>
            {customer.address?.city}
          </Typography>
        </Box>
      </Box>

    </Box>
  );
}

interface CustomerListProps {
  customers: Customer[];
}

function CustomerList({ customers }:CustomerListProps): JSX.Element {
  if (customers.length === 0) {
    return <InfoMessage severity="info" message="Ei näytettäviä asiakkaita" />;
  }

  return (
    <Box>
      {customers.map((customer) => (
        <CustomerListItem key={customer.id} customer={customer} />
      ))}
    </Box>
  );
}

export default CustomerList;
