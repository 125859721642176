import {
  Box,
  Container,
  Link,
  Paper,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import LanguageSelection from '../../components/registration/LanguageSelection';

function ParticipantEntrySuccessView(): JSX.Element {
  const { t } = useTranslation();
  return (
    <Container component="main" maxWidth="xs">

      <Paper sx={{ p: 2, mt: 15 }}>

        <Box display="flex" alignItems="center">
          <Box sx={{ flexGrow: 2 }}>
            <img src="/img/camilla_safety-ea.svg" alt="Camilla Safety Oy" />
          </Box>
          <LanguageSelection sx={{ flexGrow: 1 }} />
        </Box>

        <Typography variant="h2" gutterBottom>
          {t('Kiitos!')}
        </Typography>

        <Typography variant="body1" paragraph>
          {t('Tiedot vastaanotettu')}
          .
        </Typography>

      </Paper>
      <Box sx={{ mt: 2 }}>
        <Typography variant="body2" color="text.secondary" align="center">
          <Link component={RouterLink} underline="none" to="/">{t('Ilmoita toinen')}</Link>
        </Typography>
      </Box>
    </Container>
  );
}

export default ParticipantEntrySuccessView;
