import {
  Box,
  debounce,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

import Breadcrumbs from '../../components/layout/Breadcrumbs';
import { CloseIcon } from '../../components/layout/icons';
import Restricted from '../../components/layout/Restricted';
import ViewContainer from '../../components/layout/ViewContainer';
import ViewPaper from '../../components/layout/ViewPaper';
import InfoMessage from '../../components/misc/InfoMessage';
import ParticipantSearchResults from '../../components/participants/ParticipantSearchResults';
import { useAppDispatch, useAppSelector } from '../../store';
import { useGetV1ParticipantsQuery } from '../../store/api/backendApi';
import { setParticipantFilterOptions } from '../../store/userSlice';
import { serializeRTKFetchError } from '../../utils/utils';

const MIN_SEARCH_LENGTH = 4;

export default function ParticipantsView(): JSX.Element {
  const dispatch = useAppDispatch();
  const participantFilterOptions = useAppSelector(
    (state) => state.userState.participantFilterOptions,
  );
  const [query, setQuery] = useState<string>('');
  const {
    data: participantsData,
    error: fetchError,
    isError,
    isFetching,
  } = useGetV1ParticipantsQuery(
    { q: query },
    { skip: query.length < MIN_SEARCH_LENGTH, refetchOnMountOrArgChange: true },
  );

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setParticipantFilterOptions({ search: event.target.value }));
  };

  const handleSearchReset = () => {
    dispatch(setParticipantFilterOptions({ search: '' }));
  };

  const debouncedSearch = useCallback(
    debounce((q) => {
      setQuery(q);
    }, 1000),
    [],
  );

  useEffect(() => {
    debouncedSearch(participantFilterOptions.search);
  }, [participantFilterOptions.search, debouncedSearch]);

  if (isError) {
    return (
      <ViewContainer>

        <Breadcrumbs items={[{ label: 'Osallistujat' }]} />
        <ViewPaper>
          <InfoMessage
            title="Virhe"
            message="Osallistujien lataaminen epäonnistui."
            severity="error"
            details={serializeRTKFetchError(fetchError)}
          />
        </ViewPaper>
      </ViewContainer>
    );
  }

  return (
    <ViewContainer>
      <Restricted requires={['admin']}>
        <>

          <Breadcrumbs items={[{ label: 'Osallistujat' }]} />

          <ViewPaper>
            <Typography variant="h2" gutterBottom>
              Osallistujat
            </Typography>

            <Box sx={{ mt: 2, mb: 2 }}>
              <TextField
                fullWidth
                label="Hae osallistujaa"
                variant="outlined"
                value={participantFilterOptions.search}
                onChange={handleSearchChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <IconButton onClick={handleSearchReset}>
                        <CloseIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>

            {query.length < MIN_SEARCH_LENGTH ? (
              <InfoMessage
                message={`Anna vähintään ${MIN_SEARCH_LENGTH} merkkiä.`}
                severity="info"
              />
            ) : (
              <ParticipantSearchResults
                isLoading={isFetching}
                participants={participantsData?.data || []}
              />
            ) }

          </ViewPaper>
        </>
      </Restricted>
    </ViewContainer>
  );
}
