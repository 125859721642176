import {
  configureStore,
  PreloadedState,
  StateFromReducersMapObject,
} from '@reduxjs/toolkit';
import type { TypedUseSelectorHook } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';

import { baseApi } from './api/index';
import userReducer from './userSlice';

const reducer = {
  userState: userReducer,
  [baseApi.reducerPath]: baseApi.reducer,
};

export type RootState = StateFromReducersMapObject<typeof reducer>
export type CreateStorePreloadedState = PreloadedState<Partial<RootState>>

export const createStore = (preloadedState?: CreateStorePreloadedState) => configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware()
    .concat(baseApi.middleware),
  preloadedState,
});

export type Store = ReturnType<typeof createStore>

export type AppDispatch = Store['dispatch']

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
