import { createTheme, responsiveFontSizes } from '@mui/material/styles';

const theme = responsiveFontSizes(createTheme({
  palette: {
    primary: {
      main: '#A50E7B',
    },
    secondary: {
      main: '#295DCE',
    },
  },
  typography: {
    h1: {
      fontSize: '2.3rem',
      fontWeight: 300,
    },
    h2: {
      fontSize: '1.8rem',
      fontWeight: 300,
    },
    h3: {
      fontSize: '1.5rem',
      fontWeight: 300,
    },
    h4: {
      fontSize: '1.4rem',
      fontWeight: 300,
    },
    h5: {
      fontSize: '1.25rem',
      fontWeight: 300,
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 300,
    },
    body1: {
      fontWeight: 300,
    },
    body2: {
      fontWeight: 300,
    },
  },
}));

export default theme;
