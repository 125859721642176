import {
  Avatar,
  BoxProps,
} from '@mui/material';

import { contrastColor, stringToColor } from '../../utils/colors';

type UserAvatarProps = {
  name: string;
  email: string;
  sx?: BoxProps['sx'];
};

function UserAvatar({ name, email, sx = {} } : UserAvatarProps): JSX.Element {
  const initials = name.split(' ').map((n) => n[0]).join('');
  const color = stringToColor(email);
  return (
    <Avatar
      sx={{
        bgcolor: color,
        color: contrastColor(color),
        width: 20,
        height: 20,
        fontSize: 10,
        ...sx,
      }}
    >
      {initials}
    </Avatar>
  );
}

export default UserAvatar;
