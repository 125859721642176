import {
  Avatar,
  BoxProps,
} from '@mui/material';

import { CourseType } from '../../store/api/backendApi';
import { contrastColor } from '../../utils/colors';

type CourseTypeAvatarProps = {
  category: CourseType['category'];
  sx?: BoxProps['sx'];
};

function CourseTypeAvatar({ category, sx = {} } : CourseTypeAvatarProps): JSX.Element {
  let color = '';
  let label = '';
  switch (category) {
    case 'firstAid':
      color = '#A50E7B';
      label = 'EA';
      break;
    case 'threat':
      color = '#74C3B8';
      label = 'U';
      break;
    case 'fire':
      color = '#255AA0';
      label = 'P';
      break;
    case 'other':
    default:
      color = '#4A4B4C';
      label = 'M';
      break;
  }

  return (
    <Avatar
      variant="square"
      sx={{
        bgcolor: color,
        color: contrastColor(color),
        width: 20,
        height: 20,
        fontSize: 11,
        ...sx,
      }}
    >
      {label}
    </Avatar>
  );
}

export default CourseTypeAvatar;
