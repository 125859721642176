import { baseApi as api } from "./index";
export const addTagTypes = [
  "Course certificates",
  "Course certificate",
  "Course types",
  "Courses",
  "Customers",
  "Relations",
  "Participants",
  "Users",
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getV1CourseCertificates: build.query<
        GetV1CourseCertificatesApiResponse,
        GetV1CourseCertificatesApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/course-certificates`,
          params: { courseId: queryArg.courseId },
        }),
        providesTags: ["Course certificates"],
      }),
      getV1CourseCertificatesById: build.query<
        GetV1CourseCertificatesByIdApiResponse,
        GetV1CourseCertificatesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/course-certificates/${queryArg.id}`,
        }),
        providesTags: ["Course certificate"],
      }),
      getV1CourseCertificatesByIdDownload: build.query<
        GetV1CourseCertificatesByIdDownloadApiResponse,
        GetV1CourseCertificatesByIdDownloadApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/course-certificates/${queryArg.id}/download`,
        }),
        providesTags: ["Course certificate"],
      }),
      getV1CourseTypes: build.query<
        GetV1CourseTypesApiResponse,
        GetV1CourseTypesApiArg
      >({
        query: () => ({ url: `/v1/course-types` }),
        providesTags: ["Course types"],
      }),
      postV1CourseTypes: build.mutation<
        PostV1CourseTypesApiResponse,
        PostV1CourseTypesApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/course-types`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["Course types"],
      }),
      getV1CourseTypesById: build.query<
        GetV1CourseTypesByIdApiResponse,
        GetV1CourseTypesByIdApiArg
      >({
        query: (queryArg) => ({ url: `/v1/course-types/${queryArg.id}` }),
        providesTags: ["Course types"],
      }),
      putV1CourseTypesById: build.mutation<
        PutV1CourseTypesByIdApiResponse,
        PutV1CourseTypesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/course-types/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["Course types"],
      }),
      deleteV1CourseTypesById: build.mutation<
        DeleteV1CourseTypesByIdApiResponse,
        DeleteV1CourseTypesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/course-types/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Course types"],
      }),
      getV1Courses: build.query<GetV1CoursesApiResponse, GetV1CoursesApiArg>({
        query: () => ({ url: `/v1/courses` }),
        providesTags: ["Courses"],
      }),
      postV1Courses: build.mutation<
        PostV1CoursesApiResponse,
        PostV1CoursesApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/courses`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["Courses"],
      }),
      getV1CoursesById: build.query<
        GetV1CoursesByIdApiResponse,
        GetV1CoursesByIdApiArg
      >({
        query: (queryArg) => ({ url: `/v1/courses/${queryArg.id}` }),
        providesTags: ["Courses"],
      }),
      putV1CoursesById: build.mutation<
        PutV1CoursesByIdApiResponse,
        PutV1CoursesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/courses/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["Courses"],
      }),
      deleteV1CoursesById: build.mutation<
        DeleteV1CoursesByIdApiResponse,
        DeleteV1CoursesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/courses/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Courses"],
      }),
      postV1CoursesByIdClose: build.mutation<
        PostV1CoursesByIdCloseApiResponse,
        PostV1CoursesByIdCloseApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/courses/${queryArg.id}/close`,
          method: "POST",
          params: { runAutomationIds: queryArg.runAutomationIds },
        }),
        invalidatesTags: ["Courses"],
      }),
      postV1CoursesVerifyPinCode: build.mutation<
        PostV1CoursesVerifyPinCodeApiResponse,
        PostV1CoursesVerifyPinCodeApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/courses/verify-pin-code`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["Courses"],
      }),
      getV1Customers: build.query<
        GetV1CustomersApiResponse,
        GetV1CustomersApiArg
      >({
        query: () => ({ url: `/v1/customers` }),
        providesTags: ["Customers"],
      }),
      postV1Customers: build.mutation<
        PostV1CustomersApiResponse,
        PostV1CustomersApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/customers`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["Customers"],
      }),
      getV1CustomersById: build.query<
        GetV1CustomersByIdApiResponse,
        GetV1CustomersByIdApiArg
      >({
        query: (queryArg) => ({ url: `/v1/customers/${queryArg.id}` }),
        providesTags: ["Customers"],
      }),
      putV1CustomersById: build.mutation<
        PutV1CustomersByIdApiResponse,
        PutV1CustomersByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/customers/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["Customers"],
      }),
      deleteV1CustomersById: build.mutation<
        DeleteV1CustomersByIdApiResponse,
        DeleteV1CustomersByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/customers/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Customers"],
      }),
      postV1Relations: build.mutation<
        PostV1RelationsApiResponse,
        PostV1RelationsApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/relations`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["Relations"],
      }),
      putV1RelationsById: build.mutation<
        PutV1RelationsByIdApiResponse,
        PutV1RelationsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/relations/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["Relations"],
      }),
      deleteV1RelationsById: build.mutation<
        DeleteV1RelationsByIdApiResponse,
        DeleteV1RelationsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/relations/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Relations"],
      }),
      getV1Participants: build.query<
        GetV1ParticipantsApiResponse,
        GetV1ParticipantsApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/participants`,
          params: { courseId: queryArg.courseId, q: queryArg.q },
        }),
        providesTags: ["Participants"],
      }),
      postV1Participants: build.mutation<
        PostV1ParticipantsApiResponse,
        PostV1ParticipantsApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/participants`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["Participants"],
      }),
      getV1ParticipantsById: build.query<
        GetV1ParticipantsByIdApiResponse,
        GetV1ParticipantsByIdApiArg
      >({
        query: (queryArg) => ({ url: `/v1/participants/${queryArg.id}` }),
        providesTags: ["Participants"],
      }),
      putV1ParticipantsById: build.mutation<
        PutV1ParticipantsByIdApiResponse,
        PutV1ParticipantsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/participants/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["Participants"],
      }),
      deleteV1ParticipantsById: build.mutation<
        DeleteV1ParticipantsByIdApiResponse,
        DeleteV1ParticipantsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/participants/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Participants"],
      }),
      postV1ParticipantsByIdTransfer: build.mutation<
        PostV1ParticipantsByIdTransferApiResponse,
        PostV1ParticipantsByIdTransferApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/participants/${queryArg.id}/transfer`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["Participants"],
      }),
      getV1Users: build.query<GetV1UsersApiResponse, GetV1UsersApiArg>({
        query: () => ({ url: `/v1/users` }),
        providesTags: ["Users"],
      }),
      getV1UsersById: build.query<
        GetV1UsersByIdApiResponse,
        GetV1UsersByIdApiArg
      >({
        query: (queryArg) => ({ url: `/v1/users/${queryArg.id}` }),
        providesTags: ["Users"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as backendApi };
export type GetV1CourseCertificatesApiResponse =
  /** status 200 Success response. */ ListFrame & {
    data?: CourseCertificate[];
  };
export type GetV1CourseCertificatesApiArg = {
  courseId: string;
};
export type GetV1CourseCertificatesByIdApiResponse =
  /** status 200 Success response. */ ObjectFrame & {
    data?: CourseCertificate;
  };
export type GetV1CourseCertificatesByIdApiArg = {
  /** Primary key of document. */
  id: string;
};
export type GetV1CourseCertificatesByIdDownloadApiResponse =
  /** status 200 Success response. */ ObjectFrame & {
    data?: CourseCertificateDownloadUrlResponse;
  };
export type GetV1CourseCertificatesByIdDownloadApiArg = {
  /** Primary key of document. */
  id: string;
};
export type GetV1CourseTypesApiResponse =
  /** status 200 Success response. */ ListFrame & {
    data?: CourseType[];
  };
export type GetV1CourseTypesApiArg = void;
export type PostV1CourseTypesApiResponse =
  /** status 200 Success response. */ SuccessStatusFrame;
export type PostV1CourseTypesApiArg = {
  body: {
    name: string;
    category: "firstAid" | "fire" | "threat" | "other";
    courseDescription?: string | null;
    pdfOptions?: {
      templateId?: string;
      [key: string]: any;
    }[];
    courseDuration: string;
    cardValidForDuration: string;
  };
};
export type GetV1CourseTypesByIdApiResponse =
  /** status 200 Success response. */ ObjectFrame & {
    data?: CourseType;
  };
export type GetV1CourseTypesByIdApiArg = {
  /** Primary key of document. */
  id: string;
};
export type PutV1CourseTypesByIdApiResponse =
  /** status 200 Success response. */ SuccessStatusFrame;
export type PutV1CourseTypesByIdApiArg = {
  /** Primary key of document. */
  id: string;
  body: {
    name: string;
    category: "firstAid" | "fire" | "threat" | "other";
    courseDescription?: string | null;
    pdfOptions?: {
      templateId?: string;
      [key: string]: any;
    }[];
    courseDuration: string;
    cardValidForDuration: string;
  };
};
export type DeleteV1CourseTypesByIdApiResponse =
  /** status 200 Success response. */ SuccessStatusFrame;
export type DeleteV1CourseTypesByIdApiArg = {
  /** Primary key of document. */
  id: string;
};
export type GetV1CoursesApiResponse =
  /** status 200 Success response. */ ListFrame & {
    data?: Course[];
  };
export type GetV1CoursesApiArg = void;
export type PostV1CoursesApiResponse =
  /** status 200 Success response. */ SuccessStatusFrame;
export type PostV1CoursesApiArg = {
  body: {
    primaryCustomerId: string;
    courseTypeId: string;
    instructorUserId?: string;
    name: string;
    startDateTime?: string | null;
    endDateTime?: string | null;
    defaultCertificateSelection?: CertificateType;
    address?: AddressProperties;
    notes?: string | null;
    courseDescription?: string | null;
    contactPerson?: ContactPersonsProperties;
  };
};
export type GetV1CoursesByIdApiResponse =
  /** status 200 Success response. */ ObjectFrame & {
    data?: Course & {
      links?: {
        customers?: ResourceLink;
        courseTypes?: ResourceLink;
        users?: ResourceLink;
      };
    };
  };
export type GetV1CoursesByIdApiArg = {
  /** Primary key of document. */
  id: string;
};
export type PutV1CoursesByIdApiResponse =
  /** status 200 Success response. */ SuccessStatusFrame;
export type PutV1CoursesByIdApiArg = {
  /** Primary key of document. */
  id: string;
  body: {
    primaryCustomerId: string;
    courseTypeId: string;
    instructorUserId?: string;
    name: string;
    startDateTime?: string | null;
    endDateTime?: string | null;
    defaultCertificateSelection?: CertificateType;
    address?: AddressProperties;
    notes?: string | null;
    courseDescription?: string | null;
    contactPerson?: ContactPersonsProperties;
    status: "pending" | "started" | "closed" | "finalized";
    invoicingStatus: "pending" | "incomplete" | "finalized" | "notInvoiceable";
  };
};
export type DeleteV1CoursesByIdApiResponse =
  /** status 200 Success response. */ SuccessStatusFrame;
export type DeleteV1CoursesByIdApiArg = {
  /** Primary key of document. */
  id: string;
};
export type PostV1CoursesByIdCloseApiResponse =
  /** status 200 Success response. */ SuccessStatusFrame;
export type PostV1CoursesByIdCloseApiArg = {
  /** Primary key of document. */
  id: string;
  /** Automation IDs to run immediately after closing course. If this parameter is empty, automations won't be run immediately. This parameter is only usable in development environment. It has no effect in production environments. */
  runAutomationIds?: ("customer" | "participant")[] | null;
};
export type PostV1CoursesVerifyPinCodeApiResponse =
  /** status 200 Success response. */ ObjectFrame & {
    data?: PinCodeVerificationResponse;
  };
export type PostV1CoursesVerifyPinCodeApiArg = {
  body: {
    pinCode: string;
  };
};
export type GetV1CustomersApiResponse =
  /** status 200 Success response. */ ListFrame & {
    data?: Customer[];
  };
export type GetV1CustomersApiArg = void;
export type PostV1CustomersApiResponse =
  /** status 200 Success response. */ SuccessStatusFrame;
export type PostV1CustomersApiArg = {
  body: {
    name: string;
    address?: AddressProperties;
    contactPerson?: ContactPersonsProperties;
    comment?: string | null;
  };
};
export type GetV1CustomersByIdApiResponse =
  /** status 200 Success response. */ ObjectFrame & {
    data?: Customer & {
      links?: {
        courses?: ResourceLink;
      };
    };
  };
export type GetV1CustomersByIdApiArg = {
  /** Primary key of document. */
  id: string;
};
export type PutV1CustomersByIdApiResponse =
  /** status 200 Success response. */ SuccessStatusFrame;
export type PutV1CustomersByIdApiArg = {
  /** Primary key of document. */
  id: string;
  body: {
    name: string;
    address?: AddressProperties;
    contactPerson?: ContactPersonsProperties;
    comment?: string | null;
  };
};
export type DeleteV1CustomersByIdApiResponse =
  /** status 200 Success response. */ SuccessStatusFrame;
export type DeleteV1CustomersByIdApiArg = {
  /** Primary key of document. */
  id: string;
};
export type PostV1RelationsApiResponse =
  /** status 200 Success response. */ SuccessStatusFrame;
export type PostV1RelationsApiArg = {
  body: {
    type: "customer-participates-in-course";
    secondaryCustomerId: string;
    courseId: string;
    contactPerson?: ContactPersonsProperties;
    comment?: string | null;
  };
};
export type PutV1RelationsByIdApiResponse =
  /** status 200 Success response. */ SuccessStatusFrame;
export type PutV1RelationsByIdApiArg = {
  /** Primary key of document. */
  id: string;
  body: {
    contactPerson?: ContactPersonsProperties;
    comment?: string | null;
  };
};
export type DeleteV1RelationsByIdApiResponse =
  /** status 200 Success response. */ SuccessStatusFrame;
export type DeleteV1RelationsByIdApiArg = {
  /** Primary key of document. */
  id: string;
};
export type GetV1ParticipantsApiResponse =
  /** status 200 Success response. */ ListFrame & {
    data?: Participant[];
  };
export type GetV1ParticipantsApiArg = {
  courseId?: string;
  q?: string;
};
export type PostV1ParticipantsApiResponse =
  /** status 200 Success response. */ SuccessStatusFrame;
export type PostV1ParticipantsApiArg = {
  body: {
    participant: {
      customerId: string;
      firstName: string;
      lastName: string;
      emailAddress: string;
      phoneNumber: string;
      certificateSelection: "none" | "pdf" | "card" | "cardGiven" | "renewCard";
      customerComment?: string | null;
      privateComment?: string | null;
    };
    pinCode: string;
  };
};
export type GetV1ParticipantsByIdApiResponse =
  /** status 200 Success response. */ ObjectFrame & {
    data?: Participant & {
      links?: {
        courses?: ResourceLink;
        customers?: ResourceLink;
      };
    };
  };
export type GetV1ParticipantsByIdApiArg = {
  /** Primary key of document. */
  id: string;
};
export type PutV1ParticipantsByIdApiResponse =
  /** status 200 Success response. */ SuccessStatusFrame;
export type PutV1ParticipantsByIdApiArg = {
  /** Primary key of document. */
  id: string;
  body: {
    customerId: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    certificateSelection: "none" | "pdf" | "card" | "cardGiven" | "renewCard";
    courseStatus: "pending" | "accepted" | "rejected";
    customerComment?: string | null;
    privateComment?: string | null;
  };
};
export type DeleteV1ParticipantsByIdApiResponse =
  /** status 200 Success response. */ SuccessStatusFrame;
export type DeleteV1ParticipantsByIdApiArg = {
  /** Primary key of document. */
  id: string;
};
export type PostV1ParticipantsByIdTransferApiResponse =
  /** status 200 Success response. */ SuccessStatusFrame;
export type PostV1ParticipantsByIdTransferApiArg = {
  /** Primary key of document. */
  id: string;
  body: {
    courseId: string;
  };
};
export type GetV1UsersApiResponse =
  /** status 200 Success response. */ ListFrame & {
    data?: User[];
  };
export type GetV1UsersApiArg = void;
export type GetV1UsersByIdApiResponse =
  /** status 200 Success response. */ ObjectFrame & {
    data?: User;
  };
export type GetV1UsersByIdApiArg = {
  /** Primary key of document. */
  id: string;
};
export type ListFrame = {
  kind: "list";
  data: object[];
};
export type Metadata = {
  id: string;
  createdAt: number;
  updatedAt: number;
  updatedByUserId: string;
};
export type CourseCertificate = Metadata & {
  type: "customer" | "participant";
  courseId: string;
  customerId: string;
  participantId?: string | null;
  bucket: string;
  bucketKeyPrefix: string;
  filename: string;
};
export type Error = {
  message: string;
  type: "internalError" | "requestError";
  code: number;
};
export type FailureStatusFrame = {
  kind: "status";
  status: "Failure";
  error?: Error;
  metadata: {
    [key: string]: any;
  };
};
export type ObjectFrame = {
  kind: "object";
  data: object;
};
export type CourseCertificateDownloadUrlResponse = {
  id: string;
  filename: string;
  downloadUrl: string;
};
export type CourseType = Metadata & {
  name: string;
  category: "firstAid" | "fire" | "threat" | "other";
  courseDescription?: string | null;
  pdfOptions?: {
    templateId?: string;
    [key: string]: any;
  }[];
  courseDuration: string;
  cardValidForDuration: string;
};
export type SuccessStatusFrame = {
  kind: "status";
  status: "Success";
  metadata: {
    [key: string]: any;
  };
};
export type CertificateType =
  | "none"
  | "pdf"
  | "card"
  | "cardGiven"
  | "renewCard";
export type AddressProperties = {
  street?: string | null;
  postalCode?: string | null;
  city?: string | null;
  country?: string | null;
};
export type ContactPersonsProperties = {
  firstName?: string | null;
  lastName?: string | null;
  name?: string | null;
  emailAddress?: string | null;
  phoneNumber?: string | null;
} | null;
export type Course = Metadata & {
  primaryCustomerId: string;
  courseTypeId: string;
  instructorUserId: string;
  name: string;
  startDateTime?: string | null;
  endDateTime?: string | null;
  defaultCertificateSelection?: CertificateType;
  address?: AddressProperties;
  notes?: string | null;
  courseDescription?: string | null;
  contactPerson?: ContactPersonsProperties;
  pinCode: string;
  status: "pending" | "started" | "closed" | "finalized";
  statusTransitions: {
    pending?: number | null;
    started?: number | null;
    closed?: number | null;
    finalized?: number | null;
  };
  invoicingStatus: "pending" | "incomplete" | "finalized" | "notInvoiceable";
  invoicingStatusTransitions: {
    pending?: number | null;
    incomplete?: number | null;
    finalized?: number | null;
    notInvoiceable?: number | null;
  };
  handledByAutomationIds?: ("customer" | "participant")[] | null;
};
export type ResourceLink = {
  id?: string;
  metadata?: object;
}[];
export type PinCodeVerificationResponse = {
  primaryCustomerId: string;
  name: string;
};
export type Customer = Metadata & {
  name: string;
  address?: AddressProperties;
  contactPerson?: ContactPersonsProperties;
  comment?: string | null;
};
export type Participant = Metadata & {
  courseId: string;
  customerId: string;
  firstName: string;
  lastName: string;
  name: string;
  emailAddress: string;
  phoneNumber: string;
  certificateSelection: CertificateType;
  courseStatus: "pending" | "accepted" | "rejected";
  courseStatusTransitions: {
    pending?: number | null;
    accepted?: number | null;
    rejected?: number | null;
  };
  customerComment?: string | null;
  privateComment?: string | null;
};
export type User = Metadata & {
  name: string;
  emailAddress: string;
  number: string;
  role: "admin" | "instructor";
};
export const {
  useGetV1CourseCertificatesQuery,
  useGetV1CourseCertificatesByIdQuery,
  useGetV1CourseCertificatesByIdDownloadQuery,
  useGetV1CourseTypesQuery,
  usePostV1CourseTypesMutation,
  useGetV1CourseTypesByIdQuery,
  usePutV1CourseTypesByIdMutation,
  useDeleteV1CourseTypesByIdMutation,
  useGetV1CoursesQuery,
  usePostV1CoursesMutation,
  useGetV1CoursesByIdQuery,
  usePutV1CoursesByIdMutation,
  useDeleteV1CoursesByIdMutation,
  usePostV1CoursesByIdCloseMutation,
  usePostV1CoursesVerifyPinCodeMutation,
  useGetV1CustomersQuery,
  usePostV1CustomersMutation,
  useGetV1CustomersByIdQuery,
  usePutV1CustomersByIdMutation,
  useDeleteV1CustomersByIdMutation,
  usePostV1RelationsMutation,
  usePutV1RelationsByIdMutation,
  useDeleteV1RelationsByIdMutation,
  useGetV1ParticipantsQuery,
  usePostV1ParticipantsMutation,
  useGetV1ParticipantsByIdQuery,
  usePutV1ParticipantsByIdMutation,
  useDeleteV1ParticipantsByIdMutation,
  usePostV1ParticipantsByIdTransferMutation,
  useGetV1UsersQuery,
  useGetV1UsersByIdQuery,
} = injectedRtkApi;
