import CancelIcon from '@mui/icons-material/Cancel';
import {
  Box,
  Chip,
  CircularProgress,
  LinearProgress,
  SxProps,
  Theme,
  Typography,
} from '@mui/material/';
import { useEffect, useState } from 'react';

import { useGetV1CustomersByIdQuery } from '../../store/api/backendApi';

interface CustomerChipProps {
  id: string;
  active?: boolean;
  disabled?: boolean;
  onClick?: (id: string) => void;
  onDelete?: (id: string) => void;
  sx?: SxProps<Theme>;
}

export default function CustomerChip(
  {
    id,
    active = false,
    disabled = false,
    onClick = undefined,
    onDelete = undefined,
    sx = {},
  } : CustomerChipProps,
): JSX.Element {
  const confirmationTime = 3; // seconds
  const [confirming, setConfirming] = useState<boolean>(false);
  const [countdown, setCountdown] = useState<number>(100);
  const {
    data: customerData,
    isLoading,
    isError,
  } = useGetV1CustomersByIdQuery({ id });

  const handleChipClick = () => {
    if (onClick) {
      onClick(id);
    }
  };

  const handleDelete = async () => {
    if (confirming) {
      setConfirming(false);
      setCountdown(100);
      if (onDelete) {
        onDelete(id);
      }
    } else {
      setConfirming(true);
    }
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (confirming) {
      timer = setInterval(() => {
        setCountdown((oldProgress) => {
          if (oldProgress === 0) {
            setConfirming(false);
            return 100;
          }
          return Math.max(oldProgress - (100 / confirmationTime), 0);
        });
      }, 1000);
    }
    return () => {
      clearInterval(timer);
    };
  }, [confirming]);

  if (isError) {
    return (
      <Typography variant="body2" color="error">Asiakastietojen lataaminen epäonnistui.</Typography>
    );
  }

  return (
    <Box sx={sx}>
      {isLoading ? (
        <CircularProgress size={20} />
      ) : (
        <Chip
          label={confirming
            ? (
              <>
                <span className="MuiChip-labelSmall">
                  Oletko varma?
                </span>
                <Box width="100%" position="relative" bottom={0}>
                  <LinearProgress
                    variant="determinate"
                    color="warning"
                    value={countdown}
                    sx={{
                      height: '2px',
                    }}
                  />
                </Box>
              </>
            )
            : customerData?.data.name}
          variant={active ? 'filled' : 'outlined'}
          color={confirming ? 'warning' : 'default'}
          disabled={disabled}
          size="small"
          onClick={onClick ? handleChipClick : undefined}
          onDelete={onDelete ? handleDelete : undefined}
          deleteIcon={onDelete && (
            <CancelIcon
              aria-label={confirming ? 'Vahvista' : 'Poista'}
            />
          )}
        />
      )}
    </Box>
  );
}
