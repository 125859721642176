import {
  SxProps,
  Theme,
  Typography,
} from '@mui/material/';

import { useGetV1CustomersByIdQuery } from '../../store/api/backendApi';
import AddressBlock from '../misc/AddressBlock';
import LoadingIndicator from '../misc/LoadingIndicator';

interface CustomerAddressProps {
  id: string;
  sx?: SxProps<Theme> | undefined
}

function CustomerAddressBlock({
  id,
  sx = {},
}:CustomerAddressProps): JSX.Element {
  const {
    data: customerData,
    isLoading,
    isError,
  } = useGetV1CustomersByIdQuery({ id });

  if (isLoading) {
    return <LoadingIndicator size="small" />;
  }

  if (isError) {
    return (
      <Typography variant="body1" color="error">
        Asiakastietojen lataaminen epäonnistui.
      </Typography>
    );
  }

  return (
    <AddressBlock address={customerData?.data.address || {}} sx={sx} />
  );
}

export default CustomerAddressBlock;
