import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getAuth, User as FirebaseUser } from 'firebase/auth';

import { RootState } from '..';
import {
  incJwtRefreshCount,
  reset as resetUserState,
  resetJwtRefreshCount,
} from '../userSlice';

interface FirebaseOAuthUser extends FirebaseUser {
  accessToken: string;
}

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  prepareHeaders: (headers) => {
    const firebaseAuth = getAuth();
    const currentUser = firebaseAuth.currentUser as FirebaseOAuthUser;
    if (currentUser?.accessToken) {
      headers.set('Authorization', `Bearer ${currentUser.accessToken}`);
    }

    return headers;
  },
});

/**
 * wrapper for baseQuery to handle auth errors
 */
const apiBaseQuery: BaseQueryFn< // https://redux-toolkit.js.org/rtk-query/usage-with-typescript#typing-a-basequery
string | FetchArgs, // Args
unknown, // Result
FetchBaseQueryError // Error
// object, // DefinitionExtraOptions
// object // Meta
> = async (args, api, extraOptions) => {
  // debug('apiBaseQuery', args);
  const firebaseAuth = getAuth();
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    // try to get a new token
    const state = api.getState() as RootState;
    if (state.userState.jwtRefreshCount < 10) { // prevent infinite refresh just in case
      api.dispatch(incJwtRefreshCount());
      const token = await firebaseAuth?.currentUser?.getIdToken(true) || false;
      if (token) {
        // retry the initial query
        result = await baseQuery(args, api, extraOptions);
        api.dispatch(resetJwtRefreshCount());
      } else {
        // api.dispatch(jobsApi.util.resetApiState());
        api.dispatch(resetUserState());
      }
    }
  }

  return result;
};

export default apiBaseQuery;
