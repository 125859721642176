import {
  Box,
  Stack,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { Participant } from '../../store/api/backendApi';
import CustomerInfo from '../customers/CustomerInfo';
import {
  SelectedCustomerIcon,
  sxSubtleIcon,
} from '../layout/icons';
import InfoMessage from '../misc/InfoMessage';
import LoadingIndicator from '../misc/LoadingIndicator';
import ParticipantSearchResultCourseDetails from './ParticipantSearchResultCourseDetails';

interface ParticipantSearchResultsProps {
  isLoading: boolean;
  participants: Participant[];
}

export default function ParticipantSearchResults({
  isLoading,
  participants,
} : ParticipantSearchResultsProps): JSX.Element {
  const navigate = useNavigate();

  if (isLoading) {
    return (
      <LoadingIndicator />
    );
  }

  if (!participants || participants.length === 0) {
    return (
      <Box>
        <InfoMessage
          title="Ei osallistujia"
          message="Hakuehdoilla ei löytynyt osallistujia."
          severity="warning"
        />
      </Box>
    );
  }

  return (
    <Box>
      {participants?.map((participant: Participant) => (
        <Box
          key={participant.id}
          sx={{
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: '#fff9f9',
            },
            borderBottom: '1px solid #f0f0f0',
            overflow: 'hidden',
          }}
          onClick={() => navigate(`/courses/${participant.courseId}`)}
        >
          <Stack direction="row" spacing={1} alignItems="baseline" sx={{ mb: 1 }}>
            <Typography variant="h4" gutterBottom>
              {participant.name}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {participant.emailAddress}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={1} alignItems="center" sx={{ mb: 1 }}>
            <SelectedCustomerIcon fontSize="small" sx={sxSubtleIcon} />
            <CustomerInfo id={participant.customerId} />
          </Stack>
          <ParticipantSearchResultCourseDetails courseId={participant.courseId} />
        </Box>
      ))}
    </Box>
  );
}
