import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const stage = process.env.REACT_APP_STAGE || 'dev';

const firebaseConfig = {
  dev: {
    projectId: 'camilla-safety-kurre',
    apiKey: 'AIzaSyD4oiiaLC-k7KNkv5nqt1AsgYFY7dYjh8s',
    authDomain: 'camilla-safety-kurre.firebaseapp.com',
  },
  staging: {
    projectId: 'camilla-safety-kurre',
    apiKey: 'AIzaSyD4oiiaLC-k7KNkv5nqt1AsgYFY7dYjh8s',
    authDomain: 'camilla-safety-kurre.firebaseapp.com',
  },
  prod: {
    projectId: 'camilla-safety-prod',
    apiKey: 'AIzaSyB_mHDueWP7qDm1mBYb3vfp5dml4ekSFVo',
    authDomain: 'camilla-safety-prod.firebaseapp.com',
  },
};

const app = initializeApp(firebaseConfig[stage as keyof typeof firebaseConfig]);
const auth = getAuth(app);

export { auth };
