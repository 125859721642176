// import PropTypes from 'prop-types';
import {
  Paper,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const ViewPaper = styled(Paper)(({ theme }) => ({
  padding: '25px',
  paddingTop: '35px',
  marginBottom: '25px',
  [theme.breakpoints.down('sm')]: {
    padding: '15px',
    marginLeft: '-5px',
    marginRight: '-5px',
    boxShadow: 'none',
    borderRadius: 0,
  },
}));

export default ViewPaper;
