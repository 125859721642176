import {
  Chip,
} from '@mui/material';

import { CheckIcon } from '../../layout/icons';

interface ToggleChipProps {
  size: 'small' | 'medium';
  label: string;
  selected: boolean;
  onChange: (selected: boolean) => void;
}

export default function ToggleChip(
  {
    size, label, selected, onChange,
  } : ToggleChipProps,
): JSX.Element {
  return (
    <Chip
      icon={selected ? <CheckIcon /> : undefined}
      variant={selected ? 'filled' : 'outlined'}
      size={size}
      color="secondary"
      label={label}
      onClick={() => {
        onChange(!selected);
      }}
      sx={{ mr: 1 }}
    />
  );
}
