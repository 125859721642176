import {
  Box,
  IconButton,
  LinearProgress,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import CourseCertificatesList from '../../components/courseCertificates/CourseCertificateList';
import { CourseStatusChip, InvoicingStatusChip } from '../../components/courses/chips';
import CourseClosing from '../../components/courses/CourseClosing';
import CourseDetailsForm from '../../components/courses/CourseDetailsForm';
import CourseParticipantList from '../../components/courses/CourseParticipantList';
import CourseTypeInfo from '../../components/courseTypes/CourseTypeInfo';
import CustomerAddressBlock from '../../components/customers/CustomerAddressBlock';
import CustomerContactPersonBlock from '../../components/customers/CustomerContactPersonBlock';
import CustomerInfo from '../../components/customers/CustomerInfo';
import SecondaryCustomerColletion from '../../components/customers/SecondaryCustomerCollection';
import Breadcrumbs from '../../components/layout/Breadcrumbs';
import ErrorBoundary from '../../components/layout/ErrorBoundary';
import {
  AlertIcon,
  CalendarIcon,
  CertificateTypeIcon,
  CheckIcon,
  CopyIcon,
  DeleteIcon,
  EditIcon,
  ExternalLinkIcon,
  InfoIcon,
  SelectedCustomerIcon,
  sxSubtleIcon,
} from '../../components/layout/icons';
import Restricted from '../../components/layout/Restricted';
import ViewContainer from '../../components/layout/ViewContainer';
import ViewPaper from '../../components/layout/ViewPaper';
import AddressBlock from '../../components/misc/AddressBlock';
import ContactPersonBlock from '../../components/misc/ContactPersonBlock';
import InfoMessage from '../../components/misc/InfoMessage';
import LoadingIndicator from '../../components/misc/LoadingIndicator';
import MultilineComment from '../../components/misc/MultilineComment';
import UserInfo from '../../components/users/UserInfo';
import {
  Course,
  GetV1CoursesByIdApiResponse,
  PutV1CoursesByIdApiArg,
  ResourceLink,
  useGetV1CoursesByIdQuery,
  usePutV1CoursesByIdMutation,
} from '../../store/api/backendApi';
import { certificateSelections } from '../../utils/const';
import { serializeRTKFetchError } from '../../utils/utils';

function formatPinCode(pinCode: string): string {
  return `${pinCode.slice(0, 3)} ${pinCode.slice(3)}`;
}

function CourseView(): JSX.Element {
  const [showForm, setShowForm] = useState<boolean>(false);
  const [pinCopied, setPinCopied] = useState(false);

  const { id } = useParams<{ id: string }>() as { id: string };
  const {
    data: courseData, isLoading, isFetching, isError, error: fetchError, refetch,
  } = useGetV1CoursesByIdQuery({ id });
  const [putCourse] = usePutV1CoursesByIdMutation();

  const courseDisabled = ['closed', 'finalized'].includes(courseData?.data.status as string);
  const courseFinalized = ['finalized'].includes(courseData?.data.status as string);

  const handleStatusChange = async (status: Course['status']) => {
    const payload = {
      id,
      body: {
        ...courseData?.data,
        status,
      },
    } as PutV1CoursesByIdApiArg;
    await putCourse(payload);
    refetch();
  };

  const handleInvoicingStatusChange = async (invoicingStatus: Course['invoicingStatus']) => {
    const payload = {
      id,
      body: {
        ...courseData?.data,
        invoicingStatus,
      },
    } as PutV1CoursesByIdApiArg;
    await putCourse(payload);
    refetch();
  };

  const handleCourseUpdate = (needsRefresh = false) => {
    if (needsRefresh) {
      refetch();
    }
    setShowForm(false);
  };

  const handleClipboardCopy = () => {
    navigator.clipboard.writeText(
      formatPinCode(courseData?.data.pinCode as string),
    );
    setPinCopied(true);
    setTimeout(() => setPinCopied(false), 3000);
  };

  if (isLoading) {
    return (
      <LoadingIndicator caption="Ladataan tapahtumaa..." />
    );
  }

  if (isError) {
    return (
      <ViewContainer>

        <Breadcrumbs items={[{ label: 'Kurssit', to: '/courses' }]} />
        <ViewPaper>
          <InfoMessage
            sx={{ m: 2, p: 1 }}
            title="Virhe"
            message="Kurssin lataaminen epäonnistui."
            severity="error"
            details={serializeRTKFetchError(fetchError)}
          />
        </ViewPaper>
      </ViewContainer>
    );
  }

  return (
    <ErrorBoundary>
      <ViewContainer>

        <Breadcrumbs items={[{ label: 'Kurssit', to: '/courses' }, { label: courseData?.data.name || 'Ladataan... ' }]} />

        <ViewPaper>
          {(showForm && !courseDisabled) ? (
            <CourseDetailsForm
              course={courseData?.data as Course}
              onClose={handleCourseUpdate}
            />
          ) : (
            <>
              <Box sx={{ mb: 3 }}>
                <Box sx={{
                  display: 'flex', flexWrap: 'wrap-reverse', justifyContent: 'space-between', alignItems: 'center',
                }}
                >
                  <Typography variant="h3" gutterBottom sx={{ mb: 1 }}>
                    {courseData?.data.name}
                  </Typography>

                  <Box sx={{
                    display: 'flex',
                    width: { xs: '100%', sm: 'auto' },
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 2,
                  }}
                  >
                    <Restricted requires={['admin']} quiet>
                      <InvoicingStatusChip
                        status={courseData?.data.invoicingStatus as Course['invoicingStatus']}
                        showMenu
                        onChange={handleInvoicingStatusChange}
                        sx={{ mr: 1 }}
                      />
                    </Restricted>
                    <CourseStatusChip
                      status={courseData?.data.status as Course['status']}
                      showMenu={!courseFinalized}
                      onChange={handleStatusChange}
                    />
                    <IconButton size="small" onClick={() => setShowForm(true)} aria-label="Muokkaa" disabled={courseDisabled}>
                      <EditIcon />
                    </IconButton>
                    <Restricted requires={['admin']} quiet>
                      <IconButton size="small" aria-label="Poista" disabled={courseDisabled}>
                        <DeleteIcon />
                      </IconButton>
                    </Restricted>
                  </Box>
                </Box>

                <LinearProgress sx={{ mb: 1, opacity: isFetching ? '1' : '0' }} />

                <CourseTypeInfo id={courseData?.data.courseTypeId as string} sx={{ mb: 1 }} />

                <UserInfo id={courseData?.data.instructorUserId as string} sx={{ mb: 1 }} />

                <Stack direction="row" alignItems="center" spacing={1} sx={{ mb: 1 }}>
                  <SelectedCustomerIcon fontSize="small" sx={sxSubtleIcon} />
                  <CustomerInfo
                    id={courseData?.data.primaryCustomerId as string}
                    showLink
                    sx={{ mb: 1 }}
                  />
                </Stack>

                <Stack direction="row" alignItems="center" spacing={1} sx={{ mb: 1 }}>
                  <CertificateTypeIcon fontSize="small" sx={sxSubtleIcon} />
                  <Typography variant="body1">
                    {certificateSelections[courseData?.data.defaultCertificateSelection || 'pdf'].label}
                  </Typography>

                  <Tooltip title="Todistustyyppi lisätään osallistujalle, kun hän rekisteröityy kurssille. Oletuksen vaihtaminen ei vaikuta jo rekisteröityneisiin osallistujiin.">
                    <InfoIcon fontSize="small" color="info" />
                  </Tooltip>
                </Stack>

                <Stack direction="row" alignItems="center" spacing={1} sx={{ mb: 1 }}>
                  <CalendarIcon fontSize="small" />
                  <Typography variant="body1" sx={{ mb: 1 }}>
                    {courseData?.data.startDateTime ? DateTime.fromISO(courseData?.data.startDateTime).toFormat('dd.MM.yyyy') : '_'}
                    {' -> '}
                    {courseData?.data.endDateTime ? DateTime.fromISO(courseData?.data.endDateTime).toFormat('dd.MM.yyyy') : '_'}
                  </Typography>
                </Stack>

                <Stack direction={{ xs: 'column', sm: 'row' }} alignItems={{ sm: 'flex-start', md: 'center' }} spacing={1} sx={{ mb: 1 }}>
                  <Stack direction="row" alignItems="center" spacing={1} sx={{ mb: 1 }}>
                    <Typography variant="body1">
                      {formatPinCode(courseData?.data.pinCode as string)}
                    </Typography>
                    <Tooltip title="Kopioi koodi leikepöydälle">
                      <IconButton size="small" onClick={handleClipboardCopy}>
                        {pinCopied ? <CheckIcon fontSize="inherit" color="success" /> : <CopyIcon fontSize="inherit" />}
                      </IconButton>
                    </Tooltip>
                    {courseData?.data.status !== 'started' ? (
                      <>
                        <AlertIcon color="warning" fontSize="small" />
                        <Typography variant="body2" color="text.secondary">
                          Kurssi ei ole käynnissä
                        </Typography>
                      </>
                    ) : (
                      <Tooltip title="Avaa ilmoittautumislomake">
                        <IconButton size="small" href={`/?pin=${courseData?.data.pinCode}`} target="_blank">
                          <ExternalLinkIcon fontSize="inherit" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Stack>
                </Stack>

                <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="baseline" spacing={1} sx={{ mb: 1 }}>
                  <Typography variant="body1" sx={{ minWidth: 170 }}>
                    <strong>
                      Osoite:
                    </strong>
                    <Typography variant="body2" color="text.secondary" component="span">
                      {courseData?.data.address?.street ? '' : ' (tilaaja)'}
                    </Typography>
                  </Typography>
                  {courseData?.data.address?.street ? (
                    <AddressBlock address={courseData?.data.address || {}} />
                  ) : (
                    <CustomerAddressBlock id={courseData?.data.primaryCustomerId as string} />
                  )}
                </Stack>

                <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="baseline" spacing={1} sx={{ mb: 1 }}>
                  <Typography variant="body1" sx={{ minWidth: 170 }}>
                    <strong>
                      Yhteyshenkilö:
                    </strong>
                    <Typography variant="body2" color="text.secondary" component="span">
                      {courseData?.data.contactPerson?.name ? '' : ' (tilaaja)'}
                    </Typography>
                  </Typography>
                  {courseData?.data.contactPerson?.name ? (
                    <ContactPersonBlock contactPerson={courseData?.data.contactPerson} />
                  ) : (
                    <CustomerContactPersonBlock id={courseData?.data.primaryCustomerId as string} />
                  )}
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} alignItems={{ sm: 'flex-start', md: 'center' }} spacing={1} sx={{ mb: 1 }}>
                  <Box sx={{ pb: 1 }}>
                    <Typography variant="body1">
                      <strong>
                        Muut asiakkaat: &nbsp;
                      </strong>
                    </Typography>
                  </Box>
                  <SecondaryCustomerColletion
                    courseId={id}
                    disabled={courseDisabled}
                    links={courseData?.data.links?.customers?.filter(
                      (link) => link.metadata
                        && 'relation' in link.metadata
                        && 'type' in (link.metadata.relation as Record<string, unknown>)
                        && (link.metadata.relation as Record<string, unknown>).type === 'customer-participates-in-course',
                    ) as ResourceLink}
                    onChange={refetch}
                  />
                </Stack>
              </Box>

              <Typography variant="h4" gutterBottom>Kurssin sisältö</Typography>
              <MultilineComment content={courseData?.data.courseDescription as string} />

              <Typography variant="h4" gutterBottom>Muistiinpanot</Typography>
              <MultilineComment content={courseData?.data.notes as string} />

              <CourseParticipantList
                course={courseData?.data as GetV1CoursesByIdApiResponse['data']}
                disabled={courseDisabled}
              />

              {courseData?.data.status === 'started' && (
                <Box sx={{ my: 3 }}>
                  <Typography variant="h4" gutterBottom>Kurssin viimeistely</Typography>
                  <CourseClosing course={courseData?.data as Course} onClose={handleCourseUpdate} />
                </Box>
              )}

              {courseData?.data.status === 'finalized' && (
                <Restricted requires={['admin']} quiet>
                  <Box sx={{ my: 3 }}>
                    <CourseCertificatesList
                      course={courseData?.data as GetV1CoursesByIdApiResponse['data']}
                    />
                  </Box>
                </Restricted>
              )}
            </>
          )}
        </ViewPaper>
      </ViewContainer>
    </ErrorBoundary>

  );
}

export default CourseView;
