/* eslint-disable no-nested-ternary */
import { Add as AddIcon } from '@mui/icons-material';
import {
  Box,
  IconButton,
  SxProps,
  Theme,
} from '@mui/material/';
import { useState } from 'react';

import {
  Customer,
  PostV1RelationsApiArg,
  ResourceLink,
  useDeleteV1RelationsByIdMutation,
  usePostV1RelationsMutation,
} from '../../store/api/backendApi';
import LoadingIndicator from '../misc/LoadingIndicator';
import CustomerChip from './CustomerChip';
import CustomerSearch from './CustomerSearch';

interface SecondaryCustomerColletionProps {
  courseId: string;
  links: ResourceLink;
  onChange: () => void;
  disabled: boolean;
  sx?: SxProps<Theme>;
}

const findRelationIdOrThrow = (links: ResourceLink, customerId: string) => {
  const link = links?.find((l) => l.id === customerId);

  if (!link) {
    throw new Error(`No relation found for customer ${customerId}`);
  }

  const relation = (link.metadata as { relation?: { id: string } })?.relation;

  if (!relation || typeof relation !== 'object') {
    throw new Error(`Invalid relation found for customer ${customerId}`);
  }

  return relation.id;
};

export default function SecondaryCustomerColletion({
  courseId,
  links,
  onChange,
  disabled,
  sx = {},
} : SecondaryCustomerColletionProps): JSX.Element {
  const [showCustomerSearch, setShowCustomerSearch] = useState(false);
  const [postRelation, { isLoading: isPosting }] = usePostV1RelationsMutation();
  const [deleteRelation] = useDeleteV1RelationsByIdMutation();

  const handleAdd = async (customer: Customer) => {
    const payload: PostV1RelationsApiArg = {
      body: {
        type: 'customer-participates-in-course',
        secondaryCustomerId: customer.id as string,
        courseId,
      },
    };
    await postRelation(payload);
    onChange();
    setShowCustomerSearch(false);
  };

  const handleDelete = async (customerId: string) => {
    const relationId = findRelationIdOrThrow(links, customerId);
    await deleteRelation({ id: relationId });
    onChange();
  };

  return (
    <Box display="flex" alignItems="center" flexWrap="wrap" sx={sx}>
      {links.map((link) => (
        <CustomerChip
          disabled={disabled}
          key={link.id}
          id={link.id as string}
          onDelete={handleDelete}
          sx={{ mr: 1, mb: 1 }}
        />
      ))}

      {showCustomerSearch ? (
        isPosting ? (
          <LoadingIndicator />
        ) : (
          <CustomerSearch
            onSelect={handleAdd}
            onCancel={() => setShowCustomerSearch(false)}
            sx={{ minWidth: 300 }}
          />
        )
      ) : (
        <IconButton
          size="small"
          onClick={() => setShowCustomerSearch(true)}
          sx={{ mb: 1 }}
          aria-label="Lisää"
          disabled={disabled}
        >
          <AddIcon fontSize="small" />
        </IconButton>
      )}
    </Box>
  );
}
