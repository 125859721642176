import { BaseTextFieldProps, TextField } from '@mui/material';

import type { UseFormValidatorReturn } from '../../hooks/useFormValidator';

interface TextInputProps extends BaseTextFieldProps {
  label: string;
  name: string;
  value: string | undefined | null;
  formValidator: UseFormValidatorReturn;
  helperText?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
}

function TextInput({
  label, name, value, margin = 'normal', formValidator, helperText = '', onChange, onBlur, ...other
}: TextInputProps): JSX.Element {
  return (
    <TextField
      variant="outlined"
      margin={margin}
      label={label}
      name={name}
      value={value}
      error={formValidator.hasError(name)}
      helperText={formValidator.getError(name) || helperText}
      onChange={onChange}
      onBlur={onBlur}
      {...other}
    />
  );
}

export default TextInput;
