import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import { IconButton, Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';

import { Course } from '../../store/api/backendApi';
import { invoicingStatuses } from '../../utils/const';
// import { EditIcon } from '../layout/icons';

interface InvoicingStatusMenuProps {
  invoicingStatus: Course['invoicingStatus'];
  onChange: (status: Course['invoicingStatus']) => void;
}

export default function InvoicingStatusMenu(
  { invoicingStatus, onChange } : InvoicingStatusMenuProps,
): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleStatusChange = (newStatus: Course['invoicingStatus']) => {
    if (newStatus !== invoicingStatus) {
      onChange(newStatus as Course['invoicingStatus']);
    }
    handleClose();
  };

  return (
    <>
      <IconButton size="small" color="inherit" aria-label="Vaihda laskutustilaa" onClick={handleClick}>
        <PendingOutlinedIcon fontSize="inherit" />
      </IconButton>
      <Menu
        id="course-status-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {Object.entries(invoicingStatuses).map(([key, value]) => (
          <MenuItem
            key={key}
            value={key}
            selected={invoicingStatus === key}
            onClick={() => handleStatusChange(key as Course['invoicingStatus'])}
          >
            {value}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
