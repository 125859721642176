import ClearIcon from '@mui/icons-material/Clear';
import {
  Badge,
  Box,
  Button,
  Chip,
  Collapse,
  IconButton,
  InputAdornment,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useMemo, useState } from 'react';

import CourseList from '../../components/courses/CourseList';
import CourseStatusSelector from '../../components/courses/filter/CourseStatusSelector';
import NewCourseDialog from '../../components/courses/NewCourseDialog';
import Breadcrumbs from '../../components/layout/Breadcrumbs';
import { CheckIcon, FilterIcon } from '../../components/layout/icons';
import Restricted from '../../components/layout/Restricted';
import ViewContainer from '../../components/layout/ViewContainer';
import ViewPaper from '../../components/layout/ViewPaper';
import LoadingIndicator from '../../components/misc/LoadingIndicator';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  Course,
  useGetV1CoursesQuery,
} from '../../store/api/backendApi';
import { setCourseFilterOptions } from '../../store/userSlice';
import { courseFilter, courseSort } from '../../utils/utils';

function CoursesView(): JSX.Element {
  const [newCourseDialogOpen, setNewCourseDialogOpenOpen] = useState(false);
  const userRoles = useAppSelector((state) => state.userState.roles);
  const isUserAdmin = userRoles.includes('admin');
  const userId = useAppSelector((state) => state.userState.data?.id) as string;
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const [showFilters, setShowFilters] = useState(!isMobile);
  const [filtersActive, setFiltersActive] = useState(false);

  const {
    data: courseData, isFetching, isError, refetch,
  } = useGetV1CoursesQuery();

  const dispatch = useAppDispatch();
  const courseFilterOptions = useAppSelector((state) => state.userState.courseFilterOptions);

  const setFilterOption = (option: string, value: boolean | string | string[]) => {
    dispatch(setCourseFilterOptions({ ...courseFilterOptions, [option]: value }));
  };

  const courses = useMemo(() => {
    if (!courseData?.data) {
      return [];
    }

    setFiltersActive(
      courseFilterOptions.search !== ''
      || courseFilterOptions.ownOnly
      || courseFilterOptions.lastFourWeeks
      || courseFilterOptions.nextFourWeeks
      || courseFilterOptions.unInvoiced
      || courseFilterOptions.courseStatuses.length > 0,
    );

    // for perfomance testing
    // const filteredDataTemp = courseData.data.flatMap((course: Course) => {
    //   const multiplier = 50;
    //   const array = new Array(multiplier);
    //   for (let i = 0; i < multiplier; i += 1) {
    //     array[i] = {
    //       ...course,
    //       id: `${course.id}-${Math.random().toString(36).substring(7)}`, // Generate a unique ID
    //     };
    //   }
    //   return array;
    // });

    const filtered = courseData.data.filter(
      (course: Course) => courseFilter(course, courseFilterOptions, isUserAdmin, userId),
    ) as Course[];

    filtered.sort((a: Course, b: Course) => courseSort(a, b, 'startDateTime', 'asc'));

    return filtered;
  }, [
    courseData,
    courseFilterOptions.search,
    courseFilterOptions.ownOnly,
    courseFilterOptions.lastFourWeeks,
    courseFilterOptions.nextFourWeeks,
    courseFilterOptions.unInvoiced,
    courseFilterOptions.courseStatuses,
  ]);

  if (isError) {
    return (
      <ViewContainer>
        <Typography variant="h2" gutterBottom>
          Virhe
        </Typography>
        <Typography variant="body1" gutterBottom>
          Tapahtumien lataaminen epäonnistui.
        </Typography>
      </ViewContainer>
    );
  }

  return (
    <ViewContainer sx={{
      minHeight: 'calc(max(100vh - 50px, 600px))',
      display: 'flex',
      flexDirection: 'column',
    }}
    >

      <Breadcrumbs items={[{ label: 'Kurssit' }]} />

      <ViewPaper sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <Box>
          <Box
            display={{ xs: 'flex', md: 'block' }}
            flexDirection={{ xs: 'row', md: 'column' }}
            alignItems={{ xs: 'center', md: 'flex-start' }}
            sx={{ mb: 1 }}
          >
            <Typography variant="h2" gutterBottom={!isMobile} sx={{ mr: 2 }}>
              Kurssit
            </Typography>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Restricted requires={['admin']} quiet>
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  onClick={() => setNewCourseDialogOpenOpen(true)}
                  sx={{ mr: 2 }}
                >
                  Lisää uusi
                </Button>
              </Restricted>

              <Button
                variant="outlined"
                size="small"
                color="inherit"
                onClick={() => {
                  refetch();
                }}
                sx={{ mr: 2 }}
              >
                Päivitä lista
              </Button>
              <Box flexGrow={1} />
              <IconButton
                size="small"
                color="inherit"
                onClick={() => {
                  setShowFilters(!showFilters);
                }}
              >
                <Badge variant="dot" showZero={false} badgeContent={filtersActive ? 1 : 0} color="primary">
                  <FilterIcon />
                </Badge>
              </IconButton>
            </Box>
          </Box>

          <Collapse in={showFilters}>
            <Box sx={{ mt: 0, mb: 1 }} textAlign="right">
              <TextField
                label="Suodata kurssin tunnisteella"
                variant="outlined"
                fullWidth
                size={isMobile ? 'small' : 'medium'}
                value={courseFilterOptions.search}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setFilterOption('search', event.target.value);
                }}
                margin="normal"
                disabled={isFetching}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {courseFilterOptions.search && (
                      <IconButton
                        aria-label="Clear"
                        onClick={() => {
                          setFilterOption('search', '');
                        }}
                        edge="end"
                      >
                        <ClearIcon />
                      </IconButton>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              <Chip // TODO use ToggleChip
                icon={courseFilterOptions.lastFourWeeks ? <CheckIcon /> : undefined}
                variant={courseFilterOptions.lastFourWeeks ? 'filled' : 'outlined'}
                size={isMobile ? 'small' : 'medium'}
                color="secondary"
                label="-4 viikkoa "
                onClick={() => {
                  setFilterOption('lastFourWeeks', !courseFilterOptions.lastFourWeeks);
                }}
                sx={{ mr: 1 }}
              />
              <Chip // TODO use ToggleChip
                icon={courseFilterOptions.nextFourWeeks ? <CheckIcon /> : undefined}
                variant={courseFilterOptions.nextFourWeeks ? 'filled' : 'outlined'}
                size={isMobile ? 'small' : 'medium'}
                color="secondary"
                label="+4 viikkoa"
                onClick={() => {
                  setFilterOption('nextFourWeeks', !courseFilterOptions.nextFourWeeks);
                }}
                sx={{ mr: 1 }}
              />
              <Restricted requires={['admin']} quiet>
                <>
                  <Chip // TODO use ToggleChip
                    icon={courseFilterOptions.ownOnly ? <CheckIcon /> : undefined}
                    variant={courseFilterOptions.ownOnly ? 'filled' : 'outlined'}
                    size={isMobile ? 'small' : 'medium'}
                    color="secondary"
                    label="Vain omat"
                    onClick={() => {
                      setFilterOption('ownOnly', !courseFilterOptions.ownOnly);
                    }}
                    sx={{ mr: 1 }}
                  />
                  <Chip // TODO use ToggleChip
                    icon={courseFilterOptions.unInvoiced ? <CheckIcon /> : undefined}
                    variant={courseFilterOptions.unInvoiced ? 'filled' : 'outlined'}
                    size={isMobile ? 'small' : 'medium'}
                    color="secondary"
                    label="Laskuttamatta"
                    onClick={() => {
                      setFilterOption('unInvoiced', !courseFilterOptions.unInvoiced);
                    }}
                    sx={{ mr: 1 }}
                  />
                </>
              </Restricted>
              <CourseStatusSelector
                variant={isMobile ? 'mobile' : 'desktop'}
                selectedStatuses={courseFilterOptions.courseStatuses}
                onChange={(selected) => {
                  setFilterOption('courseStatuses', selected);
                }}
              />
            </Box>
          </Collapse>
        </Box>

        {isFetching ? (
          <LoadingIndicator caption="Ladataan tapahtumia..." />
        ) : (
          <CourseList courses={courses} />
        )}
      </ViewPaper>
      <NewCourseDialog
        open={newCourseDialogOpen}
        onClose={() => setNewCourseDialogOpenOpen(false)}
      />
    </ViewContainer>
  );
}

export default CoursesView;
