import {
  Box,
  Button,
  CircularProgress,
  LinearProgress,
} from '@mui/material';
import { ButtonProps } from '@mui/material/Button';
import { useEffect, useState } from 'react';

interface SubmitButtonProps extends ButtonProps {
  loading: boolean;
  requireConfirmation?: boolean,
}

function SubmitButton({
  children,
  loading,
  onClick,
  requireConfirmation = false,
  ...rest
} : SubmitButtonProps): JSX.Element {
  const [confirming, setConfirming] = useState<boolean>(false);
  const [countdown, setCountdown] = useState<number>(100);

  const confirmationTime = 3; // seconds

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (confirming) {
      timer = setInterval(() => {
        setCountdown((oldProgress) => {
          if (oldProgress === 0) {
            setConfirming(false);
            return 100;
          }
          return Math.max(oldProgress - (100 / confirmationTime), 0);
        });
      }, 1000);
    }
    return () => {
      clearInterval(timer);
    };
  }, [confirming]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (requireConfirmation) {
      if (confirming) {
        setConfirming(false);
        setCountdown(100);
        if (onClick) {
          onClick(event);
        }
      } else {
        setConfirming(true);
      }
    } else if (onClick) {
      onClick(event);
    }
  };
  return (
    <Button onClick={handleClick} {...rest}>
      {confirming ? 'Vahvista klikkaamalla uudestaan!' : children}
      {loading && (
        <CircularProgress
          size={25}
          color="secondary"
          style={{ position: 'absolute' }}
        />
      )}
      {confirming && (
        <Box width="100%" position="absolute" bottom={0}>
          <LinearProgress variant="determinate" color="error" value={countdown} sx={{ height: '2px' }} />
        </Box>
      )}
    </Button>
  );
}

export default SubmitButton;
