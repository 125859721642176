import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import en from './en/translation.en.json';
import fi from './fi/translation.fi.json';
import se from './se/translation.se.json';

// remove non-translated keys from the object, so that they fall back to the default language
const removeNonTranslated = (
  obj: Record<string, unknown>,
): Record<string, unknown> => Object.fromEntries(
  Object.entries(obj)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .filter(([_, value]) => value !== '')
    .map(([key, value]) => [key, value === Object(value)
      ? removeNonTranslated(value as Record<string, unknown>) : value]),
);

export const setLanguage = (language: string) => {
  localStorage.setItem('language', language);
  i18n.changeLanguage(language);
};

export const resources = {
  fi: {
    translation: removeNonTranslated(fi),
  },
  en: {
    translation: removeNonTranslated(en),
  },
  se: {
    translation: removeNonTranslated(se),
  },
} as const;

const detection = {
  order: ['querystring', 'localStorage', 'navigator'],
  lookupLocalStorage: 'language',
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources,
    detection,
    fallbackLng: false,
    nsSeparator: ':::',
    keySeparator: '::',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

i18n.languages = ['fi', 'en', 'se'];

export default i18n;
