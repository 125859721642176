import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';

import { stateFromYupSchema, useFormValidator } from '../../hooks/useFormValidator';
import {
  CourseType,
  PostV1CourseTypesApiArg,
  PutV1CourseTypesByIdApiArg,
  usePostV1CourseTypesMutation,
  usePutV1CourseTypesByIdMutation,
} from '../../store/api/backendApi';
import { courseTypeCategories } from '../../utils/const';
import {
  isErrorWithFailureFrame,
  isErrorWithMessage,
  isFetchBaseQueryError,
  setNestedProperty,
} from '../../utils/utils';
import { CourseTypeInputValues, courseTypeSchema } from '../../validators/validators';
import DurationInput from '../form/DurationInput';
import FormActionsBox from '../form/FormActionsBox';
import FormInputStack from '../form/FormInputStack';
import SubmitButton from '../form/SubmitButton';
import TextInput from '../form/TextInput';
import InfoMessage from '../misc/InfoMessage';

const defaultPdfOptions: Record<CourseType['category'], Array<unknown>> = {
  firstAid: [
    {
      templateId: 'customer-course-completion',
      instructorInfo: 'Kouluttajana on toiminut laillistettu terveydenhuollon ammattilainen ja hyväksytty ensiapukouluttaja.',
      headerText: 'TYÖPAIKAN ENSIAPUVALMIUS',
      headerLogoFilename: 'camilla-logo-ea',
      footerImageFilename: 'camilla-footer-ea',
    },
    {
      templateId: 'participant-course-completion',
      instructorInfo: 'Kouluttajana on toiminut laillistettu terveydenhuollon ammattilainen ja hyväksytty ensiapukouluttaja.',
      locale: 'fi',
      headerLogoFilename: 'camilla-logo-ea',
      footerImageFilename: 'camilla-footer-ea',
    },
  ],
  fire: [
    {
      templateId: 'customer-course-completion',
      instructorInfo: '',
      headerText: 'TYÖPAIKAN PALOTURVALLISUUS',
      headerLogoFilename: 'camilla-logo-palo',
      footerImageFilename: 'camilla-footer-ea',
    },
    {
      templateId: 'participant-course-completion',
      instructorInfo: '',
      locale: 'fi',
      headerLogoFilename: 'camilla-logo-palo',
      footerImageFilename: 'camilla-footer-ea',
    },
  ],
  threat: [
    {
      templateId: 'customer-course-completion',
      instructorInfo: '',
      headerText: 'TYÖPAIKAN TURVALLISUUSKOULUTUS',
      headerLogoFilename: 'camilla-logo-uhka',
      footerImageFilename: 'camilla-footer-ea',
    },
    {
      templateId: 'participant-course-completion',
      instructorInfo: '',
      locale: 'fi',
      headerLogoFilename: 'camilla-logo-uhka',
      footerImageFilename: 'camilla-footer-ea',
    },
  ],
  other: [
    {
      templateId: 'customer-course-completion',
      instructorInfo: '',
      headerText: 'TURVALLISUUSKOULUTUS',
      headerLogoFilename: 'camilla-logo-ea',
      footerImageFilename: 'camilla-footer-ea',
    },
    {
      templateId: 'participant-course-completion',
      instructorInfo: '',
      locale: 'fi',
      headerLogoFilename: 'camilla-logo-ea',
      footerImageFilename: 'camilla-footer-ea',
    },
  ],
};

interface CourseTypeFormProps {
  courseType: CourseType | 'new';
  onClose: (needsRefresh: boolean) => void;
}

export default function CourseTypeForm(
  {
    courseType,
    onClose,
  }: CourseTypeFormProps,
) {
  const formValidator = useFormValidator(courseTypeSchema);
  const [formState, setFormState] = useState<CourseTypeInputValues>(
    stateFromYupSchema(courseTypeSchema, courseType !== 'new' ? courseType : undefined),
  );
  const [formError, setFormError] = useState<
    {error: string, errorDescription: string} | false
  >(false);
  const [putCourseType, { isLoading: isUpdating }] = usePutV1CourseTypesByIdMutation();
  const [postCourseType, { isLoading: isSaving }] = usePostV1CourseTypesMutation();
  const isUpdatingOrSaving = isUpdating || isSaving;

  const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = target.type === 'checkbox' ? { name: target.name, value: target.checked } : target;

    if (formValidator.isTouched(name)) {
      formValidator.validate(name, value);
    }
    const newState = { ...formState };
    setNestedProperty(newState, name, value);
    setFormState(newState);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSelectChange = (event: SelectChangeEvent<any>) => {
    const { name, value } = event.target;
    const newState = { ...formState };
    setNestedProperty(newState, name, value);
    setFormState(newState);
  };

  const handleDurationChange = (durationString: string) => {
    const newState = { ...formState };
    formValidator.validate('courseDuration', durationString);
    setNestedProperty(newState, 'courseDuration', durationString);
    setFormState(newState);
  };

  const handleBlur = ({ target }: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = target;
    formValidator.setTouched(target.name);
    formValidator.validate(name, value);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      setFormError(false);
      if (courseType === 'new') {
        formState.pdfOptions = defaultPdfOptions[formState.category as CourseType['category']];
      }
      const validated = await formValidator.validateAll(formState);
      if (!validated) {
        const e = new Error('Tarkista lomakkeen kentät');
        e.name = 'FormError';
        throw e;
      }

      if (courseType === 'new') {
        validated.pdfOptions = defaultPdfOptions[validated.category as CourseType['category']];
        const payload = {
          body: {
            ...validated,
          },
        } as PostV1CourseTypesApiArg;

        await postCourseType(payload).unwrap();
      } else {
        const payload = {
          id: courseType.id,
          body: {
            ...validated,
          },
        } as PutV1CourseTypesByIdApiArg;

        await putCourseType(payload).unwrap();
      }

      setFormError(false);
      onClose(true);
    } catch (e) {
      const errorInfo = {
        error: 'Tallennus epäonnistui',
        errorDescription: 'Tuntematon virhe',
      };

      if (isErrorWithFailureFrame(e)) {
        errorInfo.errorDescription = `Palvelinvirhe (${e.data.error?.message})`;
      } else if (isFetchBaseQueryError(e)) {
        errorInfo.errorDescription = 'Palvelinvirhe (verkkovirhe)';
      } else if (isErrorWithMessage(e)) {
        errorInfo.errorDescription = e.message;
      }

      setFormError(errorInfo);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormInputStack>
        <TextInput
          label="Nimi"
          name="name"
          value={formState.name}
          formValidator={formValidator}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={isUpdatingOrSaving}
          sx={{ flexGrow: 3 }}
        />
        <FormControl
          margin="normal"
          sx={{ flexGrow: 1 }}
        >
          <InputLabel id="fe-category">Kategoria</InputLabel>
          <Select
            labelId="fe-category"
            name="category"
            value={formState.category}
            label="Kategoria"
            onChange={handleSelectChange}
            disabled={isUpdatingOrSaving}
          >
            {Object.entries(courseTypeCategories).map(([key, value]) => (
              <MenuItem key={key} value={key}>{value}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </FormInputStack>

      <FormInputStack>
        <Box sx={{ flexGrow: 1 }} />
        <DurationInput
          label="Kurssin kesto"
          name="courseDuration"
          value={formState.courseDuration}
          formValidator={formValidator}
          onChange={handleDurationChange}
          onBlur={handleBlur}
          disabled={isUpdatingOrSaving}
          validUnits={['H', 'D', 'W', 'M', 'Y']}
        />
        <FormControl margin="normal">
          <InputLabel id="fe-validfor">Voimassa</InputLabel>
          <Select
            labelId="fe-validfor"
            name="cardValidForDuration"
            value={formState.cardValidForDuration}
            label="Voimassa"
            onChange={handleSelectChange}
            disabled={isUpdatingOrSaving}
          >
            <MenuItem value="P1Y">1 vuotta</MenuItem>
            <MenuItem value="P2Y">2 vuotta</MenuItem>
            <MenuItem value="P3Y">3 vuotta</MenuItem>
            <MenuItem value="P4Y">4 vuotta</MenuItem>
            <MenuItem value="P5Y">5 vuotta</MenuItem>
          </Select>
        </FormControl>
      </FormInputStack>

      <FormInputStack>
        <Box width="100%">
          <TextInput
            label="Kurssin sisältö"
            name="courseDescription"
            value={formState.courseDescription}
            formValidator={formValidator}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={isUpdatingOrSaving}
            fullWidth
            multiline
          />
          <Typography variant="body2" color="error">PDF-todistukselle mahtuu noin 20 riviä x 80 merkkiä</Typography>
        </Box>
      </FormInputStack>

      <FormActionsBox>
        <Button
          size="small"
          disabled={isUpdatingOrSaving}
          onClick={() => onClose(false)}
          sx={{ mr: 1 }}
        >
          Peruuta
        </Button>
        <SubmitButton
          size="small"
          disabled={isUpdatingOrSaving}
          loading={isUpdatingOrSaving}
          type="submit"
          variant="contained"
        >
          Tallenna
        </SubmitButton>
      </FormActionsBox>
      {formError && (
      <InfoMessage
        sx={{ mt: 2 }}
        title={formError.error}
        message={formError.errorDescription}
        severity="error"
      />
      )}
    </form>
  );
}
