import {
  Stack,
  StackProps,
} from '@mui/material';
import React from 'react';

interface FormInputStackProps extends StackProps {
  children: React.ReactNode;
}

export default function FormInputStack(
  { children, ...props }: FormInputStackProps,
): JSX.Element {
  const {
    sx,
    direction = { sm: 'column', md: 'row' },
    spacing = 2,
    ...rest
  } = props;
  return (
    <Stack
      direction={direction}
      spacing={spacing}
      sx={{ mb: { sm: 1, md: 2 }, ...sx }}
      {...rest}
    >
      {children}
    </Stack>

  );
}
