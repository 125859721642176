import { useNavigate } from 'react-router-dom';

import CustomerForm from '../../components/customers/CustomerForm';
import Breadcrumbs from '../../components/layout/Breadcrumbs';
import Restricted from '../../components/layout/Restricted';
import ViewContainer from '../../components/layout/ViewContainer';

export default function CustomerView(): JSX.Element {
  const navigate = useNavigate();

  const handleFormClose = () => {
    // called only on form cancel
    navigate('/customers');
  };
  return (
    <ViewContainer>
      <Restricted requires={['admin']}>
        <>
          <Breadcrumbs items={[
            { label: 'Asiakkaat', to: '/customers' },
            { label: 'Uusi asiakas' },
          ]}
          />
          <CustomerForm
            customer="new"
            onClose={handleFormClose}
          />
        </>
      </Restricted>
    </ViewContainer>
  );
}
