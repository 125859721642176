import { DateTime } from 'luxon';

const TZ = 'Europe/Helsinki';
const LOCALE = 'fi';

export const localDateTimeFromMillis = (millis) => {
  const dt = DateTime.fromMillis(millis, { zone: 'utc' });
  dt.setZone(TZ).setLocale(LOCALE);
  return dt;
};
