import {
  Box,
  Button,
  Typography,
} from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import CustomerList from '../../components/customers/CustomerList';
import CustomerSearch from '../../components/customers/CustomerSearch';
import Breadcrumbs from '../../components/layout/Breadcrumbs';
import Restricted from '../../components/layout/Restricted';
import ViewContainer from '../../components/layout/ViewContainer';
import ViewPaper from '../../components/layout/ViewPaper';
import InfoMessage from '../../components/misc/InfoMessage';
import LoadingIndicator from '../../components/misc/LoadingIndicator';
import { Customer, useGetV1CustomersQuery } from '../../store/api/backendApi';
import { serializeRTKFetchError } from '../../utils/utils';

function CustomersView(): JSX.Element {
  const navigate = useNavigate();
  const {
    data: customerData,
    error: fetchError,
    isError,
    isFetching,
  } = useGetV1CustomersQuery();

  const handleCustomerSearchSelect = (customer: Customer) => {
    navigate(`/customers/${customer.id}`);
  };

  if (isError) {
    return (
      <ViewContainer>

        <Breadcrumbs items={[{ label: 'Asiakkaat' }]} />
        <ViewPaper>
          <InfoMessage
            title="Virhe"
            message="Asiakkaiden lataaminen epäonnistui."
            severity="error"
            details={serializeRTKFetchError(fetchError)}
          />
        </ViewPaper>
      </ViewContainer>
    );
  }

  return (
    <ViewContainer>
      <Restricted requires={['admin']}>
        <>

          <Breadcrumbs items={[{ label: 'Asiakkaat' }]} />

          <ViewPaper>
            <Typography variant="h2" gutterBottom>
              Asiakkaat
            </Typography>
            <Button
              variant="outlined"
              size="small"
              color="primary"
              component={RouterLink}
              to="/customers/new"
            >
              Lisää uusi
            </Button>

            <Box sx={{ mt: 2, mb: 2 }}>
              <CustomerSearch onSelect={handleCustomerSearchSelect} />
            </Box>

            {isFetching ? (
              <LoadingIndicator />
            ) : (
              <CustomerList customers={customerData?.data as Customer[]} />
            )}
          </ViewPaper>
        </>
      </Restricted>
    </ViewContainer>
  );
}

export default CustomersView;
