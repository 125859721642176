import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';

import {
  useGetV1UsersQuery,
  User,
} from '../../store/api/backendApi';
import { serializeRTKFetchError } from '../../utils/utils';
import InfoMessage from '../misc/InfoMessage';
import LoadingIndicator from '../misc/LoadingIndicator';
import UserAvatar from '../users/UserAvatar';

type UserListItemProps = {
  user: User;
};

function UserListItem({ user } : UserListItemProps): JSX.Element {
  return (
    <TableRow
      key={user.id}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell component="th" scope="row">
        <Stack direction="row" spacing={1} alignItems="center">
          <UserAvatar name={user.name} email={user.emailAddress} />
          <Typography variant="body1">
            {user.name}
          </Typography>
          <Typography variant="body2">
            {user.number}
          </Typography>
        </Stack>
      </TableCell>
    </TableRow>
  );
}

function UserList(): JSX.Element {
  const {
    data: usersData, isLoading, isError, error: fetchError,
  } = useGetV1UsersQuery();

  if (isLoading) {
    return <LoadingIndicator caption="Ladataan tietoja..." />;
  }

  if (isError) {
    return (
      <InfoMessage
        severity="error"
        title="Virhe"
        message="Tietojen lataaminen epäonnistui."
        details={serializeRTKFetchError(fetchError)}
      />
    );
  }

  return (
    <TableContainer>
      <Table>
        <TableBody>
          {usersData?.data.map((user: User) => (
            <UserListItem
              key={user.id}
              user={user}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default UserList;
