/* eslint-disable react/jsx-one-expression-per-line */
import {
  Box,
  Checkbox,
  FormControlLabel,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { stateFromYupSchema, useFormValidator } from '../../hooks/useFormValidator';
import { PinCodeVerificationResponse, PostV1ParticipantsApiArg, usePostV1ParticipantsMutation } from '../../store/api/backendApi';
import {
  isErrorWithFailureFrame,
  isErrorWithMessage,
  isFetchBaseQueryError,
  setNestedProperty,
} from '../../utils/utils';
import { ParticipantEntryInputValues, participantEntrySchema } from '../../validators/validators';
import SubmitButton from '../form/SubmitButton';
import TextInput from '../form/TextInput';
import InfoMessage from '../misc/InfoMessage';

interface ParticipantEntryFormProps {
  verification: PinCodeVerificationResponse,
  pinCode: string,
}

function ParticipantEntryForm({ verification, pinCode }:ParticipantEntryFormProps): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const formValidator = useFormValidator(participantEntrySchema);
  const [formState, setFormState] = useState<ParticipantEntryInputValues>(
    stateFromYupSchema(participantEntrySchema),
  );
  const [formError, setFormError] = useState<
    {error: string, errorDescription: string} | false
  >(false);
  const [emailError, setEmailError] = useState(false);

  const [postParticipant, { isLoading: isUpdating }] = usePostV1ParticipantsMutation();

  const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = target.type === 'checkbox' ? { name: target.name, value: target.checked } : target;

    if (target.type === 'checkbox') { // checkbox does not trigger onBlur
      formValidator.setTouched(target.name);
    }

    if (formValidator.isTouched(name)) {
      formValidator.validate(name, value);
    }
    const newState = { ...formState };
    setNestedProperty(newState, name, value);
    setFormState(newState);
  };

  const handleBlur = ({ target }: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = target;
    formValidator.setTouched(target.name);
    formValidator.validate(name, value);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      setFormError(false);
      setEmailError(false);
      const validated = await formValidator.validateAll(formState);
      if (!validated) {
        const e = new Error(t('Tarkista lomakkeen kentät'));
        e.name = 'FormError';
        throw e;
      }

      const payload = {
        body: {
          participant: {
            customerId: verification.primaryCustomerId,
            ...validated,
          },
          pinCode: pinCode.replace(/\s/g, ''),
        },
      } as PostV1ParticipantsApiArg;

      await postParticipant(payload).unwrap();
      setFormError(false);
      navigate('/success');
    } catch (e) {
      const errorInfo = {
        error: t('Tallennus epäonnistui'),
        errorDescription: t('Tuntematon virhe'),
      };

      if (isErrorWithFailureFrame(e) && e.data.error?.message === 'Participant already exists.') {
        setEmailError(true);
        errorInfo.error = t('Sähköposti on jo ilmoitettu');
        errorInfo.errorDescription = t('Tällä sähköpostiosoitteella on jo ilmoittauduttu kurssille. Tarkasta sähköposti tai ota yhteyttä kurssin kouluttajaan.');
      } else if (isFetchBaseQueryError(e)) {
        errorInfo.errorDescription = t('Palvelinvirhe');
      } else if (isErrorWithMessage(e)) {
        errorInfo.errorDescription = e.message;
      }

      setFormError(errorInfo);
    }
  };

  return (
    <>
      <Typography variant="h2" gutterBottom>
        {t('Tervetuloa kurssille')}
      </Typography>
      <Box sx={{ mb: 2 }}>
        <Typography variant="body1" color="text.secondary">
          <strong>{verification.name}</strong>
        </Typography>
      </Box>

      <Typography variant="body1" paragraph sx={{ color: '#255AA0' }}>
        {t('Täytä yhteystietosi kurssitodistusta varten ja paina lopuksi Tallenna.')}
        {' '}
        {t('Kurssitodistus lähetetään kurssin jälkeen sähköpostiisi.')}
      </Typography>

      <form onSubmit={handleSubmit}>
        <Stack direction="row" spacing={2}>
          <TextInput
            label={`${t('Etunimi')} *`}
            name="firstName"
            value={formState.firstName}
            formValidator={formValidator}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={isUpdating}
            fullWidth
          />
          <TextInput
            label={`${t('Sukunimi')} *`}
            name="lastName"
            value={formState.lastName}
            formValidator={formValidator}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={isUpdating}
            fullWidth
          />
        </Stack>
        <TextInput
          label={`${t('Sähköposti')} *`}
          name="emailAddress"
          inputProps={{ inputMode: 'email', autoCapitalize: 'none' }}
          value={formState.emailAddress}
          formValidator={formValidator}
          error={formValidator.hasError('emailAddress') || emailError}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={isUpdating}
          fullWidth
        />

        <TextInput
          label={`${t('Puhelin')}`}
          name="phoneNumber"
          inputProps={{ inputMode: 'tel' }}
          value={formState.phoneNumber}
          formValidator={formValidator}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={isUpdating}
          fullWidth
        />

        <TextInput
          label={`${t('Lisätiedot')}`}
          name="privateComment"
          value={formState.privateComment}
          formValidator={formValidator}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={isUpdating}
          fullWidth
        />

        <FormControlLabel
          control={(
            <Checkbox
              checked={formState.acceptPrivacyPolicy}
              onChange={handleChange}
              name="acceptPrivacyPolicy"
            />
            )}
          label={(
            <Typography color={formValidator.hasError('acceptPrivacyPolicy') ? 'error' : 'text.primary'}>
              <Trans i18nKey="accept_pricacy_policy">
                Hyväksyn tietojeni käsittelyn <Link target="_blank" href="https://camilla.fi/tietosuojaseloste/">tietosuojaselosteen</Link> mukaisesti
              </Trans>

            </Typography>
            )}
        />

        <Box sx={{ my: 2 }}>
          <SubmitButton
            type="submit"
            size="large"
            fullWidth
            variant="contained"
            color="primary"
            loading={isUpdating}
            disabled={isUpdating}
          >
            {t('Tallenna')}
          </SubmitButton>
          {formError && (
            <InfoMessage
              sx={{ mt: 2 }}
              title={formError.error}
              message={formError.errorDescription}
              severity="error"
            />
          )}
        </Box>
      </form>
    </>
  );
}

export default ParticipantEntryForm;
