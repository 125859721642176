import {
  Avatar,
  IconButton,
  Stack,
  SxProps,
  Theme,
} from '@mui/material';

import { setLanguage } from '../../i18n/i18n';

interface LanguageSelectionProps {
  sx?: SxProps<Theme>;
}

export default function LanguageSelection({ sx = {} } : LanguageSelectionProps): JSX.Element {
  return (
    <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mb: 2, ...sx }}>
      <IconButton size="small" onClick={() => setLanguage('fi')}>
        <Avatar src="/img/fi.svg" alt="Suomi" sx={{ width: 20, height: 20 }} />
      </IconButton>
      <IconButton size="small" onClick={() => setLanguage('se')}>
        <Avatar src="/img/se.svg" alt="Svenska" sx={{ width: 20, height: 20 }} />
      </IconButton>
      <IconButton size="small" onClick={() => setLanguage('en')}>
        <Avatar src="/img/gb.svg" alt="English" sx={{ width: 20, height: 20 }} />
      </IconButton>
    </Stack>
  );
}
