import {
  Link,
  Typography,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { useAppSelector } from '../../store';
import { User } from '../../store/api/backendApi';
import InfoMessage from '../misc/InfoMessage';

type RestrictedProps = {
  requires: User['role'][]; // require SOME of these roles
  fallback?: JSX.Element;
  quiet?: boolean;
  children: JSX.Element;
};

function Restricted({
  requires,
  fallback = undefined,
  quiet = false,
  children,
} : RestrictedProps) : JSX.Element | null {
  const roles = useAppSelector((state) => state.userState.roles);

  const pass = requires.length === 0 || requires.some((role) => roles.includes(role));

  if (pass) {
    return children;
  }

  if (quiet) {
    return null;
  }

  if (fallback) {
    return fallback;
  }

  return (
    <InfoMessage
      severity="warning"
      title="Käyttöoikeus puuttuu. Ota yhteyttä järjestelmän ylläpitäjään"
      message={(
        <>
          <Typography component="span">
            Käyttöoikeus puuttuu. Ota yhteyttä järjestelmän ylläpitäjään.
          </Typography>
          <br />
          <Link component={RouterLink} to="/dashboard" underline="none">
            Takaisin etusivulle
          </Link>
        </>
    )}
    />

  );
}

export default Restricted;
