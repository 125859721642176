import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  CircularProgress,
  InputLabelProps,
  SxProps,
  TextField,
  Theme,
} from '@mui/material';
import { useEffect, useState } from 'react';

import { CourseType, useGetV1CourseTypesQuery } from '../../store/api/backendApi';
import { courseTypeSort, serializeRTKFetchError } from '../../utils/utils';
import InfoMessage from '../misc/InfoMessage';
import CourseTypeAvatar from './CourseTypeAvatar';

 interface CourseTypeSearchProps {
  onSelect: (courseType: CourseType) => void,
  sx?: SxProps<Theme> | undefined
}

export default function CourseTypeSearch({ onSelect, sx = {}, ...props }: CourseTypeSearchProps) {
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>('Ladataan...');
  const [courseTypes, setCourseTypes] = useState<readonly Partial<CourseType>[]>([]);

  const {
    data: courseTypeData,
    error: fetchError,
    isError: isFetchError,
    isFetching,
  } = useGetV1CourseTypesQuery();

  useEffect(() => {
    if (courseTypeData?.data) {
      const sorted = [...courseTypeData.data];
      sorted.sort(
        (a: CourseType, b: CourseType) => courseTypeSort(a, b),
      );
      setSearchTerm('');
      setCourseTypes(sorted);
    }
  }, [courseTypeData]);

  if (isFetchError) {
    return (
      <InfoMessage
        title="Virhe"
        message="Tietojen lataaminen epäonnistui."
        severity="error"
        details={serializeRTKFetchError(fetchError)}
      />
    );
  }

  return (
    <Autocomplete
      id="courseType-search"
      sx={sx}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      inputValue={searchTerm}
      onInputChange={(event, newInputValue) => {
        if (event) {
          setSearchTerm(newInputValue);
        } else {
          setSearchTerm('');
        }
      }}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      renderOption={(optionProps, option, state, ownerState) => (
        <Box
          {...optionProps}
          component="li"
          key={option.id}
        >
          <CourseTypeAvatar category={option.category ?? 'other'} sx={{ mr: 1 }} />
          {ownerState.getOptionLabel(option)}
        </Box>
      )}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option;
        }
        return option.name as string;
      }}
      options={courseTypes}
      loading={isFetching}
      onChange={(event, newValue, reason) => {
        if (reason === 'selectOption') {
          if (typeof newValue === 'object' && newValue !== null) {
            onSelect(newValue as CourseType);
          }
        }
        setSearchTerm('');
      }}
      renderInput={(params) => (
        <TextField
          {...(params as Partial<AutocompleteRenderInputParams>
            & { InputLabelProps: React.PropsWithChildren<InputLabelProps> })
          }
          label="Kurssityyppi"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isFetching ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      {...props}
    />
  );
}
