import { courseStatuses } from '../../../utils/const';
import ToggleChip from './ToggleChip';

interface CourseStatusSelectorProps {
  variant: 'mobile' | 'desktop';
  selectedStatuses: string[];
  onChange: (selected: string[]) => void;
}

export default function CourseStatusSelector(
  { variant, selectedStatuses, onChange } : CourseStatusSelectorProps,
): JSX.Element {
  return (
    <>
      {Object.entries(courseStatuses).map(([key, value]) => (
        <ToggleChip
          key={key}
          selected={selectedStatuses.includes(key)}
          size={variant === 'mobile' ? 'small' : 'medium'}
          label={value}
          onChange={(selected) => {
            if (selected) {
              onChange([...selectedStatuses, key]);
            } else {
              onChange(selectedStatuses.filter((s) => s !== key));
            }
          }}
        />
      ))}
    </>
  );
}
