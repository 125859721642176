import { t } from 'i18next';
import {
  array,
  boolean,
  InferType,
  object,
  string,
} from 'yup';

import {
  certificateSelections,
  courseStatuses,
  courseTypeCategories,
  invoicingStatuses,
  participantCourseStatuses,
} from '../utils/const';

const emptyStrToNullTransform = (value: string, orig: string): string | null => (orig === '' ? null : value);

const nullableString = () => string().ensure().nullable().transform(emptyStrToNullTransform);

const emailAddress = () => nullableString().email(t('Sähköposti ei kelpaa'));

export const addressSchema = {
  street: nullableString(),
  postalCode: nullableString().matches(/^(\d{5})$/, 'Anna postinumero muodossa 12345'),
  city: nullableString(),
  country: nullableString().matches(/^([A-Z]{2})$/, 'Anna maakoodi muodossa FI'),
};

export const contactPersonSchema = {
  firstName: nullableString(),
  lastName: nullableString(),
  emailAddress: emailAddress(),
  phoneNumber: nullableString(),
};

export const customerSchema = object({
  name: string().required('Pakollinen kenttä'),
  address: object().shape(addressSchema),
  contactPerson: object().shape(contactPersonSchema),
  comment: nullableString(),
});

export type CustomerInputValues = InferType<typeof customerSchema>

export const courseSchema = object({
  primaryCustomerId: string().required('Pakollinen kenttä'),
  courseTypeId: string().required('Pakollinen kenttä'),
  name: string().required('Pakollinen kenttä'),
  status: string().oneOf(Object.keys(courseStatuses)).required(),
  invoicingStatus: string().default('pending').oneOf(Object.keys(invoicingStatuses)).required(),
  contactPerson: object().shape(contactPersonSchema),
  instructorUserId: nullableString(),
  address: object().shape(addressSchema),
  notes: nullableString(),
  courseDescription: nullableString(),
  startDateTime: nullableString(),
  endDateTime: nullableString(),
  defaultCertificateSelection: string().oneOf(Object.keys(certificateSelections)).default('pdf'),
});

export type CourseInputValues = InferType<typeof courseSchema>

export const participantSchema = object({
  customerId: string().required('Pakollinen kenttä'),
  firstName: string().required('Pakollinen kenttä'),
  lastName: string().required('Pakollinen kenttä'),
  // emailAddress: emailAddress().required('Pakollinen kenttä'), // update currently not supported
  phoneNumber: nullableString(),
  courseStatus: string().oneOf(Object.keys(participantCourseStatuses)),
  certificateSelection: string().oneOf(Object.keys(certificateSelections)),
  customerComment: nullableString(),
  privateComment: nullableString(),
});

export type ParticipantInputValues = InferType<typeof participantSchema>

export const participantEntrySchema = object({
  firstName: string().required(t('Pakollinen kenttä')),
  lastName: string().required(t('Pakollinen kenttä')),
  emailAddress: emailAddress().required(t('Pakollinen kenttä')),
  phoneNumber: nullableString(),
  privateComment: nullableString(),
  certificateSelection: string().oneOf(Object.keys(certificateSelections)).default('pdf'),
  courseStatus: string().oneOf(Object.keys(participantCourseStatuses)).default('pending'),
  acceptPrivacyPolicy: boolean().isTrue(t('Pakollinen kenttä')),
});

export type ParticipantEntryInputValues = InferType<typeof participantEntrySchema>

export const courseTypeSchema = object({
  name: string().required('Pakollinen kenttä'),
  category: string().oneOf(Object.keys(courseTypeCategories)).default('firstAid').required('Pakollinen kenttä'),
  courseDescription: nullableString(),
  cardValidForDuration: string().required('Pakollinen kenttä'),
  courseDuration: string().default('P16H').required('Pakollinen kenttä'),
  pdfOptions: array(),
});

export type CourseTypeInputValues = InferType<typeof courseTypeSchema>
