import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import {
  DatePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';
import React, { useState } from 'react';

import { stateFromYupSchema, useFormValidator } from '../../hooks/useFormValidator';
import { useAppSelector } from '../../store';
import {
  CertificateType,
  Course,
  PutV1CoursesByIdApiArg,
  usePutV1CoursesByIdMutation,
} from '../../store/api/backendApi';
import { courseStatuses, invoicingStatuses } from '../../utils/const';
import {
  isErrorWithFailureFrame,
  isErrorWithMessage,
  isFetchBaseQueryError,
  setNestedProperty,
} from '../../utils/utils';
import { CourseInputValues, courseSchema } from '../../validators/validators';
import FormActionsBox from '../form/FormActionsBox';
import FormInputStack from '../form/FormInputStack';
import SubmitButton from '../form/SubmitButton';
import TextInput from '../form/TextInput';
import { CalendarIcon, CertificateTypeIcon } from '../layout/icons';
import Restricted from '../layout/Restricted';
import ExternalRefSelect from '../misc/ExternalRefSelect';
import InfoMessage from '../misc/InfoMessage';
import CertificateTypeSelect from './CertificateTypeSelect';

interface CourseDetailsFormProps {
  course: Course;
  onClose: (needsRefresh: boolean) => void;
}

export function CourseDetailsForm(
  {
    course,
    onClose,
  }: CourseDetailsFormProps,
) {
  const userRoles = useAppSelector((state) => state.userState.roles);
  const formValidator = useFormValidator(courseSchema);
  const [formState, setFormState] = useState<CourseInputValues>(
    stateFromYupSchema(courseSchema, course),
  );
  const [formError, setFormError] = useState<
    {error: string, errorDescription: string} | false
  >(false);
  const [putCourse, { isLoading: isUpdating }] = usePutV1CoursesByIdMutation();

  const setExternalRef = (ref: 'primaryCustomerId' | 'courseTypeId' | 'instructorUserId', id: string | undefined) => {
    setFormState((prevState) => ({
      ...prevState,
      [ref]: id,
    }));
  };

  const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = target.type === 'checkbox' ? { name: target.name, value: target.checked } : target;

    if (target.type === 'checkbox') { // checkbox does not trigger onBlur
      formValidator.setTouched(target.name);
    }

    if (formValidator.isTouched(name)) {
      formValidator.validate(name, value);
    }
    const newState = { ...formState };
    setNestedProperty(newState, name, value);
    setFormState(newState);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSelectChange = (event: SelectChangeEvent<any>) => {
    const { name, value } = event.target;
    const newState = { ...formState };
    setNestedProperty(newState, name, value);
    setFormState(newState);
  };

  const handleDateChange = (name: string, date: DateTime | null) => {
    const value = date ? date.toISODate() : null;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleBlur = ({ target }: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = target;
    formValidator.setTouched(target.name);
    formValidator.validate(name, value);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      setFormError(false);

      const validated = await formValidator.validateAll(formState);
      if (!validated) {
        const e = new Error('Tarkista lomakkeen kentät');
        e.name = 'FormError';
        throw e;
      }

      if (validated.startDateTime
        && validated.endDateTime
        && validated.startDateTime > validated.endDateTime) {
        const e = new Error('Alkupvm ei voi olla loppupvm:n jälkeen');
        e.name = 'FormError';
        throw e;
      }

      const payload = {
        id: course.id,
        body: {
          ...validated,
        },
      } as PutV1CoursesByIdApiArg;

      await putCourse(payload).unwrap();
      setFormError(false);
      onClose(true);
    } catch (e) {
      const errorInfo = {
        error: 'Tallennus epäonnistui',
        errorDescription: 'Tuntematon virhe',
      };

      if (isErrorWithFailureFrame(e)) {
        errorInfo.errorDescription = `Palvelinvirhe (${e.data.error?.message})`;
      } else if (isFetchBaseQueryError(e)) {
        errorInfo.errorDescription = 'Palvelinvirhe (verkkovirhe)';
      } else if (isErrorWithMessage(e)) {
        errorInfo.errorDescription = e.message;
      }

      setFormError(errorInfo);
    }
  };

  return (
    <>
      <Typography variant="h3" gutterBottom>
        {course.name}
      </Typography>

      <ExternalRefSelect
        type="customer"
        refId={formState.primaryCustomerId}
        onChange={(result) => setExternalRef('primaryCustomerId', result?.id)}
        readonly={!userRoles.includes('admin')}
      />

      <ExternalRefSelect
        type="courseType"
        refId={formState.courseTypeId}
        onChange={(result) => setExternalRef('courseTypeId', result?.id)}
        readonly={!userRoles.includes('admin')}
      />

      <ExternalRefSelect
        type="instructor"
        refId={formState.instructorUserId}
        onChange={(result) => setExternalRef('instructorUserId', result?.id)}
        readonly={!userRoles.includes('admin')}
      />

      <Stack direction="row" alignItems="center" spacing={1} sx={{ mb: 1 }}>
        <Box>
          <CertificateTypeIcon fontSize="small" color="primary" sx={{ mr: 1 }} />
        </Box>
        <CertificateTypeSelect
          label="Todistuksen tyyppi (oletus)"
          name="defaultCertificateSelection"
          fullWidth={false}
          value={formState.defaultCertificateSelection as CertificateType}
          onChange={handleSelectChange}
        />
        <Typography variant="body1">
          <Typography component="span" variant="body2">
            (vaikuttaa vain uusiin osallistujiin)
          </Typography>
        </Typography>
      </Stack>

      <Stack direction="row" spacing={1} alignItems="center" sx={{ mt: 2, mb: 1 }}>
        <Box>
          <CalendarIcon fontSize="small" color="primary" sx={{ mr: 1 }} />
        </Box>
        <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="fi">
          <DatePicker
            label="Alkupvm"
            value={formState.startDateTime ? DateTime.fromISO(formState.startDateTime) : null}
            onChange={(newDate) => handleDateChange('startDateTime', newDate)}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="fi">
          <DatePicker
            label="Loppupvm"
            value={formState.endDateTime ? DateTime.fromISO(formState.endDateTime) : null}
            onChange={(newDate) => handleDateChange('endDateTime', newDate)}
          />
        </LocalizationProvider>
      </Stack>

      <Box sx={{ my: 3 }} />

      <form onSubmit={handleSubmit}>
        <FormInputStack>
          <Box sx={{ flexGrow: 3 }}>
            <TextInput
              label="Tunniste"
              name="name"
              value={formState.name}
              formValidator={formValidator}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={isUpdating}
              fullWidth
            />
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <FormControl margin="normal" fullWidth>
              <InputLabel id="fe-status">Tila</InputLabel>
              <Select
                labelId="fe-status"
                name="status"
                value={formState.status}
                label="Tila"
                onChange={handleSelectChange}
              >
                {Object.entries(courseStatuses).map(([key, value]) => (
                  <MenuItem key={key} value={key} disabled={['closed', 'finalized'].includes(key)}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Restricted requires={['admin']} quiet>
            <Box sx={{ flexGrow: 1 }}>
              <FormControl margin="normal" fullWidth>
                <InputLabel id="fe-invoicingStatus">Laskutus</InputLabel>
                <Select
                  labelId="fe-invoicingStatus"
                  name="invoicingStatus"
                  value={formState.invoicingStatus}
                  label="Laskutus"
                  onChange={handleSelectChange}
                >
                  {Object.entries(invoicingStatuses).map(([key, value]) => (
                    <MenuItem key={key} value={key}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Restricted>
        </FormInputStack>

        <Typography variant="h4" gutterBottom>
          Kurssipaikan osoite
        </Typography>
        <Typography variant="body2" color="text.secondary" gutterBottom sx={{ mb: 2 }}>
          Mikäli eri kuin tilaajan osoite.
        </Typography>

        <FormInputStack>
          <TextInput
            label="Katuosoite"
            name="address.street"
            value={formState.address?.street}
            formValidator={formValidator}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={isUpdating}
            fullWidth
          />
        </FormInputStack>

        <FormInputStack>
          <TextInput
            label="Postinumero"
            name="address.postalCode"
            value={formState.address?.postalCode}
            formValidator={formValidator}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={isUpdating}
            fullWidth
          />
          <TextInput
            label="Kaupunki"
            name="address.city"
            value={formState.address?.city}
            formValidator={formValidator}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={isUpdating}
            fullWidth
          />
        </FormInputStack>

        <Typography variant="h4" gutterBottom>
          Kurssin yhteyshenkilö
        </Typography>
        <Typography variant="body2" color="text.secondary" gutterBottom sx={{ mb: 2 }}>
          Mikäli eri kuin tilaajan asiakastietoihin tallennettu yhteyshenkilö.
        </Typography>

        <FormInputStack>
          <TextInput
            label="Etunimi"
            name="contactPerson.firstName"
            value={formState.contactPerson.firstName}
            formValidator={formValidator}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={isUpdating}
            fullWidth
            multiline
          />
          <TextInput
            label="Sukunimi"
            name="contactPerson.lastName"
            value={formState.contactPerson.lastName}
            formValidator={formValidator}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={isUpdating}
            fullWidth
            multiline
          />
        </FormInputStack>

        <FormInputStack>
          <TextInput
            label="Sähköposti"
            name="contactPerson.emailAddress"
            value={formState.contactPerson.emailAddress}
            formValidator={formValidator}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={isUpdating}
            fullWidth
          />
          <TextInput
            label="Puhelin"
            name="contactPerson.phoneNumber"
            value={formState.contactPerson.phoneNumber}
            formValidator={formValidator}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={isUpdating}
            fullWidth
          />
        </FormInputStack>

        <Typography variant="h4" gutterBottom>
          Muut tiedot
        </Typography>

        <FormInputStack>
          <Box width="100%">
            <TextInput
              label="Kurssin sisältö"
              name="courseDescription"
              value={formState.courseDescription}
              formValidator={formValidator}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={isUpdating}
              fullWidth
              multiline
            />
            <Typography variant="body2" color="error">PDF-todistukselle mahtuu noin 20 riviä x 80 merkkiä</Typography>
          </Box>
        </FormInputStack>

        <FormInputStack>
          <TextInput
            label="Muistiinpano"
            name="notes"
            value={formState.notes}
            formValidator={formValidator}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={isUpdating}
            fullWidth
            multiline
          />
        </FormInputStack>

        <FormActionsBox>
          <Button
            size="small"
            disabled={isUpdating}
            onClick={() => onClose(false)}
            sx={{ mr: 1 }}
          >
            Peruuta
          </Button>
          <SubmitButton
            size="small"
            disabled={isUpdating}
            loading={isUpdating}
            type="submit"
            variant="contained"
          >
            Tallenna
          </SubmitButton>
        </FormActionsBox>
        {formError && (
        <InfoMessage
          sx={{ mt: 2 }}
          title={formError.error}
          message={formError.errorDescription}
          severity="error"
        />
        )}
      </form>
    </>
  );
}

export default CourseDetailsForm;
