import { CertificateType } from '../store/api/backendApi';

export const courseStatuses = {
  pending: 'Ei aloitettu',
  started: 'Käynnissä',
  closed: 'Päättynyt',
  finalized: 'Viimeistelty',
};

export const invoicingStatuses = {
  pending: 'Laskuttamatta',
  incomplete: 'Osittain laskutettu',
  finalized: 'Laskutettu',
  notInvoiceable: 'Ei laskutettava',
};

export const participantCourseStatuses = {
  pending: {
    label: 'Kesken',
    shorthand: 'K',
  },
  accepted: {
    label: 'Hyväksytty',
    shorthand: 'H',
  },
  rejected: {
    label: 'Hylätty',
    shorthand: 'X',
  },
};

export const certificateSelections: {
  [key in CertificateType]:
    {label: string, shorthand: string}
  } = {
    none: {
      label: 'Ei todistusta',
      shorthand: '-',
    },
    pdf: {
      label: 'PDF-todistus',
      shorthand: 'P',
    },
    card: {
      label: 'Uusi kortti (toimitetaan)',
      shorthand: 'UT',
    },
    cardGiven: {
      label: 'Uusi kortti (annettu kurssilla)',
      shorthand: 'UA',
    },
    renewCard: {
      label: 'Aiempi kortti kerrattu',
      shorthand: 'K',
    },
  };

export const courseTypeCategories = {
  firstAid: 'Ensiapu',
  fire: 'Palo',
  threat: 'Uhkatilanne',
  other: 'Muu',
};
