import { Box, Typography } from '@mui/material';
import React, { ErrorInfo } from 'react';

import ViewContainer from './ViewContainer';
import ViewPaper from './ViewPaper';

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

interface ErrorBoundaryState {
  error: Error | null;
  errorInfo: ErrorInfo | null;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      error,
      errorInfo,
    });
    // TODO: Sentry?
  }

  render() {
    const { error, errorInfo } = this.state;
    const { children } = this.props;

    if (errorInfo) {
      // Error path
      return (
        <ViewContainer maxWidth="lg">
          <ViewPaper>

            <Typography variant="h2" gutterBottom>Oops... </Typography>

            <Typography paragraph>
              Jotain odottamatonta tapahtui
            </Typography>
            <Typography paragraph>
              Yritä ladata sivu uudelleen tai
              {' '}
              <a href="/logout">kirjaudu ulos</a>
              {' '}
              ja yritä uudelleen.
            </Typography>
            <Box sx={{
              fontFamily: 'monospace',
              fontSize: '0.8em',
              color: '#888',
              '& > details > p': {
                fontFamily: 'monospace',
                whiteSpace: 'pre-wrap',
              },
            }}
            >
              <details>
                <summary>Lisätiedot:</summary>
                <p>
                  {error && error.toString()}
                  {errorInfo.componentStack}
                </p>
              </details>
            </Box>
          </ViewPaper>
        </ViewContainer>
      );
    }
    // Normally, just render children
    return children;
  }
}

export default ErrorBoundary;
