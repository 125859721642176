import {
  Box,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import React from 'react';

import { ContactPersonsProperties } from '../../store/api/backendApi';
import Mailto from './Mailto';

interface ContactPersonBlockProps {
  contactPerson: ContactPersonsProperties;
  sx?: SxProps<Theme> | undefined
}

export default function ContactPersonBlock(
  { contactPerson, sx = {} } : ContactPersonBlockProps,
): JSX.Element {
  return (
    <Box sx={sx}>
      <Typography variant="body1">
        {contactPerson?.name || '-' }
      </Typography>
      <Typography variant="body2" gutterBottom>
        {contactPerson?.emailAddress ? (
          <Mailto mailto={contactPerson?.emailAddress} />
        ) : '-'}
        <br />
        {contactPerson?.phoneNumber || '-'}
      </Typography>
    </Box>
  );
}
