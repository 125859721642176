import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { Course, User } from './api/backendApi';

export interface UserState {
  data: User | null;
  authenticated: boolean;
  roles: string[];
  jwtRefreshCount: number;
  courseFilterOptions: {
    search: string;
    ownOnly: boolean;
    nextFourWeeks: boolean;
    lastFourWeeks: boolean;
    unInvoiced: boolean;
    courseStatuses: Course['status'][] | [];
  };
  courseSelectValue: string | null;
  participantFilterOptions: {
    search: string;
  };
}

export const initialState: UserState = {
  data: null,
  authenticated: false,
  roles: [],
  jwtRefreshCount: 0,
  courseFilterOptions: {
    search: '',
    ownOnly: false,
    nextFourWeeks: false,
    lastFourWeeks: false,
    unInvoiced: false,
    courseStatuses: ['pending', 'started'],
  },
  courseSelectValue: null,
  participantFilterOptions: {
    search: '',
  },
};

export const userSlice = createSlice({
  name: 'userState',
  initialState,
  reducers: {
    incJwtRefreshCount: (state: UserState) => {
      state.jwtRefreshCount += 1;
    },
    resetJwtRefreshCount: (state: UserState) => {
      state.jwtRefreshCount = 0;
    },
    setData(state: UserState, action: PayloadAction<User | null>) {
      state.data = action.payload;
    },
    setAuthenticated: (state: UserState, action: PayloadAction<boolean>) => {
      state.authenticated = action.payload;
    },
    setRole: (state: UserState, action: PayloadAction<string>) => {
      // mimic multiple roles. Admin is always also an instructor
      const roles = action.payload === 'admin' ? ['admin', 'instructor'] : [action.payload];
      state.roles = roles;
    },
    setRoles: (state: UserState, action: PayloadAction<string[]>) => {
      state.roles = action.payload;
    },
    setCourseFilterOptions: (state: UserState, action: PayloadAction<UserState['courseFilterOptions']>) => {
      state.courseFilterOptions = action.payload;
    },
    setCourseSelectValue: (state: UserState, action: PayloadAction<UserState['courseSelectValue']>) => {
      state.courseSelectValue = action.payload;
    },
    setParticipantFilterOptions: (state: UserState, action: PayloadAction<UserState['participantFilterOptions']>) => {
      state.participantFilterOptions = action.payload;
    },
    reset: (state) => ({
      ...initialState,
      // only apiBaseQuery should reset jwtRefreshCount
      jwtRefreshCount: state.jwtRefreshCount,
    }),
  },
});

export const {
  incJwtRefreshCount,
  resetJwtRefreshCount,
  setData,
  setAuthenticated,
  setRole,
  setRoles,
  setCourseFilterOptions,
  setCourseSelectValue,
  setParticipantFilterOptions,
  reset,
} = userSlice.actions;

export default userSlice.reducer;
