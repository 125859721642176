import {
  Box,
  IconButton,
  LinearProgress,
  Typography,
} from '@mui/material';
import { useMemo, useState } from 'react';
import {
  useParams,
} from 'react-router-dom';

import CourseSelectionList from '../../components/courses/CourseSelectionList';
import CustomerForm from '../../components/customers/CustomerForm';
import Breadcrumbs from '../../components/layout/Breadcrumbs';
import { EditIcon } from '../../components/layout/icons';
import Restricted from '../../components/layout/Restricted';
import ViewContainer from '../../components/layout/ViewContainer';
import ViewPaper from '../../components/layout/ViewPaper';
import GoogleMapsLink from '../../components/misc/GoogleMapsLink';
import InfoMessage from '../../components/misc/InfoMessage';
import LoadingIndicator from '../../components/misc/LoadingIndicator';
import Mailto from '../../components/misc/Mailto';
import MultilineComment from '../../components/misc/MultilineComment';
import {
  Course,
  Customer,
  useGetV1CoursesQuery,
  useGetV1CustomersByIdQuery,
} from '../../store/api/backendApi';
import { courseSort, serializeRTKFetchError } from '../../utils/utils';

export default function CustomerView(): JSX.Element {
  const { id } = useParams<{ id: string }>() as { id: string };
  const [showForm, setShowForm] = useState<boolean>(false);

  const {
    data: customerData,
    error: fetchError,
    isError,
    isLoading,
    isFetching,
    refetch,
  } = useGetV1CustomersByIdQuery({ id });

  const {
    data: courseData, isLoading: isLoadingCourses,
  } = useGetV1CoursesQuery();

  const handleFormClose = (needsRefresh = false) => {
    if (needsRefresh) {
      refetch();
    }
    setShowForm(false);
  };

  const courses = useMemo(() => {
    if (!courseData) {
      return [];
    }

    const filtered = courseData.data.filter(
      (course: Course) => course.primaryCustomerId === id,
    );

    return filtered.sort(
      (a: Course, b: Course) => courseSort(a, b, 'startDateTime', 'asc'),
    );
  }, [courseData]);

  if (isError) {
    return (
      <ViewContainer>

        <Breadcrumbs items={[{ label: 'Asiakkaat' }]} />
        <ViewPaper>
          <InfoMessage
            sx={{ m: 2, p: 1 }}
            title="Virhe"
            message="Asiakkaan lataaminen epäonnistui."
            severity="error"
            details={serializeRTKFetchError(fetchError)}
          />
        </ViewPaper>

      </ViewContainer>
    );
  }

  if (isLoading || isLoadingCourses) {
    return <LoadingIndicator caption="Ladataan asiakkaan tietoja" />;
  }

  return (
    <ViewContainer>
      <Restricted requires={['admin']}>
        <>
          <Breadcrumbs items={[
            { label: 'Asiakkaat', to: '/customers' },
            { label: customerData?.data.name || '[nimetön]' },
          ]}
          />

          {showForm ? (
            <CustomerForm
              customer={customerData?.data as Customer}
              onClose={handleFormClose}
            />
          ) : (
            <>
              <ViewPaper>
                <Box sx={{ mb: 1 }}>
                  <Box sx={{
                    display: 'flex', justifyContent: 'space-between', alignItems: 'center',
                  }}
                  >
                    <Typography variant="h3" gutterBottom sx={{ mb: 1 }}>
                      {customerData?.data.name}
                    </Typography>

                    <IconButton size="small" onClick={() => setShowForm(true)} aria-label="Muokkaa">
                      <EditIcon />
                    </IconButton>
                  </Box>
                </Box>

                <LinearProgress sx={{ mb: 1, opacity: isFetching ? '1' : '0' }} />

                <Typography variant="h4" gutterBottom>Yhteyshenkilö</Typography>
                <Box sx={{ pl: 1 }}>
                  <Typography variant="body1" gutterBottom>
                    {customerData?.data.contactPerson?.name || '-'}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {customerData?.data.contactPerson?.emailAddress ? (
                      <Mailto mailto={customerData?.data.contactPerson?.emailAddress as string} />
                    ) : '-'}

                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {customerData?.data.contactPerson?.phoneNumber || '-'}
                  </Typography>
                </Box>

                <Typography variant="h4" gutterBottom>Osoite</Typography>
                <Box sx={{ pl: 1 }}>
                  <Typography variant="body1" gutterBottom>
                    {customerData?.data.address?.street || '-' }
                    <br />
                    {customerData?.data.address?.postalCode || '-'}
                    {' '}
                    {customerData?.data.address?.city || '-'}
                    {customerData?.data.address?.street && (
                    <>
                      {' '}
                      <GoogleMapsLink address={customerData?.data.address} />
                    </>
                    )}
                  </Typography>
                </Box>

                <Typography variant="h4" gutterBottom>Muistiinpanot</Typography>
                <MultilineComment content={customerData?.data.comment || '-'} />

              </ViewPaper>

              <ViewPaper sx={{ mt: 2 }}>
                <Typography variant="h3" gutterBottom>
                  Asiakkaan kurssit
                </Typography>
                <CourseSelectionList courses={courses} showInvoicingStatus />
              </ViewPaper>
            </>
          )}
        </>
      </Restricted>
    </ViewContainer>
  );
}
