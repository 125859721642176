import {
  IconButton,
  IconButtonProps,
  styled,
} from '@mui/material';

interface RotatingIconButtonProps extends IconButtonProps {
  rotated: boolean;
  defaultAngle?: number;
  rotatedAngle?: number;
}

// https://github.com/mui/material-ui/issues/29207#issuecomment-949283534
const RotatingIconButton = styled(
  IconButton,
  {
    shouldForwardProp: (prop) => prop !== 'rotated' && prop !== 'defaultAngle' && prop !== 'rotatedAngle',
  },
)<RotatingIconButtonProps>(
  ({
    theme,
    rotated,
    defaultAngle = 0,
    rotatedAngle = 90,
  }) => ({
    transform: rotated ? `rotate(${rotatedAngle}deg)` : `rotate(${defaultAngle}deg)`,
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }),
);

export default RotatingIconButton;
