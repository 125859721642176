import {
  Box,
  Button,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';

import {
  Participant,
  usePostV1ParticipantsByIdTransferMutation,
} from '../../store/api/backendApi';
import {
  isErrorWithFailureFrame,
  isErrorWithMessage,
  isFetchBaseQueryError,
} from '../../utils/utils';
import FormActionsBox from '../form/FormActionsBox';
import SubmitButton from '../form/SubmitButton';
import InfoMessage from '../misc/InfoMessage';
import CourseSelect from './CourseSelect';

interface CourseParticipantTransferFormProps {
  currentCourseId: string;
  participant: Participant;
  onClose: (needsRefresh: boolean) => void;
}

export default function CourseParticipantTransferForm(
  {
    currentCourseId,
    participant,
    onClose,
  }: CourseParticipantTransferFormProps,
) {
  const [courseId, setCourseId] = useState<string | undefined>(undefined);
  const [formError, setFormError] = useState<
    {error: string, errorDescription: string} | false
  >(false);

  const [
    transferParticipant,
    { isLoading: isUpdating },
  ] = usePostV1ParticipantsByIdTransferMutation();

  const handleCourseSelect = (newCourseId: string | undefined) => {
    setCourseId(newCourseId);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      if (!courseId) {
        throw new Error('Valitse kurssi');
      }
      await transferParticipant({
        id: participant.id,
        body: {
          courseId: courseId as string,
        },
      }).unwrap();
      onClose(true);
    } catch (e) {
      const errorInfo = {
        error: 'Tallennus epäonnistui',
        errorDescription: 'Tuntematon virhe',
      };

      if (isErrorWithFailureFrame(e)) {
        errorInfo.errorDescription = `Virhe: "${e.data.error?.message}"`;
      } else if (isFetchBaseQueryError(e)) {
        errorInfo.errorDescription = 'Palvelinvirhe (verkkovirhe)';
      } else if (isErrorWithMessage(e)) {
        errorInfo.errorDescription = e.message;
      }

      setFormError(errorInfo);
    }
  };

  return (
    <form onSubmit={handleSubmit}>

      <Typography variant="body1" gutterBottom sx={{ mb: 1 }}>
        Siirrä osallistuja toiselle kurssille
      </Typography>

      <CourseSelect disabledValues={[currentCourseId]} onSelect={handleCourseSelect} />

      <FormActionsBox>
        <Box flexGrow={1} />
        <Button
          variant="text"
          size="small"
          color="inherit"
          onClick={() => onClose(false)}
          sx={{ mr: 1 }}
        >
          Peruuta
        </Button>
        <SubmitButton
          variant="contained"
          size="small"
          type="submit"
          disabled={isUpdating}
          loading={isUpdating}
        >
          Siirrä
        </SubmitButton>
      </FormActionsBox>
      {formError && (
      <InfoMessage
        sx={{ mt: 2 }}
        title={formError.error}
        message={formError.errorDescription}
        severity="error"
      />
      )}
    </form>
  );
}
