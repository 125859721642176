import {
  SxProps,
  Theme,
  Typography,
} from '@mui/material/';

import { useGetV1CustomersByIdQuery } from '../../store/api/backendApi';
import ContactPersonBlock from '../misc/ContactPersonBlock';
import LoadingIndicator from '../misc/LoadingIndicator';

interface CustomerContactPersonProps {
  id: string;
  sx?: SxProps<Theme> | undefined
}

function CustomerContactPersonBlock({
  id,
  sx = {},
}:CustomerContactPersonProps): JSX.Element {
  const {
    data: customerData,
    isLoading,
    isError,
  } = useGetV1CustomersByIdQuery({ id });

  if (isLoading) {
    return <LoadingIndicator size="small" />;
  }

  if (isError) {
    return (
      <Typography variant="body1" color="error">
        Asiakastietojen lataaminen epäonnistui.
      </Typography>
    );
  }

  return (
    <ContactPersonBlock contactPerson={customerData?.data.contactPerson || {}} sx={sx} />
  );
}

export default CustomerContactPersonBlock;
