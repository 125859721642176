/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Button,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { stateFromYupSchema, useFormValidator } from '../../hooks/useFormValidator';
import {
  Customer,
  PostV1CustomersApiArg,
  PutV1CustomersByIdApiArg,
  usePostV1CustomersMutation,
  usePutV1CustomersByIdMutation,
} from '../../store/api/backendApi';
import {
  isErrorWithFailureFrame,
  isErrorWithMessage,
  isFetchBaseQueryError,
  setNestedProperty,
} from '../../utils/utils';
import { CustomerInputValues, customerSchema } from '../../validators/validators';
import FormActionsBox from '../form/FormActionsBox';
import FormInputStack from '../form/FormInputStack';
import SubmitButton from '../form/SubmitButton';
import TextInput from '../form/TextInput';
import ViewPaper from '../layout/ViewPaper';
import InfoMessage from '../misc/InfoMessage';

interface CustomerFormProps {
  customer: Customer | 'new';
  onClose: (needsRefresh: boolean) => void;
}

export default function CustomerForm(
  {
    customer,
    onClose,
  }: CustomerFormProps,
): JSX.Element {
  const navigate = useNavigate();
  const formValidator = useFormValidator(customerSchema);
  const [formState, setFormState] = useState<CustomerInputValues>(
    stateFromYupSchema(customerSchema, customer),
  );
  const [formError, setFormError] = useState<
    {error: string, errorDescription: string} | false
  >(false);

  const [putCustomer, { isLoading: isUpdating }] = usePutV1CustomersByIdMutation();
  const [postCustomer, { isLoading: isCreating }] = usePostV1CustomersMutation();

  const isUpdatingOrCreating = isUpdating || isCreating;

  const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = target.type === 'checkbox' ? { name: target.name, value: target.checked } : target;

    if (formValidator.isTouched(name)) {
      formValidator.validate(name, value);
    }
    const newState = { ...formState };
    setNestedProperty(newState, name, value);
    setFormState(newState);
  };

  const handleBlur = ({ target }: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = target;
    formValidator.setTouched(target.name);
    formValidator.validate(name, value);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      setFormError(false);
      const validated = await formValidator.validateAll(formState);
      if (!validated) {
        const e = new Error('Tarkista lomakkeen kentät');
        e.name = 'FormError';
        throw e;
      }

      if (customer === 'new') {
        const payload: PostV1CustomersApiArg = {
          body: {
            ...validated,
          },
        };

        const newCustomer = await postCustomer(payload).unwrap();
        navigate(`/customers/${newCustomer.metadata.id}`);
      } else {
        const payload: PutV1CustomersByIdApiArg = {
          id: customer.id,
          body: {
            ...validated,
          },
        };

        await putCustomer(payload).unwrap();
        onClose(true);
      }
    } catch (e) {
      const errorInfo = {
        error: 'Tallennus epäonnistui',
        errorDescription: 'Tuntematon virhe',
      };

      if (isErrorWithFailureFrame(e)) {
        errorInfo.errorDescription = `Palvelinvirhe (${e.data.error?.message})`;
      } else if (isFetchBaseQueryError(e)) {
        errorInfo.errorDescription = 'Palvelinvirhe (verkkovirhe)';
      } else if (isErrorWithMessage(e)) {
        errorInfo.errorDescription = e.message;
      }

      setFormError(errorInfo);
    }
  };

  return (

    <ViewPaper>
      <Typography variant="h3" gutterBottom>
        { customer === 'new' ? 'Uusi asiakas' : customer.name }
      </Typography>

      <form onSubmit={handleSubmit}>

        <FormInputStack>
          <TextInput
            label="Nimi"
            name="name"
            value={formState.name}
            formValidator={formValidator}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={isUpdatingOrCreating}
            fullWidth
          />
        </FormInputStack>

        <Typography variant="h4" gutterBottom>Yhteyshenkilö</Typography>

        <FormInputStack>
          <TextInput
            label="Etunimi"
            name="contactPerson.firstName"
            value={formState.contactPerson?.firstName}
            formValidator={formValidator}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={isUpdatingOrCreating}
            fullWidth
          />
          <TextInput
            label="Sukunimi"
            name="contactPerson.lastName"
            value={formState.contactPerson?.lastName}
            formValidator={formValidator}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={isUpdatingOrCreating}
            fullWidth
          />
        </FormInputStack>

        <FormInputStack>
          <TextInput
            label="Sähköposti"
            name="contactPerson.emailAddress"
            value={formState.contactPerson?.emailAddress}
            formValidator={formValidator}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={isUpdatingOrCreating}
            fullWidth
          />
          <TextInput
            label="Puhelinnumero"
            name="contactPerson.phoneNumber"
            value={formState.contactPerson?.phoneNumber}
            formValidator={formValidator}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={isUpdatingOrCreating}
            fullWidth
          />
        </FormInputStack>

        <Typography variant="h4" gutterBottom>Osoite</Typography>

        <FormInputStack>
          <TextInput
            label="Katuosoite"
            name="address.street"
            value={formState.address?.street}
            formValidator={formValidator}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={isUpdatingOrCreating}
            fullWidth
          />
        </FormInputStack>

        <FormInputStack>
          <TextInput
            label="Postinumero"
            name="address.postalCode"
            value={formState.address?.postalCode}
            formValidator={formValidator}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={isUpdatingOrCreating}
            fullWidth
          />
          <TextInput
            label="Kaupunki"
            name="address.city"
            value={formState.address?.city}
            formValidator={formValidator}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={isUpdatingOrCreating}
            fullWidth
          />
        </FormInputStack>

        <Typography variant="h4" gutterBottom>Muut tiedot</Typography>

        <FormInputStack>
          <TextInput
            label="Muistiinpanot"
            name="comment"
            value={formState.comment}
            formValidator={formValidator}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={isUpdatingOrCreating}
            multiline
            fullWidth
          />

        </FormInputStack>

        <FormActionsBox>
          <Button
            size="small"
            disabled={isUpdatingOrCreating}
            onClick={() => onClose(false)}
            sx={{ mr: 1 }}
          >
            Peruuta
          </Button>
          <SubmitButton
            size="small"
            variant="contained"
            type="submit"
            loading={isUpdatingOrCreating}
            disabled={isUpdatingOrCreating}
          >
            Tallenna
          </SubmitButton>
        </FormActionsBox>

        {formError && (
        <InfoMessage
          sx={{ mt: 2 }}
          title={formError.error}
          message={formError.errorDescription}
          severity="error"
        />
        )}

      </form>

    </ViewPaper>
  );
}
