import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Stack,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';

import RotatingIconButton from './RotatingIconButton';

interface CollapseButtonProps {
  collapseOpen: boolean;
  onClick: () => void;
  title: string;
  sx?: SxProps<Theme>;
}

export default function CollapseButton({
  collapseOpen,
  onClick,
  title,
  sx = {},
  ...props
} : CollapseButtonProps) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={2}
      sx={{ mb: 2, cursor: 'pointer', ...sx }}
      onClick={onClick}
      {...props}
    >
      <RotatingIconButton
        rotated={collapseOpen}
        defaultAngle={-90}
        rotatedAngle={0}
        aria-label={title}
      >
        <ExpandMoreIcon />
      </RotatingIconButton>
      <Typography flex={1} color="text.secondary" variant="body2">{title}</Typography>
    </Stack>
  );
}
