import {
  Box,
  Stack,
  SxProps,
  Theme,
  Typography,
} from '@mui/material/';

import { useGetV1UsersByIdQuery } from '../../store/api/backendApi';
import { serializeRTKFetchError } from '../../utils/utils';
import InfoMessage from '../misc/InfoMessage';
import LoadingIndicator from '../misc/LoadingIndicator';
import UserAvatar from './UserAvatar';

interface UserInfoProps {
  id: string;
  sx?: SxProps<Theme> | undefined
}

function UserInfo({ id, sx = {} } : UserInfoProps): JSX.Element {
  const {
    data: userData,
    isLoading,
    isError,
    error: fetchError,
  } = useGetV1UsersByIdQuery({ id }, { skip: !id });

  if (isLoading) {
    return <LoadingIndicator size="small" />;
  }

  if (isError) {
    return (
      <InfoMessage
        sx={{ m: 2, p: 1 }}
        title="Virhe"
        message="Tietojen lataaminen epäonnistui."
        severity="error"
        details={serializeRTKFetchError(fetchError)}
      />
    );
  }

  return (
    <Box sx={sx}>
      <Stack direction="row" spacing={1} alignItems="center">
        {userData ? (
          <>
            <UserAvatar
              name={userData?.data.name as string}
              email={userData?.data.emailAddress as string}
            />
            <Typography variant="body1">
              {userData?.data.name}
            </Typography>
          </>
        ) : (
          <Typography variant="body1" color="error">
            Kouluttaja puuttuu
          </Typography>
        )}
      </Stack>
    </Box>
  );
}

export default UserInfo;
