import {
  Chip,
  SxProps,
  Theme,
  useMediaQuery,
} from '@mui/material';

import { Course, Participant } from '../../store/api/backendApi';
import {
  certificateSelections,
  courseStatuses,
  invoicingStatuses,
  participantCourseStatuses,
} from '../../utils/const';
import CourseStatusMenu from './CourseStatusMenu';
import InvoicingStatusMenu from './InvoicingStatusMenu';

type Colors = 'default' | 'info' | 'primary' | 'success' | 'secondary' | 'error' | 'warning';

type CourseStatusChipProps = {
  status: Course['status'];
  showMenu?: boolean;
  onChange?: (status: Course['status']) => void;
  sx?: SxProps<Theme> | undefined
};
export function CourseStatusChip({
  status,
  showMenu = false,
  onChange = () => undefined,
  sx = {},
} : CourseStatusChipProps): JSX.Element {
  let chipColor: Colors = 'default';
  let chipLabel = 'Tuntematon';
  const sxProp = sx;
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  switch (status) {
    case 'pending':
      chipColor = 'default';
      chipLabel = courseStatuses.pending;
      break;
    case 'started':
      chipColor = 'info';
      chipLabel = courseStatuses.started;
      break;
    case 'closed':
      chipColor = 'primary';
      chipLabel = courseStatuses.closed;
      break;
    case 'finalized':
      chipColor = 'success';
      chipLabel = courseStatuses.finalized;
      break;
    default:
      throw new Error(`Unknown course status: ${status}`);
      break;
  }
  return (
    <Chip
      size={isMobile ? 'small' : 'medium'}
      label={(
        <>
          {showMenu && (
            <CourseStatusMenu status={status} onChange={onChange} />
          )}
          {chipLabel}
        </>
      )}
      color={chipColor}
      sx={sxProp}
    />
  );
}

type InvoicingStatusChipProps = {
  status: Course['invoicingStatus'];
  showMenu?: boolean;
  onChange?: (status: Course['invoicingStatus']) => void;
  sx?: SxProps<Theme> | undefined
};
export function InvoicingStatusChip({
  status,
  showMenu = false,
  onChange = () => undefined,
  sx = {},
} : InvoicingStatusChipProps): JSX.Element {
  let chipColor: Colors = 'default';
  let chipLabel = 'Tuntematon';
  const sxProp = sx;
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  switch (status) {
    case 'incomplete':
      chipColor = 'error';
      chipLabel = invoicingStatuses.incomplete;
      break;
    case 'finalized':
      chipColor = 'success';
      chipLabel = invoicingStatuses.finalized;
      break;
    case 'notInvoiceable':
      chipColor = 'warning';
      chipLabel = invoicingStatuses.notInvoiceable;
      break;
    case 'pending':
    default:
      chipColor = 'default';
      chipLabel = invoicingStatuses.pending;
      break;
  }
  return (
    <Chip
      size={isMobile ? 'small' : 'medium'}
      label={(
        <>
          {showMenu && (
            <InvoicingStatusMenu invoicingStatus={status} onChange={onChange} />
          )}
          {chipLabel}
        </>
    )}
      color={chipColor}
      variant="outlined"
      sx={sxProp}
      data-testid="invoicing-status-chip"
    />
  );
}

type ParticipantStatusChipProps = {
  status: Participant['courseStatus'];
  tiny?: boolean;
  sx?: SxProps<Theme> | undefined
};
export function ParticipantStatusChip({ status, tiny = false, sx = {} }
  : ParticipantStatusChipProps): JSX.Element {
  let chipColor: Colors = 'default';
  let chipLabel = 'Tuntematon';
  const sxProp = sx;
  switch (status) {
    case 'pending':
      chipColor = 'default';
      chipLabel = tiny
        ? participantCourseStatuses.pending.shorthand : participantCourseStatuses.pending.label;
      break;
    case 'accepted':
      chipColor = 'success';
      chipLabel = tiny
        ? participantCourseStatuses.accepted.shorthand : participantCourseStatuses.accepted.label;
      break;
    case 'rejected':
      chipColor = 'error';
      chipLabel = tiny
        ? participantCourseStatuses.rejected.shorthand : participantCourseStatuses.rejected.label;
      break;
    default:
      throw new Error(`Unknown participant status: ${status}`);
      break;
  }
  return (
    <Chip
      size="small"
      label={tiny ? chipLabel.substring(0, 1) : chipLabel}
      color={chipColor}
      sx={sxProp}
    />
  );
}

type ParticipantCardStatusChipProps = {
  status: Participant['certificateSelection'];
  tiny?: boolean;
  sx?: SxProps<Theme> | undefined
};
export function ParticipantCardStatusChip({ status, tiny = false, sx = {} }
  : ParticipantCardStatusChipProps): JSX.Element {
  let chipColor: Colors = 'default';
  let chipLabel = 'Tuntematon';
  const sxProp = sx;
  switch (status) {
    case 'none':
      chipColor = 'default';
      chipLabel = tiny
        ? certificateSelections.none.shorthand : certificateSelections.none.label;
      break;
    case 'pdf':
      chipColor = 'primary';
      chipLabel = tiny
        ? certificateSelections.pdf.shorthand : certificateSelections.pdf.label;
      break;
    case 'renewCard':
      chipColor = 'info';
      chipLabel = tiny
        ? certificateSelections.renewCard.shorthand : certificateSelections.renewCard.label;
      break;
    case 'card':
      chipColor = 'warning';
      chipLabel = tiny
        ? certificateSelections.card.shorthand : certificateSelections.card.label;
      break;
    case 'cardGiven':
      chipColor = 'info';
      chipLabel = tiny
        ? certificateSelections.cardGiven.shorthand : certificateSelections.cardGiven.label;
      break;
    default:
      throw new Error(`Unknown participant card status: ${status}`);
      break;
  }
  return (
    <Chip
      size="small"
      label={chipLabel}
      color={chipColor}
      sx={sxProp}
    />
  );
}
