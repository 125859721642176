import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { DateTime } from 'luxon';

import { useAppDispatch } from '../../store';
import {
  backendApi,
  CourseCertificate,
  GetV1CoursesByIdApiResponse,
  Participant,
  useGetV1CourseCertificatesQuery,
  useGetV1ParticipantsQuery,
} from '../../store/api/backendApi';
import { localDateTimeFromMillis } from '../../utils/datetime';
import { serializeRTKFetchError } from '../../utils/utils';
import { DownloadIcon } from '../layout/icons';
import InfoMessage from '../misc/InfoMessage';
import LoadingIndicator from '../misc/LoadingIndicator';

type CourseCertificatesListItemProps = {
  participant: Participant | undefined;
  certificate: CourseCertificate;
};

function CourseCertificatesListItem({
  participant,
  certificate,
} : CourseCertificatesListItemProps): JSX.Element {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useAppDispatch();

  const handleDownload = async (id: string) => {
    const promise = dispatch(
      backendApi.endpoints.getV1CourseCertificatesByIdDownload.initiate({ id }),
    );
    const { data /* isLoading, isSuccess  ... */ } = await promise;
    window.open(data?.data.downloadUrl, '_blank');
  };

  return (
    <TableRow
      key={certificate.id}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell>
        {certificate.type === 'customer' ? (
          <Typography variant="body2">
            Osallistujalista
          </Typography>
        ) : (
          <>
            <Typography variant="body2">
              {participant?.name}
            </Typography>
            <Typography variant="body2">
              {participant?.emailAddress}
            </Typography>
          </>
        )}
      </TableCell>
      <TableCell>
        <Typography variant="caption">
          {!isSmallScreen
            ? `${certificate.bucketKeyPrefix}/${certificate.filename}`
            : `...${certificate.filename.substring(23)}`}
          <IconButton onClick={() => handleDownload(certificate.id)}>
            <DownloadIcon />
          </IconButton>
        </Typography>
      </TableCell>
      <TableCell align="right">
        <Typography variant="caption">
          {localDateTimeFromMillis(certificate.createdAt).toLocaleString(DateTime.DATETIME_SHORT)}
        </Typography>
      </TableCell>
    </TableRow>
  );
}

interface CourseCertificatesListProps {
  course: GetV1CoursesByIdApiResponse['data'];
}

function CourseCertificatesList({
  course,
} : CourseCertificatesListProps): JSX.Element {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    data: participants,
    isLoading: isLoadingParticipants,
    isError: isParticipansError,
    error: participantsError,
  } = useGetV1ParticipantsQuery({ courseId: course.id });

  const {
    data: certificates,
    isLoading: isLoadingCertificates,
    isError: isCertificatesError,
    error: certificatesError,
  } = useGetV1CourseCertificatesQuery({ courseId: course.id });

  if (isLoadingCertificates || isLoadingParticipants) {
    return <LoadingIndicator caption="Ladataan tietoja..." />;
  }

  if (isParticipansError || isCertificatesError) {
    return (
      <InfoMessage
        severity="error"
        title="Virhe"
        message="Tiedostojen lataaminen epäonnistui."
        details={serializeRTKFetchError(participantsError || certificatesError)}
      />
    );
  }

  return (
    <>
      <Typography variant="h4" gutterBottom>
        Todistukset
      </Typography>
      <TableContainer sx={{
        '& .MuiTableCell-sizeSmall': {
          padding: '6px 8px',
        },
      }}
      >
        <Table size={isSmallScreen ? 'small' : 'medium'}>
          {!isSmallScreen && (
            <TableHead>
              <TableRow>
                <TableCell>Nimi</TableCell>
                <TableCell>Tiedosto</TableCell>
                <TableCell align="right">Luotu</TableCell>
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {certificates?.data.map((certificate: CourseCertificate) => (
              <CourseCertificatesListItem
                key={certificate.id}
                participant={participants?.data.find(
                  (p: Participant) => p.id === certificate.participantId,
                )}
                certificate={certificate}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default CourseCertificatesList;
