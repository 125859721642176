import {
  Paper,
  Stack,
  Typography,
} from '@mui/material';

import Breadcrumbs from '../../components/layout/Breadcrumbs';
import { CourseTypeIcon, UserIcon } from '../../components/layout/icons';
import Restricted from '../../components/layout/Restricted';
import ViewContainer from '../../components/layout/ViewContainer';
import ViewPaper from '../../components/layout/ViewPaper';
import CourseTypeList from '../../components/settings/CourseTypeList';
import UserList from '../../components/settings/UserList';

function SettingsView(): JSX.Element {
  return (
    <ViewContainer>
      <Restricted requires={['admin']}>
        <>

          <Breadcrumbs items={[{ label: 'Asetukset' }]} />

          <ViewPaper>
            <Typography variant="h2" gutterBottom>
              Asetukset
            </Typography>

            <Paper sx={{ p: 2, boxShadow: 0 }}>
              <Stack direction="row" alignItems="center" spacing={2} sx={{ mb: 2 }}>
                <CourseTypeIcon />
                <Typography variant="h3" gutterBottom>
                  Kurssityypit
                </Typography>
              </Stack>

              <CourseTypeList />
            </Paper>

            <Paper sx={{ p: 2, boxShadow: 0 }}>
              <Stack direction="row" alignItems="center" spacing={2} sx={{ mb: 2 }}>
                <UserIcon />
                <Typography variant="h3" gutterBottom>
                  Käyttäjät
                </Typography>
              </Stack>

              <UserList />
            </Paper>

          </ViewPaper>
        </>
      </Restricted>
    </ViewContainer>
  );
}

export default SettingsView;
