import { Link } from '@mui/material';
import React from 'react';

type MailtoProps = {
  mailto: string;
  color?: string;
};

export default function Mailto({ mailto, color = 'primary' }: MailtoProps): JSX.Element {
  return (
    <Link underline="none" href={`mailto:${mailto}`} color={color}>
      {mailto}
    </Link>
  );
}
