import {
  Box,
  Stack,
  SxProps,
  Theme,
  Typography,
} from '@mui/material/';

import { useGetV1CourseTypesByIdQuery } from '../../store/api/backendApi';
import { serializeRTKFetchError } from '../../utils/utils';
import InfoMessage from '../misc/InfoMessage';
import LoadingIndicator from '../misc/LoadingIndicator';
import CourseTypeAvatar from './CourseTypeAvatar';

interface CourseTypeInfoProps {
  id: string;
  sx?: SxProps<Theme> | undefined
}

function CourseTypeInfo({ id, sx = {} }:CourseTypeInfoProps): JSX.Element {
  const {
    data: courseTypeData,
    isLoading,
    isError,
    error: fetchError,
  } = useGetV1CourseTypesByIdQuery({ id });

  if (isLoading) {
    return <LoadingIndicator size="small" />;
  }

  if (isError) {
    return (
      <InfoMessage
        sx={{ m: 2, p: 1 }}
        title="Virhe"
        message="Kurssityypin lataaminen epäonnistui."
        severity="error"
        details={serializeRTKFetchError(fetchError)}
      />
    );
  }

  return (
    <Box sx={sx}>
      <Stack direction="row" spacing={1} alignItems="center">
        <CourseTypeAvatar category={courseTypeData?.data.category ?? 'other'} />
        <Typography variant="body1">
          {courseTypeData?.data.name}
        </Typography>
      </Stack>
    </Box>
  );
}

export default CourseTypeInfo;
