import {
  Badge,
  BadgeProps,
  styled,
} from '@mui/material';

import {
  GetV1CoursesByIdApiResponse,
  useGetV1ParticipantsQuery,
} from '../../store/api/backendApi';
import { ParticipantsIcon } from '../layout/icons';

interface CourseParticipantCountProps {
  course: GetV1CoursesByIdApiResponse['data'];
}

const color = '#999';

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    background: `${theme.palette.background.paper}`,
    border: `1px solid ${color}`,
    color,
  },
}));

export default function CourseParticipantCount({
  course,
} : CourseParticipantCountProps): JSX.Element | null {
  const {
    data: participants,
  } = useGetV1ParticipantsQuery(
    { courseId: course.id },
  );

  const count = participants?.data.length || 0;

  return count ? (
    <StyledBadge badgeContent={count} color="info">
      <ParticipantsIcon fontSize="small" sx={{ color, ml: 2 }} />
    </StyledBadge>
  ) : null;
}
