import {
  Box,
  IconButton,
  Stack,
  SxProps,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material/';
import { Link as RouterLink } from 'react-router-dom';

import { useGetV1CustomersByIdQuery } from '../../store/api/backendApi';
import { serializeRTKFetchError } from '../../utils/utils';
import {
  ExternalLinkIcon,
} from '../layout/icons';
import Restricted from '../layout/Restricted';
import InfoMessage from '../misc/InfoMessage';
import LoadingIndicator from '../misc/LoadingIndicator';
import Mailto from '../misc/Mailto';

interface CustomerInfoProps {
  id: string;
  showDetails?: boolean;
  showLink?: boolean;
  sx?: SxProps<Theme> | undefined
}

function CustomerInfo({
  id,
  showDetails = false,
  showLink = false,
  sx = {},
}:CustomerInfoProps): JSX.Element {
  const {
    data: customerData,
    isLoading,
    isError,
    error: fetchError,
  } = useGetV1CustomersByIdQuery({ id });

  if (isLoading) {
    return <LoadingIndicator size="small" />;
  }

  if (isError) {
    return (
      <InfoMessage
        sx={{ m: 2, p: 1 }}
        title="Virhe"
        message="Asiakastietojen lataaminen epäonnistui."
        severity="error"
        details={serializeRTKFetchError(fetchError)}
      />
    );
  }

  return (
    <Box sx={sx}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography variant="body1">
          {customerData?.data.name}
        </Typography>
        {showLink && (
          <Restricted requires={['admin']} quiet>
            <Tooltip title="Muokkaa asiakkaan tietoja">
              <IconButton size="small" component={RouterLink} to={`/customers/${id}`}>
                <ExternalLinkIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </Restricted>
        )}
      </Stack>
      {showDetails && (
      <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="baseline" spacing={1}>
        <Typography variant="body2" color="text.secondary">
          {customerData?.data.contactPerson?.name}
        </Typography>
        {customerData?.data.contactPerson?.emailAddress && (
        <Typography variant="body2" color="text.secondary">
          <Mailto mailto={customerData?.data.contactPerson?.emailAddress} color="inherit" />
        </Typography>
        )}
        {customerData?.data.contactPerson?.phoneNumber && (
        <Typography variant="body2" color="text.secondary">
          {customerData?.data.contactPerson?.phoneNumber}
        </Typography>
        )}
      </Stack>
      )}
    </Box>
  );
}

export default CustomerInfo;
