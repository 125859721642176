import {
  Button,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { useMemo, useState } from 'react';

import {
  CourseType,
  useGetV1CourseTypesQuery,
} from '../../store/api/backendApi';
import { courseTypeSort, serializeRTKFetchError } from '../../utils/utils';
import CourseTypeAvatar from '../courseTypes/CourseTypeAvatar';
import {
  EditIcon,
} from '../layout/icons';
import InfoMessage from '../misc/InfoMessage';
import LoadingIndicator from '../misc/LoadingIndicator';
import CourseTypeForm from './CourseTypeForm';

type CourseTypeListItem = {
  courseType: CourseType;
  disabled?: boolean;
  onUpdate: () => void;
};

function CourseTypeListItem({
  courseType,
  onUpdate,
  disabled = false,
}
  : CourseTypeListItem): JSX.Element {
  const [showForm, setShowForm] = useState<boolean>(false);

  const handleFormClose = (needsRefresh = false) => {
    if (needsRefresh) {
      onUpdate();
    }
    setShowForm(false);
  };

  const inputsDisabled = disabled || showForm;

  return (
    <>
      <TableRow
        key={courseType.id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell component="th" scope="row">
          <Stack direction="row" spacing={1} alignItems="center">
            <CourseTypeAvatar category={courseType.category} />
            <Typography variant="body1">
              {courseType.name}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell align="right">
          <IconButton
            color="default"
            disabled={inputsDisabled}
            onClick={() => setShowForm(true)}
          >
            <EditIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      {showForm && (
      <TableRow>
        <TableCell colSpan={6} sx={{ background: '#f5faff' }}>
          <Paper sx={{ p: 2 }}>
            <CourseTypeForm
              courseType={courseType}
              onClose={handleFormClose}
            />
          </Paper>
        </TableCell>
      </TableRow>
      )}
    </>
  );
}

function CourseTypeList(): JSX.Element {
  const {
    data: courseTypeData,
    isLoading,
    isFetching,
    isError,
    error: fetchError,
    refetch,
  } = useGetV1CourseTypesQuery();
  const [showNewForm, setShowNewForm] = useState(false);

  const handleOnUpdate = () => {
    refetch();
  };

  const handleNewFormClose = (needsRefresh = false) => {
    if (needsRefresh) {
      refetch();
    }
    setShowNewForm(false);
  };

  const courseTypes = useMemo(() => {
    if (!courseTypeData?.data) {
      return [];
    }
    const filtered = [...courseTypeData.data] as CourseType[];

    return filtered.sort((a: CourseType, b: CourseType) => courseTypeSort(a, b));
  }, [courseTypeData?.data]);

  if (isLoading) {
    return <LoadingIndicator caption="Ladataan tietoja..." />;
  }

  if (isError) {
    return (
      <InfoMessage
        severity="error"
        title="Virhe"
        message="Tietojen lataaminen epäonnistui."
        details={serializeRTKFetchError(fetchError)}
      />
    );
  }

  return (
    <TableContainer>
      <Table>
        <TableBody>
          {courseTypes.map((courseType: CourseType) => (
            <CourseTypeListItem
              key={courseType.id}
              courseType={courseType}
              disabled={isFetching}
              onUpdate={handleOnUpdate}
            />
          ))}
          {showNewForm ? (
            <TableRow>
              <TableCell colSpan={6} sx={{ background: '#f5faff' }}>
                <Paper sx={{ p: 2 }}>
                  <CourseTypeForm
                    courseType="new"
                    onClose={handleNewFormClose}
                  />
                </Paper>
              </TableCell>
            </TableRow>
          ) : (
            <TableRow>
              <TableCell colSpan={6} align="right">
                <Button onClick={() => setShowNewForm(true)}>
                  Lisää uusi
                </Button>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default CourseTypeList;
