import {
  Avatar,
  Badge,
  Box,
  FormControlLabel,
  IconButton,
  Paper,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useState } from 'react';

import {
  GetV1CoursesByIdApiResponse,
  Participant,
  PutV1ParticipantsByIdApiArg,
  useGetV1ParticipantsQuery,
  usePutV1ParticipantsByIdMutation,
} from '../../store/api/backendApi';
import { serializeRTKFetchError } from '../../utils/utils';
import CustomerChip from '../customers/CustomerChip';
import {
  CheckIcon,
  CloseIcon,
  CommentIcon,
  EditIcon,
} from '../layout/icons';
import InfoMessage from '../misc/InfoMessage';
import LoadingIndicator from '../misc/LoadingIndicator';
import { ParticipantCardStatusChip, ParticipantStatusChip } from './chips';
import CourseParticipantForm from './CourseParticipantForm';

const commentCount = (participant: Participant): number => {
  let count = 0;
  if (participant.customerComment) {
    count += 1;
  }
  if (participant.privateComment) {
    count += 1;
  }
  return count;
};

const commentTooltipText = (participant: Participant): string => {
  const comments: string[] = [];
  if (participant.customerComment) {
    comments.push(`Asiakkaalle: ${participant.customerComment}\n`);
  }
  if (participant.privateComment) {
    comments.push(`Sisäinen: ${participant.privateComment}`);
  }
  return comments.length ? comments.join('\n') : 'Lisää kommentti';
};

type CourseParticipantListItemProps = {
  number: number;
  course: GetV1CoursesByIdApiResponse['data'];
  participant: Participant;
  disabled?: boolean;
  onUpdate: () => void;
};

function CourseParticipantListItem({
  number,
  course,
  participant,
  onUpdate,
  disabled = false,
} : CourseParticipantListItemProps): JSX.Element {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [showForm, setShowForm] = useState<boolean>(false);
  const [putParticipant, { isLoading: isUpdating }] = usePutV1ParticipantsByIdMutation();

  const handleFormClose = (needsRefresh = false) => {
    if (needsRefresh) {
      onUpdate();
    }
    setShowForm(false);
  };

  const handleAccept = async () => {
    const payload: PutV1ParticipantsByIdApiArg = {
      id: participant.id,
      body: {
        ...participant,
        courseStatus: 'accepted',
      },

    };
    await putParticipant(payload).unwrap();
    onUpdate();
  };

  const inputsDisabled = disabled || showForm || isUpdating;

  return (
    <>
      <TableRow
        key={participant.id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell>
          <Stack direction="row" alignItems="center" spacing={{ xs: 1, sm: 2 }}>
            <Avatar sx={{
              width: 22,
              height: 22,
              fontSize: 14,
              color: '#222',
              background: '#fff',
              border: '1px solid #ccc',
            }}
            >
              {number}
            </Avatar>
            <Box>
              <Typography variant="body1">
                {participant.name}
              </Typography>
              <Typography variant="body2">
                {participant.emailAddress}
                <br />
                {participant.phoneNumber}
              </Typography>
              {participant.customerId !== course.primaryCustomerId && (
              <CustomerChip id={participant.customerId} sx={{ mt: 1 }} />
              )}
            </Box>
          </Stack>
        </TableCell>
        {isSmallScreen ? (
          <TableCell align="center">
            <ParticipantCardStatusChip
              status={participant.certificateSelection}
              sx={{ mr: 1 }}
              tiny={isSmallScreen}
            />
            <ParticipantStatusChip
              status={participant.courseStatus}
              sx={{ mr: 1 }}
              tiny={isSmallScreen}
            />
          </TableCell>
        ) : (
          <>
            <TableCell>
              <ParticipantCardStatusChip
                status={participant.certificateSelection}
                sx={{ mr: 1 }}
                tiny={isSmallScreen}
              />
            </TableCell>
            <TableCell>
              <ParticipantStatusChip
                status={participant.courseStatus}
                sx={{ mr: 1 }}
                tiny={isSmallScreen}
              />
            </TableCell>
          </>
        )}
        <TableCell align="right">
          {!isSmallScreen && (
            <>
              <Tooltip title="Merkkaa osallistujan kurssisuoritus hyväksytyksi" arrow placement="top">
                <span>
                  <IconButton color="success" disabled={inputsDisabled} onClick={handleAccept}>
                    <CheckIcon />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title={(<p style={{ whiteSpace: 'pre-line' }}>{commentTooltipText(participant)}</p>)} arrow placement="top">
                <span>
                  <IconButton color="default" disabled={inputsDisabled} onClick={() => setShowForm(true)}>
                    <Badge badgeContent={commentCount(participant)} sx={{ '& .MuiBadge-badge': { background: '#ccc' } }}>
                      <CommentIcon />
                    </Badge>
                  </IconButton>
                </span>
              </Tooltip>
            </>
          ) }
          {showForm ? (
            <Tooltip title="Sulje" arrow placement="top">
              <span>
                <IconButton
                  color="default"
                  onClick={() => setShowForm(false)}
                >
                  <CloseIcon />
                </IconButton>
              </span>
            </Tooltip>
          ) : (
            <Tooltip title="Muokkaa" arrow placement="top">
              <span>
                <IconButton
                  color="default"
                  disabled={inputsDisabled}
                  onClick={() => setShowForm(true)}
                >
                  <EditIcon />
                </IconButton>
              </span>
            </Tooltip>
          )}
        </TableCell>
      </TableRow>
      {showForm && (
      <TableRow>
        <TableCell colSpan={6} sx={{ background: '#f5faff' }}>
          <Paper sx={{ p: 2 }}>
            <CourseParticipantForm
              course={course}
              participant={participant}
              onClose={handleFormClose}
            />
          </Paper>
        </TableCell>
      </TableRow>
      )}
    </>
  );
}

interface CourseParticipantListProps {
  course: GetV1CoursesByIdApiResponse['data'];
  disabled: boolean;
}

function CourseParticipantList({
  course,
  disabled,
} : CourseParticipantListProps): JSX.Element {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [poll, setPoll] = useState<boolean>(false);
  const {
    data: participants, isLoading, isFetching, isError, error: fetchError,
    refetch,
  } = useGetV1ParticipantsQuery(
    { courseId: course.id },
    { pollingInterval: (poll && course.status === 'started') ? 5000 : 0 },
  );

  const handleOnUpdate = () => {
    refetch();
  };

  const PollSwitch = (
    <FormControlLabel
      disabled={course.status !== 'started'}
      control={<Switch checked={poll} onChange={(e) => setPoll(e.target.checked)} />}
      label={(<Typography variant="body2">Päivitä automaattisesti</Typography>)}
    />
  );

  if (isLoading) {
    return <LoadingIndicator caption="Ladataan osallistujia..." />;
  }

  if (isError) {
    return (
      <InfoMessage
        severity="error"
        title="Virhe"
        message="Osallistujien lataaminen epäonnistui."
        details={serializeRTKFetchError(fetchError)}
      />
    );
  }

  if (!participants || participants.data.length === 0) {
    return (
      <>
        {PollSwitch}
        <InfoMessage severity="info" message="Kurssilla ei ole vielä osallistujia" />
      </>
    );
  }

  return (
    <>
      <Typography variant="h4" gutterBottom>
        Osallistujat
        <Typography
          component="span"
          variant="body1"
          color={participants.data.length > 18 ? 'error' : 'text.secondary'}
          sx={{ ml: 1 }}
        >
          (
          {participants.data.length}
          {participants.data.length > 18 ? ' - osallistujatodistuksen maksimimäärä on 18 ' : ''}
          )
        </Typography>
      </Typography>
      {PollSwitch}
      <TableContainer sx={{
        '& .MuiTableCell-sizeSmall': {
          padding: '6px 8px',
        },
      }}
      >
        <Table size={isSmallScreen ? 'small' : 'medium'}>
          <TableHead>
            <TableRow>
              <TableCell>Nimi</TableCell>
              {isSmallScreen ? (
                <TableCell>&nbsp;</TableCell>
              ) : (
                <>
                  <TableCell>Kortti</TableCell>
                  <TableCell>Suoritus</TableCell>
                </>
              )}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {participants.data.map((participant: Participant, number) => (
              <CourseParticipantListItem
                number={number + 1}
                key={participant.id}
                course={course}
                participant={participant}
                disabled={isFetching || disabled}
                onUpdate={handleOnUpdate}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default CourseParticipantList;
