import {
  Alert, AlertProps, AlertTitle, Typography,
} from '@mui/material';

export interface InfoMessageProps extends AlertProps {
  title?: string;
  message: string | JSX.Element;
  severity: 'info' | 'warning' | 'error';
  details?: string;
}

function InfoMessage({
  title = '',
  message,
  severity,
  details = '',
  ...props
} : InfoMessageProps) : JSX.Element {
  return (

    <Alert severity={severity} variant="outlined" {...props}>
      {title && (<AlertTitle>{title}</AlertTitle>)}
      <Typography variant="body1" gutterBottom>
        {message}
      </Typography>
      {details && (
        <details>
          <summary>Lisätiedot:</summary>
          <p style={{ whiteSpace: 'pre-wrap' }}>
            {details}
          </p>
        </details>
      )}
    </Alert>
  );
}

export default InfoMessage;
