import {
  Box,
  CircularProgress,
  Typography,
} from '@mui/material/';

interface ILoadingIndicatorProps {
  caption?: string;
  size?: 'small' | 'default';
}

function LoadingIndicator({ caption = '', size = 'default' }:ILoadingIndicatorProps): JSX.Element {
  return (
    <>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <Box sx={{ flexGrow: 1 }} />
        <CircularProgress
          size={size === 'small' ? 25 : 45}
          color="secondary"
        />
        <Box sx={{ flexGrow: 1 }} />
      </Box>
      {caption
      && (
      <Typography variant="body2" align="center" sx={{ m: 3, color: '#bbb' }}>
        {caption}
      </Typography>
      )}
    </>
  );
}

export default LoadingIndicator;
