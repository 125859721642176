import {
  Box,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import { getAuth } from 'firebase/auth';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import SubmitButton from '../../components/form/SubmitButton';
import ViewContainer from '../../components/layout/ViewContainer';
import ViewPaper from '../../components/layout/ViewPaper';
import LanguageSelection from '../../components/registration/LanguageSelection';
import ParticipantEntryForm from '../../components/registration/ParticipantEntryForm';
import { PinCodeVerificationResponse, usePostV1CoursesVerifyPinCodeMutation } from '../../store/api/backendApi';

const sxViewPaper = { p: 2, mt: { xs: 4, sm: 2, md: 12 } };

function ParticipantEntryView(): JSX.Element {
  const firebaseAuth = getAuth();
  const { t } = useTranslation();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const initialPinCode = queryParams.get('pin') || '';

  const [verification, setVerification] = useState<PinCodeVerificationResponse>();
  const [pinCode, setPinCode] = useState<string>(initialPinCode);
  const [pinError, setPinError] = useState<string | null>(null);

  const [postVerifyPin, { isLoading }] = usePostV1CoursesVerifyPinCodeMutation();

  const handlePinSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const pin = pinCode.replace(/\s/g, '');
    try {
      if (pin.length < 6) {
        throw new Error(t('Tarkista koodi!'));
      }
      const verificationResponse = await postVerifyPin({ body: { pinCode: pin } }).unwrap();
      setVerification(verificationResponse.data);
    } catch (error) {
      setPinError(t('Tarkista koodi!'));
    }
  };

  const handlePinChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setPinCode(target.value);
  };

  return (
    <ViewContainer maxWidth="xs">

      <ViewPaper sx={sxViewPaper}>

        <Box display="flex" alignItems="center">
          <Box sx={{ flexGrow: 2 }}>
            <img src="/img/camilla_safety-ea.svg" alt="Camilla Safety Oy" />
          </Box>
          <LanguageSelection sx={{ flexGrow: 1 }} />
        </Box>

        {!verification ? (
          <>
            <Typography variant="h2" gutterBottom>
              {t('Tervetuloa kurssille')}
            </Typography>

            <Typography variant="body1" paragraph>
              {t('Kirjoita kouluttajalta saamasi kurssikoodi ja pääset syöttämään tietosi.')}
            </Typography>

            <form onSubmit={handlePinSubmit}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="pin"
                label={t('Koodi')}
                name="pin"
                autoFocus
                error={!!pinError}
                helperText={pinError}
                value={pinCode}
                onChange={handlePinChange}
                disabled={isLoading}
                inputProps={{ inputMode: 'numeric' }}
              />
              <Box sx={{ my: 2 }}>
                <SubmitButton
                  type="submit"
                  size="large"
                  fullWidth
                  variant="contained"
                  color="primary"
                  loading={isLoading}
                  disabled={isLoading}
                >
                  {t('Jatka')}
                </SubmitButton>
              </Box>
            </form>
          </>
        )
          : (
            <ParticipantEntryForm verification={verification} pinCode={pinCode} />
          )}

      </ViewPaper>
      {firebaseAuth.currentUser && (
      <Box sx={{ mt: 2 }}>
        <Typography variant="body2" color="text.secondary" align="center">
          <Link component={RouterLink} underline="none" to="/dashboard">Takaisin hallintanäkymään</Link>
        </Typography>
      </Box>
      )}
    </ViewContainer>
  );
}

export default ParticipantEntryView;
