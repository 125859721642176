import {
  Container,
} from '@mui/material';
import { getAuth, signOut } from 'firebase/auth';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import LoadingIndicator from '../components/misc/LoadingIndicator';

function LogoutView() {
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    signOut(auth).then(() => {
      navigate('/login');
    });
  });

  return (
    <Container maxWidth="sm">
      <LoadingIndicator caption="Logging out... " />
    </Container>
  );
}

export default LogoutView;
