import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  InputLabelProps,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../store';
import {
  Course,
  useGetV1CoursesQuery,
} from '../../store/api/backendApi';
import { setCourseSelectValue } from '../../store/userSlice';
import { courseSort } from '../../utils/utils';
import UserInfo from '../users/UserInfo';
import { CourseStatusChip } from './chips';

interface CourseSelectProps {
  disabledValues: string[];
  onSelect: (courseId: string | undefined) => void;
}

export default function CourseSelect({ disabledValues, onSelect }: CourseSelectProps): JSX.Element {
  const dispatch = useAppDispatch();
  const [searchTerm, setSearchTerm] = useState<string>('Ladataan...');
  const [selectedCourse, setSelectedCourse] = useState<Course | null>(null);
  const persistedValue = useAppSelector((state) => state.userState.courseSelectValue);

  const {
    data: courseData,
    isError,
    isLoading,
  } = useGetV1CoursesQuery();

  const courses = useMemo(() => {
    if (!courseData?.data) {
      return [];
    }

    const filtered = courseData.data.filter(
      (course: Course) => ['pending', 'started'].includes(course.status as string)
          && !disabledValues.includes(course.id),
    ) as Course[];

    filtered.sort((a: Course, b: Course) => courseSort(a, b, 'startDateTime', 'asc'));

    return filtered;
  }, [courseData]);

  const defaultCourse = useMemo(
    () => courses.find((course) => course.id === persistedValue),
    [courses, persistedValue],
  );

  useEffect(() => {
    onSelect(defaultCourse?.id);
    setSelectedCourse(defaultCourse || null);
  }, [defaultCourse]);

  if (isError) {
    return (
      <Typography variant="body1" gutterBottom color="error">
        Kurssien lataaminen epäonnistui.
      </Typography>
    );
  }

  return (
    <Autocomplete
      disablePortal
      id="course-select"
      loading={isLoading}
      options={courses}
      fullWidth
      inputValue={searchTerm}
      value={selectedCourse}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      slotProps={{
        paper: {
          sx: {
            '& .MuiAutocomplete-listbox': {
              '& .MuiAutocomplete-option': {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                mb: 1,
              },
            },
          },
        },
      }}
      onInputChange={(event, newInputValue) => {
        setSearchTerm(newInputValue);
      }}
      onChange={(event, newValue) => {
        setSelectedCourse(newValue);
        if (newValue !== null) {
          dispatch(setCourseSelectValue(newValue.id));
          onSelect((newValue as Course).id);
        } else {
          dispatch(setCourseSelectValue(null));
          onSelect(undefined);
        }
        setSearchTerm('');
      }}
      getOptionLabel={(option) => option.name}
      renderOption={(optionProps, course, state, ownerState) => (
        <Box
          {...optionProps}
          component="li"
          key={course.id}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="body2">
              {course.startDateTime ? DateTime.fromISO(course.startDateTime).toFormat('dd.MM.yyyy') : '- -'}
            </Typography>
            <CourseStatusChip
              status={course.status}
              sx={{
                ml: 1,
                fontSize: '0.6rem',
                padding: '2px 4px',
                borderRadius: '8px',
                height: '20px',
                '& .MuiChip-label': {
                  padding: '2px 4px',
                },
              }}
            />
          </Stack>
          <Typography variant="body1">
            {ownerState.getOptionLabel(course)}
          </Typography>
          <UserInfo id={course.instructorUserId as string} />

        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...(params as Partial<AutocompleteRenderInputParams>
            & { InputLabelProps: React.PropsWithChildren<InputLabelProps> })
          }
          label="Valitse kurssi"
        />
      )}
      sx={{
        '& .MuiAutocomplete-option': {
          alignItems: 'right',
        },
      }}

    />
  );
}
