import {
  Button,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { DateTime } from 'luxon';
import { Link as RouterLink } from 'react-router-dom';

import { Course } from '../../store/api/backendApi';
import Restricted from '../layout/Restricted';
import { CourseStatusChip, InvoicingStatusChip } from './chips';

interface CourseSelectionListProps {
  courses: Course[];
  showInvoicingStatus?: boolean;
}

export default function CourseSelectionList(
  { courses, showInvoicingStatus = false } : CourseSelectionListProps,
) {
  return (
    <List>
      {courses.map((course: Course) => (
        <ListItemButton key={course.id} component={RouterLink} to={`/courses/${course.id}`} alignItems="center">
          <ListItemAvatar>
            <CourseStatusChip status={course.status} sx={{ mr: 2 }} />
            {showInvoicingStatus && (
              <Restricted requires={['admin']} quiet>
                <>
                  <br />
                  <InvoicingStatusChip status={course.invoicingStatus} sx={{ mt: 1, mr: 2 }} />
                </>
              </Restricted>
            )}
          </ListItemAvatar>
          <ListItemText
            primary={course.name}
            secondary={course.startDateTime ? DateTime.fromISO(course.startDateTime).toFormat('dd.MM.yyyy') : null}
          />
        </ListItemButton>
      ))}
      <ListItemButton component={RouterLink} to="/courses" alignItems="center">
        <ListItemText
          primary={(<Button variant="text">Siirry kursseihin</Button>)}
        />
      </ListItemButton>
    </List>
  );
}
