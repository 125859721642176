import {
  Link,
  Typography,
} from '@mui/material';

import ViewContainer from '../components/layout/ViewContainer';
import ViewPaper from '../components/layout/ViewPaper';

export default function NotFoundView() {
  return (
    <ViewContainer maxWidth="xs">
      <ViewPaper sx={{ p: 6, mt: { xs: 4, sm: 2, md: 12 } }}>
        <Typography variant="h1" gutterBottom>
          Hups... sivua ei löytynyt
        </Typography>

        <Typography variant="body2" paragraph>
          Palaa takaisin
          <Link href="/" underline="none">
            aloitussivulle
          </Link>
        </Typography>

      </ViewPaper>
    </ViewContainer>
  );
}
