import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';

import {
  CertificateType,
} from '../../store/api/backendApi';
import { certificateSelections } from '../../utils/const';

interface CertificateTypeSelectProps {
  label: string,
  name: string,
  error?: boolean,
  fullWidth?: boolean,
  value: CertificateType;
  onChange: (event: SelectChangeEvent<any>) => void;
}

export default function CertificateTypeSelect(
  {
    label,
    name,
    value,
    onChange,
    error = false,
    fullWidth = true,
  }: CertificateTypeSelectProps,
) {
  return (
    <FormControl fullWidth={fullWidth} margin="normal" error={error}>
      <InputLabel id="fe-cert-type">{label}</InputLabel>
      <Select
        labelId="fe-cert-type"
        name={name}
        value={value}
        label={label}
        onChange={onChange}
      >
        {Object.entries(certificateSelections).map(([key, v]) => (
          <MenuItem key={key} value={key}>
            {v.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
