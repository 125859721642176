/* eslint-disable @typescript-eslint/no-explicit-any */

import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { DateTime } from 'luxon';

import { Course, CourseType, FailureStatusFrame } from '../store/api/backendApi';
import { UserState } from '../store/userSlice';

export function serializeRTKFetchError(error: any): string {
  let errMsg = ('error' in error && `${error.error}\n`) as string;
  if ('data' in error) errMsg += `${JSON.stringify(error.data)}\n`;
  if ('message' in error) errMsg += `${error.message}\n`;

  // SerializedError
  return errMsg;
}

export function setNestedProperty(
  obj: {[key: string]: any},
  path: string,
  value: string | boolean | number,
) {
  const propertyPath = path.split('.');
  let nestedObject = obj;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < propertyPath.length - 1; i++) {
    nestedObject = nestedObject[propertyPath[i]];
  }
  nestedObject[propertyPath[propertyPath.length - 1]] = value;
}

/**
 * https://redux-toolkit.js.org/rtk-query/usage-with-typescript#inline-error-handling-example
 */
export function isFetchBaseQueryError(
  error: unknown,
): error is FetchBaseQueryError {
  return typeof error === 'object' && error != null && 'status' in error;
}

/**
 * https://redux-toolkit.js.org/rtk-query/usage-with-typescript#inline-error-handling-example
 */
export function isErrorWithMessage(
  error: unknown,
): error is { message: string } {
  return (
    typeof error === 'object'
    && error != null
    && 'message' in error
    && typeof (error as any).message === 'string'
  );
}

export function isErrorWithFailureFrame(
  error: unknown,
): error is {data: FailureStatusFrame} {
  return (
    typeof error === 'object'
    && error != null

    && 'data' in error
    && typeof (error as any).data === 'object'
    && (error as any).data != null

    && 'error' in (error as any).data
    && typeof (error as any).data.error === 'object'
    && (error as any).data.error != null

    && 'message' in (error as any).data.error
    && typeof (error as any).data.error.message === 'string'
  );
}

export function courseFilter(
  course: Course,
  filterOptions: UserState['courseFilterOptions'],
  userIsAdmin: boolean,
  userId: string,
): boolean {
  const {
    search,
    ownOnly,
    nextFourWeeks,
    lastFourWeeks,
    unInvoiced,
    courseStatuses,
  } = filterOptions;
  const {
    name,
    instructorUserId,
    startDateTime,
  } = course;

  let result = true;

  // filter by date
  const aroundMidnight = DateTime.local()
    .minus({ days: 1 })
    .set({ hour: 23, minute: 59, second: 59 });
  const now = aroundMidnight.toISO() as string;
  const fourWeeksFromNow = aroundMidnight.plus({ weeks: 4 }).toISO() as string;
  const fourWeeksAgo = aroundMidnight.minus({ weeks: 4 }).toISO() as string;

  if (nextFourWeeks || lastFourWeeks) {
    if (!startDateTime) {
      return false; // no reason continue
    }

    result = false; // pessimistic default, filter passes only if date is in range

    if (nextFourWeeks) {
      result = result || (startDateTime < fourWeeksFromNow && startDateTime > now);
    }

    if (lastFourWeeks) {
      result = result || (startDateTime > fourWeeksAgo && startDateTime < now);
    }
  }

  // filter by search
  if (search) {
    const searchParts = search.toLowerCase().split(/\s+/);

    result = result && searchParts.every((part) => name.toLowerCase().includes(part));
  }

  // filter by user
  if (ownOnly || !userIsAdmin) {
    result = result && instructorUserId === userId;
  }

  // filter by invoicing status
  if (unInvoiced) {
    const statuses: Course['invoicingStatus'][] = ['pending', 'incomplete'];
    result = result && statuses.includes(course.invoicingStatus);
  }

  // filter by course status
  if (courseStatuses.length > 0) {
    result = result && (courseStatuses as string[]).includes(course.status);
  }

  return result;
}

export function courseSort(
  courseA: Course,
  courseB: Course,
  sortBy: string,
  sortDirection: 'asc' | 'desc',
): number {
  const {
    name: nameA,
    startDateTime: startDateTimeA,
  } = courseA;

  const {
    name: nameB,
    startDateTime: startDateTimeB,
  } = courseB;

  let result = 0;

  if (sortBy === 'name') {
    result = nameA.localeCompare(nameB);
  } else if (sortBy === 'startDateTime') {
    if (!startDateTimeA) {
      result = -1;
    } else if (!startDateTimeB) {
      result = 1;
    } else {
      result = startDateTimeA.localeCompare(startDateTimeB);
    }
  }

  if (sortDirection === 'desc' && result !== 0) {
    result *= -1;
  }

  return result;
}

export function courseTypeSort(
  courseTypeA: CourseType,
  courseTypeB: CourseType,
): number {
  if (courseTypeA.category === courseTypeB.category) {
    return courseTypeA.name.localeCompare(courseTypeB.name);
  }
  // firstAid > fire > threat > other
  const categoryOrder = ['firstAid', 'fire', 'threat', 'other'];
  const aIndex = categoryOrder.indexOf(courseTypeA.category);
  const bIndex = categoryOrder.indexOf(courseTypeB.category);
  return aIndex - bIndex;
}
