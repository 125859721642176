import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  TextField,
} from '@mui/material';
import {
  DatePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  CertificateType,
  CourseType,
  Customer,
  usePostV1CoursesMutation,
  User,
} from '../../store/api/backendApi';
import { CalendarIcon, CertificateTypeIcon } from '../layout/icons';
import ExternalRefSelect from '../misc/ExternalRefSelect';
import LoadingIndicator from '../misc/LoadingIndicator';
import CertificateTypeSelect from './CertificateTypeSelect';

interface NewCourseDialogProps {
  open: boolean;
  onClose: () => void;
}

export default function NewCourseDialog({ open, onClose }: NewCourseDialogProps): JSX.Element {
  const navigate = useNavigate();
  const [formError, setFormError] = useState('');
  const [postCourse, { isLoading }] = usePostV1CoursesMutation();
  const [customer, setCustomer] = useState<Customer | null>(null);
  const [courseType, setCourseType] = useState<CourseType | null>(null);
  const [instructor, setInstructor] = useState<User | null>(null);
  const [startDate, setStartDate] = useState<DateTime | null >(null);
  const [certificateSelection, setCertificateSelection] = useState<CertificateType>('pdf');

  const formatName = () : string => `${customer?.name || ''}, ${courseType?.name || ''}`;

  const handleAddCourse = async () => {
    try {
      if (!customer || !courseType) {
        setFormError('Asiakas ja kurssityyppi ovat pakollisia');
        return;
      }

      const newCourse = await postCourse({
        body: {
          name: formatName(),
          primaryCustomerId: customer?.id as string,
          courseTypeId: courseType.id,
          courseDescription: courseType.courseDescription,
          instructorUserId: instructor?.id,
          startDateTime: startDate ? startDate.toISODate() : null,
          defaultCertificateSelection: certificateSelection,
        },
      }).unwrap();

      navigate(`/courses/${newCourse.metadata.id}`);
      onClose();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setFormError(error.data?.error?.message || ' Tuntematon virhe');
    }
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>Lisää uusi kurssitapahtuma</DialogTitle>
      <DialogContent>
        <DialogContentText />
        <ExternalRefSelect
          type="customer"
          refId={customer?.id}
          onChange={(object) => setCustomer(object as Customer)}
          showUndo={false}
        />
        <ExternalRefSelect
          type="courseType"
          refId={courseType?.id}
          onChange={(object) => setCourseType(object as CourseType)}
          showUndo={false}
        />
        <ExternalRefSelect
          type="instructor"
          refId={instructor?.id}
          onChange={(object) => setInstructor(object as User)}
          showUndo={false}
        />

        <Stack direction="row" spacing={1} alignItems="center" sx={{ mt: 2, mb: 1 }}>
          <Box>
            <CertificateTypeIcon fontSize="small" color="primary" sx={{ mr: 1 }} />
          </Box>
          <CertificateTypeSelect
            label="Todistuksen tyyppi (oletus)"
            name="certificateSelection"
            value={certificateSelection}
            onChange={(event) => setCertificateSelection(event.target.value as CertificateType)}
          />
        </Stack>

        <Stack direction="row" spacing={1} alignItems="center" sx={{ mt: 2, mb: 1 }}>
          <Box>
            <CalendarIcon fontSize="small" color="primary" sx={{ mr: 1 }} />
          </Box>
          <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="fi">
            <DatePicker
              label="Alkupvm"
              value={startDate}
              onChange={(date) => setStartDate(date)}
            />
          </LocalizationProvider>
        </Stack>

        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Kurssin tunniste (voit muokata myöhemmin)"
          disabled
          fullWidth
          variant="standard"
          value={formatName()}
        />

        {formError && (
        <Alert severity="error" sx={{ mt: 1 }}>
          {formError}
        </Alert>
        )}
      </DialogContent>
      <DialogActions>
        {isLoading ? (
          <LoadingIndicator />) : (
            <>
              <Button variant="outlined" color="inherit" onClick={handleClose}>Peruuta</Button>
              <Button variant="contained" color="primary" onClick={handleAddCourse}>Lisää</Button>
            </>
        )}

      </DialogActions>
    </Dialog>
  );
}
