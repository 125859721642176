import {
  Box,
  IconButton,
  Stack,
} from '@mui/material';
import { useState } from 'react';

import {
  CourseType,
  Customer,
  User,
} from '../../store/api/backendApi';
import CourseTypeInfo from '../courseTypes/CourseTypeInfo';
import CourseTypeSearch from '../courseTypes/CourseTypeSearch';
import CustomerInfo from '../customers/CustomerInfo';
import CustomerSearch from '../customers/CustomerSearch';
import {
  CourseTypeIcon,
  SearchIcon,
  SelectedCustomerIcon,
  UndoIcon,
  UserIcon,
} from '../layout/icons';
import UserInfo from '../users/UserInfo';
import UserSearch from '../users/UserSearch';

interface ExternalRefSelectProps {
  type: 'customer' | 'courseType' | 'instructor';
  refId: string | undefined | null;
  onChange: (object: any | undefined) => void;
  showUndo?: boolean;
  readonly?: boolean;
}

function ExternalRefIcon({ type }: {type: ExternalRefSelectProps['type']}) {
  const color = 'primary';
  switch (type) {
    case 'customer':
      return <SelectedCustomerIcon fontSize="small" color={color} />;
    case 'courseType':
      return <CourseTypeIcon fontSize="small" color={color} />;
    case 'instructor':
      return <UserIcon fontSize="small" color={color} />;
    default:
      return null;
  }
}

function ExternalRefInfo({ type, id } : {type: ExternalRefSelectProps['type'], id: string }) {
  switch (type) {
    case 'customer':
      return <CustomerInfo id={id} showDetails />;
    case 'courseType':
      return <CourseTypeInfo id={id} />;
    case 'instructor':
      return <UserInfo id={id} />;
    default:
      return null;
  }
}

function ExternalRefSearch({ type, onSelect }: {
  type: ExternalRefSelectProps['type'],
  onSelect: (value: any) => void
}) {
  switch (type) {
    case 'customer':
      return <CustomerSearch onSelect={onSelect} />;
    case 'courseType':
      return <CourseTypeSearch onSelect={onSelect} />;
    case 'instructor':
      return <UserSearch label="Kouluttaja" onSelect={onSelect} />;
    default:
      return null;
  }
}

export function ExternalRefSelect(
  {
    type,
    refId,
    onChange,
    showUndo = true,
    readonly = false,
  }: ExternalRefSelectProps,
) {
  const [id, setId] = useState<string | undefined | null>(refId);

  const handleSelect = (object: Partial<Customer> | Partial<CourseType> | Partial<User>) => {
    setId(object.id);
    onChange(object);
  };

  return (
    <Box>
      {id ? (
        <Stack direction="row" spacing={2} alignItems="center" sx={{ mb: 1 }}>
          <ExternalRefIcon type={type} />
          <ExternalRefInfo type={type} id={id} />
          {!readonly && (
            <IconButton onClick={() => setId(undefined)} color="primary" aria-label="Avaa haku">
              <SearchIcon fontSize="small" />
            </IconButton>
          )}
        </Stack>
      ) : (

        <Stack direction="row" spacing={2} alignItems="center" sx={{ mb: 1 }}>
          <ExternalRefIcon type={type} />
          <Box sx={{ flexGrow: 1 }}>
            {readonly ? 'Ei asiakasta' : (
              <ExternalRefSearch type={type} onSelect={handleSelect} />
            )}
          </Box>
          {showUndo && (
            <IconButton onClick={() => setId(refId)}>
              <UndoIcon fontSize="small" aria-label="Peruuta" />
            </IconButton>
          )}
        </Stack>
      )}
    </Box>
  );
}

export default ExternalRefSelect;
