import {
  Box,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';
import AutoSizer, { Size } from 'react-virtualized-auto-sizer';
import { FixedSizeList, ListChildComponentProps } from 'react-window';

import { Course } from '../../store/api/backendApi';
import CourseTypeInfo from '../courseTypes/CourseTypeInfo';
import CustomerInfo from '../customers/CustomerInfo';
import {
  SelectedCustomerIcon,
  sxSubtleIcon,
} from '../layout/icons';
import Restricted from '../layout/Restricted';
import InfoMessage from '../misc/InfoMessage';
import UserInfo from '../users/UserInfo';
import { CourseStatusChip, InvoicingStatusChip } from './chips';
import CourseParticipantCount from './CourseParticipantCount';

function CourseListItem({ index, style, data }: ListChildComponentProps): JSX.Element {
  const navigate = useNavigate();

  const course = data[index] as Course;

  return (
    <Box
      key={index}
      style={style}
      sx={{
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: '#fff9f9',
        },
        borderTop: '1px solid #f0f0f0',
        overflow: 'hidden',
      }}
      onClick={() => navigate(`/courses/${course.id}`)}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        sx={{ py: 1, pt: 2 }}
      >
        <Box>
          <Typography variant="h4" gutterBottom>
            {course.name}
            <CourseParticipantCount course={course} />
          </Typography>
          <CourseTypeInfo id={course.courseTypeId} sx={{ mb: 1 }} />
          <Restricted requires={['admin']} quiet>
            <UserInfo id={course.instructorUserId as string} sx={{ mb: 1 }} />
          </Restricted>
          <Stack direction="row" spacing={1} alignItems="center" sx={{ mb: 1 }}>
            <SelectedCustomerIcon fontSize="small" sx={sxSubtleIcon} />
            <CustomerInfo id={course.primaryCustomerId} showLink={false} showDetails={false} />
          </Stack>
        </Box>
        <Box textAlign="center" display="flex" flexDirection="column">
          <Typography variant="body2" gutterBottom color={course.startDateTime ? 'inherit' : 'error'}>
            {course.startDateTime ? DateTime.fromISO(course.startDateTime).toFormat('dd.MM.yyyy') : '- -'}
          </Typography>
          <CourseStatusChip status={course.status} sx={{ mb: 1 }} />
          <Restricted requires={['admin']} quiet>
            <InvoicingStatusChip status={course.invoicingStatus} sx={{ mb: 1 }} />
          </Restricted>
        </Box>
      </Box>
    </Box>
  );
}

interface CourseListProps {
  courses: Course[];
}

function CourseList({ courses }:CourseListProps): JSX.Element {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  if (courses.length === 0) {
    return <InfoMessage severity="info" message="Ei näytettäviä kursseja" />;
  }

  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <AutoSizer>
          {({ width, height } : Size) => (
            <FixedSizeList
              height={height}
              width={width}
              itemSize={isSmallScreen ? 180 : 150}
              overscanCount={5}
              itemCount={courses.length}
              itemKey={(index, data) => data[index].id}
              itemData={courses}
            >
              {CourseListItem}
            </FixedSizeList>
          )}
        </AutoSizer>
      </Box>
      <Box sx={{ p: 2 }} textAlign="right">
        <Typography variant="body2" gutterBottom>
          Näytetään
          {' '}
          {courses.length}
          {' '}
          kurssia
        </Typography>
      </Box>
    </Box>
  );
}

export default CourseList;
