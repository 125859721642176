// import GoogleIcon from '@mui/icons-material/Google';
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';
import StarIcon from '@mui/icons-material/Star';
import {
  Box, Button,
  Container, Paper, Stack, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import GoogleSvgIcon from '../../components/layout/GoogleSvgIcon';
import LanguageSelection from '../../components/registration/LanguageSelection';

const FEEDBACK_URL = 'https://g.page/r/CaYNGqhpWACDEBM/review';

export default function FeedbackLandingView(): JSX.Element {
  const { t } = useTranslation();
  return (
    <Container component="main" maxWidth="xs">
      <Paper
        sx={{
          p: 2,
          mt: 1,
          minHeight: '95vh',
          display: 'flex',
          flexDirection: 'column',
        }}

      >
        <Box sx={{ flexGrow: 1, mb: 2 }}>
          <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
            <Box sx={{ flexGrow: 2 }}>
              <img src="/img/camilla_safety-ea.svg" alt="Camilla Safety Oy" />
            </Box>
            <LanguageSelection sx={{ flexGrow: 1 }} />
          </Box>

          <Box sx={{ flexGrow: 1, textAlign: 'center', mb: 4 }}>
            <RateReviewOutlinedIcon sx={{ fontSize: 140, color: '#ddd' }} />
            <Box display="flex" justifyContent="space-evenly" sx={{ mb: 2 }}>
              <StarIcon sx={{ fontSize: 22, color: '#ddd' }} />
              <StarIcon sx={{ fontSize: 22, color: '#ddd' }} />
              <StarIcon sx={{ fontSize: 22, color: '#ddd' }} />
              <StarIcon sx={{ fontSize: 22, color: '#ddd' }} />
              <StarIcon sx={{ fontSize: 22, color: '#ddd' }} />
            </Box>
          </Box>

          <Typography
            variant="h2"
            align="center"
            color="primary"
            sx={{ fontSize: 26 }}
            gutterBottom
          >
            {t('Mitä pidit koulutuksesta?')}
          </Typography>

          <Typography
            variant="body1"
            align="center"
            sx={{ fontSize: 16 }}
            gutterBottom
          >
            {t('Anna arvosana ja kerro mielellään myös muutamalla lauseella mitä pidit kurssista ja kouluttajasta.')}
          </Typography>
        </Box>

        <Box sx={{ mb: 2 }}>
          <Button
            href={FEEDBACK_URL}
            fullWidth
            variant="contained"
            startIcon={<RateReviewOutlinedIcon />}
            sx={{ mb: 3 }}
          >
            {t('Anna Google-arvio')}
          </Button>
          <Stack direction="row" alignItems="center" spacing={2} sx={{ mx: 1 }}>
            <GoogleSvgIcon sx={{ fontSize: 22 }} />
            <Typography
              variant="body2"
              color="text.secondary"
            >
              {t('Palautteen antamista varten sinun tulee olla kirjautuneena Google-tilillesi.')}
            </Typography>
          </Stack>
        </Box>
      </Paper>
    </Container>
  );
}
