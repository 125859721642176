import {
  Alert,
  AlertTitle,
  Button,
  Typography,
} from '@mui/material';
import { FirebaseError } from 'firebase/app';
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
} from 'firebase/auth';
import React, { useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import {
  GoogleIcon,
} from '../components/layout/icons';
import ViewContainer from '../components/layout/ViewContainer';
import ViewPaper from '../components/layout/ViewPaper';

function LoginView() {
  const firebaseAuth = getAuth();
  const navigate = useNavigate();

  const [formError, setFormError] = useState<
  {error: string, errorDescription: string} | false>(false);

  const redirect = () => {
    navigate('/dashboard');
  };

  const handlGoogleLogin = async () => {
    setFormError(false);
    const provider = new GoogleAuthProvider();

    try {
      await signInWithPopup(firebaseAuth, provider);
      redirect();
    } catch (e: Error | FirebaseError | unknown) {
      const errorInfo = {
        error: 'Authentication failed',
        errorDescription: 'An unknown error occurred',
      };
      if (e instanceof FirebaseError) {
        switch (e?.code) {
          case 'auth/popup-closed-by-user':
            errorInfo.errorDescription = 'Login popup was closed';
            break;
          default:
            errorInfo.errorDescription = e.message;
            break;
        }
      }
      setFormError(errorInfo);
    }
  };

  if (firebaseAuth.currentUser) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <ViewContainer maxWidth="xs">
      <ViewPaper sx={{ p: 6, mt: { xs: 4, sm: 2, md: 12 } }}>
        <Typography variant="h1" gutterBottom>
          Kirjaudu sisään
        </Typography>

        <Typography variant="body2" paragraph>
          Kirjaudu Camilla Safety Oy:n Google-tunnuksella.
        </Typography>
        <Button
          variant="contained"
          startIcon={<GoogleIcon sx={{ color: 'inherit' }} />}
          onClick={handlGoogleLogin}
          fullWidth
          size="large"
        >
          Kirjaudu
        </Button>
        {formError
            && (
            <Alert severity="error" sx={{ mt: 2 }}>
              <AlertTitle>{formError.error}</AlertTitle>
              {formError.errorDescription}
            </Alert>
            )}
      </ViewPaper>
    </ViewContainer>
  );
}

export default LoginView;
